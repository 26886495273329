import { makeStyles } from "@mui/styles";
export const useStyles = makeStyles(() => ({
  footerIcon: {
    right: -15
  },
  footerCaption: {
    fontSize: '12px',
    fontWeight: 'normal'
  },
  tableFooterRow: {
    background: '#EDEDEF',
    border: '1px solid #E4E4E6',
    boxSizing: 'border-box',
    boxShadow: 'inset 0px -1px 0px #E7E7ED',
    borderRadius: '0px 0px 8px 8px',
    position: "sticky",
    bottom: 0,
    zIndex: 2
  },
  paginationSpacer: {
    flex: 0
  }
}));