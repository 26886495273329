import { useStyles as useBNTStyles } from "./styles-bnt";
import { useStyles as useFCSStyles } from "./styles-fcs";
import { ProviderType } from "./types";

export const getProviderStyles = (provider?: ProviderType) => {
    if (provider === 'fcs') {
        return useFCSStyles;
    } else {
        return useBNTStyles;
    }
}
