import { ReactComponent as EpicTraining } from "icon-library/SVG/Laptop_EMR.svg";
import { ReactComponent as Cornerstone } from "icon-library/SVG/Mortarboard.svg";
import { ReactComponent as GeneralCompliance } from "icon-library/SVG/Shield_Check.svg";
import { ReactComponent as PopulationHealth } from "icon-library/SVG/User-Light_Card-Group.svg";

const fcsConfig = {
    provider: "fcs",
    config_left: [{
        name: `Altais Training Platform`,
        description: "Access the growing library of available training materials supporting practice operations, systems, policies, and more.",
        action: {
            id: "brown_toland_training_platform",
            href: "https://browntoland.csod.com/ui/lms-learner-home/home?tab_page_id=-200300006&tab_id=-3",
            target: "_blank"
        },
        icon: Cornerstone,
        enabled: true
    },
    {
        name: `Epic EHR & EpicLink Information & Training`,
        description: "All availble reference and training information for the Altais electronic health records system, including EpicLink can be accessed here.",
        action: {
            id: "pep-epic-training"
        },
        icon: EpicTraining,
        enabled: true
    }],
    config_right: [{
        name: `Compliance, Fraud, Waste and Abuse`,
        description: "CMS General Compliance and Fraud, Waste and Abuse (FWA) mandatory provider trainings.",
        action: {
            id: "pep-compliance-fraud-waste",
            href: "https://altais.com/code-of-conduct-and-compliance-training/",
            target: "_blank"
        },
        icon: GeneralCompliance,
        enabled: true
    },
    {
        name: `Population Health`,
        description: "New and existing Population Health program educational tools are included here.",
        action: {
            id: "pep-population-health"
        },
        icon: PopulationHealth,
        enabled: true
    }]
}

const bntConfig = {
    provider: "bnt",
    config_left: [{
        name: `Brown & Toland Training Platform`,
        description: "Access the growing library of available training materials supporting practice operations, systems, policies, and more.",
        action: {
            id: "brown_toland_training_platform",
            href: "https://browntoland.csod.com/ui/lms-learner-home/home?tab_page_id=-200300006&tab_id=-3",
            target: "_blank"
        },
        icon: Cornerstone,
        enabled: true
    },
    {
        name: `Epic EHR & EpicLink Information & Training`,
        description: "All availble reference and training information for the Brown & Toland electronic health records system, including EpicLink can be accessed here.",
        action: {
            id: "pep-epic-training"
        },
        icon: EpicTraining,
        enabled: true
    }],
    config_right: [{
        name: `Compliance, Fraud, Waste and Abuse`,
        description: "CMS General Compliance and Fraud, Waste and Abuse (FWA) mandatory provider trainings.",
        action: {
            id: "pep-compliance-fraud-waste"
        },
        icon: GeneralCompliance,
        enabled: true
    },
    {
        name: `Population Health`,
        description: "New and existing Population Health program educational tools are included here.",
        action: {
            id: "pep-population-health"
        },
        icon: PopulationHealth,
        enabled: true
    }]
}

export const getProviderConfig = (providerName: string) => {
    const allConfigs = [];
    allConfigs.push(bntConfig);
    allConfigs.push(fcsConfig);

    const config = allConfigs.filter((config) => {
        return config.provider === providerName;
    });
    return config[0];
};