import SVGIcon from "common/components/svg-icon/svg-icon";
import { ReactComponent as BlankFile } from "icon-library/SVG/File-Light_Blank.svg";
import { getProviderStyles } from "./styles";
import { CardProps, ProviderType } from "./types";

export const ClickableCard = ({ provider, config, onSelect: handleSelection, tall }: { provider?: ProviderType, config?: CardProps, onSelect?: (menuItem: string) => void, tall?: boolean }) => {
    const classes = getProviderStyles(provider)();
    const dummyText = `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ac sollicitudin pharetra tellus dis. Laoreet turpis sed blandit faucibus sagittis, nibh orci, scelerisque.`;
    const card: CardProps = config ? config : {
        name: "Clickable Card",
        action: {
            id: "practice_management_resources",
        },
        icon: BlankFile,
        enabled: true
    }
    const linkDisabled = (card.action.enabled === undefined) ? false : !card.action.enabled;

    return (card.enabled ? (linkDisabled ?
        <div id="pep_clickable_card" className={tall ? classes.pep_clickable_card_tall : classes.pep_clickable_card_disabled}>
            <div style={{ display: 'flex', height: '100%', width: '100%' }}>
                <div style={{ width: '10%', marginRight: '10px' }}>
                    <SVGIcon svg={card.icon} />
                </div>
                <div style={{ width: '90%', height: '100%' }}>
                    <p className={`pep_clickable_card_name`}>
                        {card.name}
                    </p>
                    <p className={`pep_clickable_card_description`}>
                        {card.description ? card.description : dummyText}
                    </p>
                </div>
            </div>
        </div> : <div id="pep_clickable_card" className={tall ? classes.pep_clickable_card_tall : classes.pep_clickable_card}>
            <a style={{ display: 'flex', height: '100%', width: '100%' }} href={card.action.href} target={card.action.target ? card.action.target : "_self"} onClick={(event) => {
                if (handleSelection && !card.action.href) {
                    event.preventDefault();
                    event.stopPropagation();
                    handleSelection(card.action.id);
                }
            }}>
                <div style={{ width: '10%', marginRight: '10px' }}>
                    <SVGIcon svg={card.icon} className={classes.pep_clickable_card_icon} />
                </div>
                <div style={{ width: '90%', height: '100%' }}>
                    <p className={classes.pep_clickable_card_name}>
                        {card.name}
                    </p>
                    <p className={classes.pep_clickable_card_description}>
                        {card.description ? card.description : dummyText}
                    </p>
                </div>
            </a>
        </div>
    ) : <></>)
}

export default ClickableCard