import { ThunkDispatch } from 'redux-thunk'
import { AnyAction } from 'redux'
import { AppReduxStore, AdminState, LicenseState } from "admin/store/reducerTypes"
import { queryStringParams } from "admin/store/api";
import { showNotification } from './snackbar';
import { Patient } from 'admin/views/Patients/types';
import { setProductsForUser } from './license';
import { setActiveModal } from 'store/actions';
import moment from "moment"
import { getChildOrganizationSummaries, setSelectedOrganization } from './organization';
import { setSelectedPracticeId } from './practice';
import { makeApiCall } from 'admin/common/utils';

export const getEhrSourceSystems = () => {
	return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
		// admin/v1/enable/ehr-source-systems --Deprecated
		return makeApiCall(dispatch, "GET", `/admin/v1/ehr-source-systems`).then((payload) => {
			dispatch({ type: 'SET_EHR_SOURCE_SYSTEMS', payload: { ehrSourceSystems: payload } })
		})
	}
}
export const getPracticeEhrSettings = (practiceId: string) => {
	return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
		return makeApiCall(dispatch, "GET", `/admin/v1/ehr-setting/practice/${practiceId}`).then((payload) => {
			dispatch({ type: 'SET_PRACTICE_EHR_SETTINGS', payload: { practiceEHRSettings: payload } })
		})
	}
}

export const updatePracticeEhrSettings = (ehrPracticeId: string, ehrSettings: any, practiceId: string, ehrSourceSystemId: string) => {
	const params = {
		ehrPracticeId: ehrPracticeId,
		ehrSourceSystemKey: ehrSourceSystemId,
		id: ehrSettings?.id,
		practiceId: practiceId,
		practiceIntegrationProperties: ehrSettings,
		redoxDestinationId: null,
		redoxSourceId: null
	}
	return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
		return await makeApiCall(dispatch, "PUT", `/admin/v1/ehr-setting/practice/${practiceId}?practiceId=${practiceId}`, params).then((payload) => {
			dispatch(showNotification("EHR Info Updated", "success", ""))
		}).catch(err => {
			//dispatch(showNotification("Error", "error", String(err && err.message)))
		});
	}
}

export const getPracticeEhrManagedProperties = (practiceId: string) => {
	return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
		return makeApiCall(dispatch, "GET", `/admin/v1/practice/${practiceId}/ehr-managed-properties`).then((payload) => {
			dispatch({ type: 'SET_EHR_MANAGED_PROPERTIES', payload: { ehrManagedProperties: payload } })
		})
	}
}

export const getOrgs = () => {
	return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
		return makeApiCall(dispatch, "GET", `/admin/v1/organization`).then((payload) => {
			dispatch({ type: 'SET_ORGS', payload: { orgs: payload } })
		})
	}
}

export const getTotalUsersCountPieData = (organizationId?: string | null, practiceId?: string | null, userType?: string[] | null) => {
	return async (dispatch: ThunkDispatch<{}, {}, any>, getState: () => AppReduxStore) => {
		const { auth } = getState()
		let params;
		if (organizationId) {
			params = { organizationId }
		} else if (practiceId) {
			params = { practiceId }
		} else {
			params = auth.currentOrg ? { organizationId: auth.currentOrg.id } : { practiceId: auth.currentPractice.id }
		}
		if (userType && userType.length > 0) {
			(params as any)['userType'] = userType
		}

		return makeApiCall(dispatch, "GET", "/admin/v1/users/counts", params)
			.then((payload) => {
				if (userType && userType.includes("PATIENT")) {
					dispatch({ type: 'SET_TOTAL_PATIENT_COUNTS', payload: { totalPatientsCountPieData: payload === "" ? null : payload } })
				} else {
					dispatch({ type: 'SET_TOTAL_USER_COUNTS', payload: { totalUsersCountPieData: payload === "" ? null : payload } })
				}
			})
			.catch((err) => {
			})
	}
}
export const editOrganization = (editOrganizationData: any, organization: any) => {
	return async (dispatch: ThunkDispatch<{}, {}, any>, getState: () => AppReduxStore) => {
		const { auth } = getState()
		if (!editOrganizationData.name || !editOrganizationData.city || !editOrganizationData.state) {
			dispatch(showNotification("Error", "error", "MISSING REQUIRED FIELD. PLEASE COMPLETE BEFORE SAVING"));
		} else {
			const editOrganization = {
				id: organization.sOrganization.id,
				name: editOrganizationData.name,
				shortName: editOrganizationData.shortName ?? "",
				address1: editOrganizationData.address1,
				address2: editOrganizationData.address2,
				city: editOrganizationData.city,
				state: editOrganizationData.state,
				postalCode: editOrganizationData.postalCode,
				nunaRole: editOrganizationData.nunaRole,
				absorbDepartmentId: editOrganizationData.absorbDepartmentId
			};

			const createOrgResult = await makeApiCall(dispatch, "PUT", "/admin/v1/organization", editOrganization)
				.then((payload) => {
					dispatch(setSelectedOrganization(payload?.id));
					dispatch(getChildOrganizationSummaries(auth.currentOrg?.id));
					return 200;
				})
				.catch((err) => {
					return err;
				});

			if (createOrgResult === 200) {
				dispatch(setActiveModal(""));
				dispatch(showNotification("Success", "success", "ORGANIZATION SUCCESSFULLY CREATED"));
			} else {
				dispatch(showNotification("Error", "error", "Error creating organization"));
			}
		}
	}
}

export const sendEventLog = (eventType: string) => {
	type eventRequestProps = {
		argument: string,
		contextPath: string,
		eventType: string,
		eventUrl: string,
		profileRoleJoinId: string,
		userId: string
	};
	return async (dispatch: ThunkDispatch<{}, {}, any>, getState: () => AppReduxStore) => {
		const { auth } = getState();
		const params: eventRequestProps = {
			argument: "",
			contextPath: "",
			eventType: eventType,
			eventUrl: "",
			profileRoleJoinId: auth.currentProfileRoleJoinInfo?.id,
			userId: auth && auth.userDetails !== null ? auth.userDetails.profileRoleJoinInfos[0].providerProfile !== null ? auth.userDetails.profileRoleJoinInfos[0].providerProfile.id : "" : ""
		};
		return makeApiCall(dispatch, "POST", `/api/admin/v1/even-log/log`, params).then((response) => {
			console.log(response);
		}).catch(err => {
			// 
		});
	}
}

export const getAllUserLevels = () => {
	return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
		return makeApiCall(dispatch, "GET", `/admin/v1/user/levels`).then((payload) => {
			dispatch({ type: 'SET_ALL_USER_LEVELS', payload: { allUserLevels: payload } })
		}).catch(err => {
			dispatch({ type: 'SET_ALL_USER_LEVELS', payload: { allUserLevels: [] } })
			dispatch(showNotification("Get All User Levels", "error", String(err && err.message)))
		});
	}
}

export const getUserLevels = (profileRoleJoinId?: string) => {
	return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
		//makeApiCall(dispatch, "GET", `/admin/v1/user/levels` + (profileRoleJoinId ? `/${profileRoleJoinId}` : ``))
		return makeApiCall(dispatch, "GET", `/admin/v1/user/levels/accessible`).then((payload) => {
			dispatch({ type: 'SET_USER_LEVELS', payload: { userLevels: payload } })
		}).catch(err => {
			dispatch({ type: 'SET_USER_LEVELS', payload: { userLevels: [] } })
			dispatch(showNotification("Get User Levels", "error", String(err && err.message)))
		});
	}
}

export const getAllAdminUsersByOrganization = (orgId: null | string) => {
	return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
		return makeApiCall(dispatch, "GET", `/admin/v1/users/organization/${orgId}`).then((payload) => {
			dispatch({ type: 'SET_USERS', payload: { users: payload } })
		}).catch(err => {
			dispatch(showNotification("Get Admin Users", "error", String(err && err.message)))
		});
	}
}

export const getUsersByOrgOrPractice = () => {

	return async (dispatch: ThunkDispatch<{}, {}, AnyAction>, getState: () => AppReduxStore) => {
		const { auth, admin, license } = getState();
		const { usersFilterText, usersPageNumber, usersPageSize } = admin
		let endpoint = ''

		if (admin.configurePracticeId) {
			endpoint = `/admin/v1/providers?practiceId=${admin.configurePracticeId && admin.configurePracticeId}&pageSizee=${usersPageSize}&pageNumber=${usersPageNumber}`
		}
		else if (auth.currentOrg) {
			endpoint = `/admin/v1/users/admin/organization/${auth.currentOrg && auth.currentOrg.id}?pageSize=${usersPageSize}&pageNumber=${usersPageNumber}`
		}
		else if (auth.currentPractice) {
			endpoint = `/admin/v1/providers?practiceId=${auth.currentPractice && auth.currentPractice.id}&pageSizee=${usersPageSize}&pageNumber=${usersPageNumber}`
		}
		endpoint = appendUserFliterAndSortOptionsToEndPoint(endpoint, admin, license)
		if (endpoint === "") {
			return
		}
		return makeApiCall(dispatch, "GET", `${endpoint}`, {},
			usersFilterText && usersFilterText.length > 0 ? "Admin.Actions.SearchUsers" : "Admin.Actions.FetchUsers",
			"Admin.Actions.FetchUsersDesc").then((payload) => {
				const allUsers = payload && payload.content ? payload.content : [];
				const users = allUsers && allUsers.map((u: any) => {
					return {
						ehrProviderId: u.ehrProviderId,
						npi: u.npi,
						displayName: u.displayName,
						email: u.email,
						userId: null,
						profileRoleId: u.profileRoleJoinId,
						profileId: u.profileId,
						username: u.email,
						lastLoginTime: u.lastLoginTime,
						firstName: u.firstName,
						lastName: u.lastName,
						fullName: `${u.firstName} ${u.lastName}`,
						status: u.status,
						ehrUsername: u.ehrUsername,
						userLevel: u.levelDescription,
						levelName: u.levelName,
						productCount: u.productCount,
						organization: u.organization,
						isAccountLocked: u.isOktaLocked,
						action: null
					}
				})

				dispatch({ type: 'SET_USERS', payload: { users: payload } })
				dispatch({ type: 'SET_USERLIST', payload: { userList: users } })
			}).catch(err => {
				dispatch({ type: 'SET_USERS', payload: { users: [] } })
				dispatch({ type: 'SET_USERLIST', payload: { userList: [] } })
				dispatch(showNotification("Get All Users", "error", String(err && err.message)))
			});
	}
}

const appendUserFliterAndSortOptionsToEndPoint = (endpoint: string, adminState: AdminState, licenseState: LicenseState) => {
	const { usersFilterText, usersSortBy, usersSortAsc, filterHaveAnyProducts, filterProductsIds, filterAccountStatus } = adminState
	if (usersFilterText && usersFilterText.trim() !== "") {
		endpoint = endpoint + `&name=${usersFilterText}`
	}
	if (usersSortBy && usersSortBy.trim() !== "") {
		let sortBy = usersSortBy.trim()
		if (sortBy === "fullName") {
			sortBy = "firstName"
		}
		else if (sortBy === "username") {
			sortBy = "email"
		}
		else if (sortBy === "userLevel") {
			sortBy = "levelDescription"
		}
		endpoint = endpoint + `&sortedBy=${sortBy}&sortAsc=${usersSortAsc}`
	}
	if (filterAccountStatus && filterAccountStatus.length > 0) {
		filterAccountStatus.forEach((s: string) => {
			if (s !== '') endpoint = endpoint + `&status=${s}`
		});
	}
	if (filterProductsIds && filterProductsIds.length > 0) {
		if (!filterHaveAnyProducts && licenseState.allProducts?.every((p: any) => filterProductsIds.includes(p.id))) {
			endpoint = endpoint + `&haveAnyProducts=${filterHaveAnyProducts}&allSelected=true`
		} else {
			endpoint = endpoint + `&haveAnyProducts=${filterHaveAnyProducts}&productIds=${filterProductsIds}`
		}
	}
	return endpoint
}

export const getPrefixes = () => {
	return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
		return makeApiCall(dispatch, "GET", `/admin/v1/user/prefixes`).then((payload) => {
			const prefixes = Object.entries(payload).map(([id, title]) => ({ id: title, title }))
			dispatch({ type: 'SET_PREFIXES', payload: { prefixes: prefixes } })
		}).catch(err => {
			dispatch(showNotification("Get Prefixes", "error", String(err && err.message)))
		});
	}
}

export const setSelectedPatient = (profileId: string) => {
	return async (dispatch: ThunkDispatch<{}, {}, any>, getSate: () => AppReduxStore) => {
		const { auth } = getSate()
		await makeApiCall(dispatch, "GET", `/admin/v1/patient-user/detail/${profileId}?practiceId=${auth.currentPractice.id}`).then((payload) => {
			const patientDetails = { ...payload.patientProfileInfo, lastLoginTime: payload.registeredUserInfo?.lastLoginTime }

			dispatch({ type: 'SET_SELECTED_PATIENT', payload: { selectedPatient: patientDetails } })

		}).catch(err => {
			dispatch(showNotification("Error", "error", String(err && err.message)))

		});
	}
}

export const setSelectedPatientsInfo = (profileId: string[]) => {
	return async (dispatch: ThunkDispatch<{}, {}, AnyAction>, getState: () => AppReduxStore) => {
		const { patients } = getState().admin;
		const sPatients = profileId && profileId.map((profileId: any) => {
			const patient = patients && patients.find((patient: any) =>
				(patient.profileId === profileId)
			)
			return patient
		})
		dispatch({ type: 'SET_SELECTED_PATIENTS', payload: { sPatients: sPatients } });
		dispatch({ type: 'SET_SELECTED_PATIENT_SUPPORT_NETWORK', payload: { sSupportNetwork: {} } })
	}
}

export const setSelectedProfileRoleID = (sProfileId: string, onUserProfileResponse?: any) => {
	return async (dispatch: ThunkDispatch<{}, {}, AnyAction>, getState: () => AppReduxStore) => {
		const { auth, admin } = getState()
		dispatch({ type: 'SET_SELECTED_USER', payload: { sUser: {} } })
		dispatch(setProductsForUser([]))
		if (admin.configurePracticeId) {
			await makeApiCall(dispatch, "GET", `/admin/v1/provider-user/profileId/${sProfileId}`, { practiceId: admin.configurePracticeId }).then((payload) => {
				dispatch({ type: 'SET_SELECTED_USER', payload: { sUser: formatUserDetails(payload) } })
				dispatch(setProductsForUser(payload.profileRoleJoinInfo))
				if (typeof onUserProfileResponse === 'function') {
					onUserProfileResponse();
				}
			}).catch(err => {
				dispatch(showNotification("ERROR GETTING USER DETAILS", "error", String(err && err.message)))
			});
		} else if (auth.currentOrg?.id) {
			await makeApiCall(dispatch, "GET", `/admin/v1/admin-user/profile/?organizationId=${auth.currentOrg?.id}&profileId=${sProfileId}`).then((payload) => {
				dispatch({ type: 'SET_SELECTED_USER', payload: { sUser: formatUserDetails(payload) } })
				dispatch(setProductsForUser(payload.profileRoleJoinInfo))
				if (typeof onUserProfileResponse === 'function') {
					onUserProfileResponse();
				}
			}).catch(err => {
				dispatch(showNotification("ERROR GETTING USER DETAILS", "error", String(err && err.message)))
				if (typeof onUserProfileResponse === 'function') {
					onUserProfileResponse();
				}
			});
		} else if (auth.currentPractice?.id) {
			await makeApiCall(dispatch, "GET", `/admin/v1/provider-user/profileId/${sProfileId}`, { practiceId: auth.currentPractice?.id }).then((payload) => {
				dispatch({ type: 'SET_SELECTED_USER', payload: { sUser: formatUserDetails(payload) } })
				dispatch(setProductsForUser(payload.profileRoleJoinInfo))
				if (typeof onUserProfileResponse === 'function') {
					onUserProfileResponse();
				}
			}).catch(err => {
				dispatch(showNotification("ERROR GETTING USER DETAILS", "error", String(err && err.message)))
			});
		}
	}
}

const formatUserDetails = (u: any) => {
	const profileInfo = u.adminProfileInfo || u.providerProfileInfo || u.patientProfileInfo
	return {
		ehrUsername: profileInfo && profileInfo.ehrUsername,
		username: u.registeredUserInfo ? u.registeredUserInfo.username : profileInfo && profileInfo.email,
		email: profileInfo && profileInfo.email,
		firstName: profileInfo && profileInfo.firstName,
		inviteSendDate: u.profileRoleJoinInfo && u.profileRoleJoinInfo.inviteSendDate,
		lastLoginTime: u.registeredUserInfo && u.registeredUserInfo.lastLoginTime,
		lastName: profileInfo && profileInfo.lastName,
		levelId: u.profileRoleJoinInfo && u.profileRoleJoinInfo.level.id,
		manualCreated: u.profileRoleJoinInfo && u.profileRoleJoinInfo.manualEntered,
		middleName: profileInfo && profileInfo.middleName,
		mobilePhone: profileInfo && profileInfo.mobilePhone,
		npi: profileInfo && profileInfo.npi,
		orgId: u.profileRoleJoinInfo && u.profileRoleJoinInfo.practice && u.profileRoleJoinInfo.practice.organizationId,
		practiceId: u.profileRoleJoinInfo && u.profileRoleJoinInfo.practice && u.profileRoleJoinInfo.practice.id,
		practiceRole: u.profileRoleJoinInfo && u.profileRoleJoinInfo.type,
		prefix: profileInfo && profileInfo.prefix,
		primaryPhone: profileInfo && profileInfo.primaryPhone,
		profileRoleId: u.profileRoleJoinInfo && u.profileRoleJoinInfo.id,
		id: profileInfo && profileInfo.id,
		status: u.profileRoleJoinInfo && u.profileRoleJoinInfo.status,
		userLevel: u.profileRoleJoinInfo && u.profileRoleJoinInfo.level.description,
		userType: u.profileRoleJoinInfo && u.profileRoleJoinInfo.type,
		isAccountLocked: u.registeredUserInfo && u.registeredUserInfo.isOktaLocked,
		authUserId: u.registeredUserInfo && u.registeredUserInfo.oktaUserId,
		qualityiqUrl: u.providerProfileInfo && u.providerProfileInfo.qualityiqUrl
	}
}
export const getRegisteredUserDetails = (userId: string) => {
	return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
		return makeApiCall(dispatch, "GET", `/admin/v1/user/registration`).then((payload) => {
			dispatch({ type: 'SET_SELECTED_REGISTERED_USER', payload: { sRegisteredUser: payload } })
		}).catch(err => {
			dispatch(showNotification("Get User Details", "error", String(err && err.message)))
		});
	}
}
export const insertUser = (data: any, onUserAdd?: any) => {
	return async (dispatch: ThunkDispatch<{}, {}, any>, getSate: () => AppReduxStore) => {
		const { auth, admin } = getSate()
		let orgId = ''
		let practiceId = ''

		if (auth.currentOrg) {
			orgId = auth.currentOrg.id
		}
		if (auth.currentPractice) {
			practiceId = auth.currentPractice.id
		}
		if (admin.configurePracticeId) {
			practiceId = admin.configurePracticeId
		}
		if (data.userType.toLowerCase() === 'admin') {
			data['orgId'] = orgId
			delete data["qualityiqUrl"]
		} else {
			data['practiceId'] = practiceId
		}
		delete data['username']
		delete data["isAccountLocked"]
		let endpoint = ''
		switch (data.userType.toUpperCase()) {
			case "ADMIN":
				endpoint = `/admin/v1/admin-user`
				break
			case "PROVIDER":
				endpoint = `/admin/v1/provider-user`
				break
		}
		return makeApiCall(dispatch, "POST", `${endpoint}`, data).then((payload) => {
			dispatch(showNotification(payload, "success", ""))
			dispatch(getUsersByOrgOrPractice())
			if (typeof onUserAdd === 'function') {
				onUserAdd(true)
			}
			return payload;
		}).catch(err => {
			dispatch(showNotification("Error", "error", String(err && err.message)))
			if (typeof onUserAdd === 'function') {
				onUserAdd(false)
			}
		});
	}
}

export const editUser = (data: any) => {
	return async (dispatch: ThunkDispatch<{}, {}, any>, getSate: () => AppReduxStore) => {
		const { auth } = getSate()
		let orgId = ''
		let practiceId = ''
		const profileId = data?.id

		if (auth.currentOrg) {
			orgId = auth.currentOrg.id
		}
		if (auth.currentPractice) {
			practiceId = auth.currentPractice.id
		}
		if (data.userType?.toLowerCase() === 'admin') {
			data['orgId'] = data.orgId ? data.orgId : orgId
			delete data['practiceId']
			delete data['id']
		} else {
			data['practiceId'] = data.practiceId ? data.practiceId : practiceId
			delete data['orgId']
		}

		delete data['username']

		delete data['userLevel']
		delete data["isAccountLocked"]
		delete data["authUserId"]
		let endpoint = ''
		switch (data.userType?.toUpperCase()) {
			case "ADMIN":
				endpoint = `/admin/v1/admin-user`
				delete data["qualityiqUrl"]
				break
			case "PROVIDER":
				endpoint = `/admin/v1/provider-user`
				break
		}
		return makeApiCall(dispatch, "PUT", `${endpoint}`, data).then((payload) => {
			dispatch(showNotification(payload, "success", ""))
			dispatch(getUsersByOrgOrPractice())
			dispatch(setSelectedProfileRoleID(profileId))
		}).catch(err => {
			err?.message && dispatch(showNotification("Error", "error", String(err && err.message)))
		});
	}
}

export const deleteUser = (profileRoleJoinIds: any) => {
	return async (dispatch: ThunkDispatch<{}, {}, any>, getState: () => AppReduxStore) => {
		const { auth } = getState();

		makeApiCall(dispatch, "DELETE", `/admin/v1/users/profileRoleJoins` + queryStringParams(profileRoleJoinIds) + (auth?.currentPractice?.id ? `&practiceId=${auth?.currentPractice?.id}` : "")).then((payload) => {
			dispatch(showNotification(payload, "success", ""))
			dispatch(getUsersByOrgOrPractice())
		}).catch(err => {
			dispatch(showNotification("Error", "error", String(err && err.message)))
		});
	}
}

export const updateUserLevels = (levelId: string, organizationId: string, practiceId: string, profileIds: any, type: string) => {
	return async (dispatch: ThunkDispatch<{}, {}, any>, getState: () => AppReduxStore) => {
		const { admin, license, auth } = getState();
		let endpoint = "/admin/v1/user/levels"
		let params = { levelId, organizationId, practiceId, profileIds, type } as any
		if (admin.isAllSelectedBulk) {
			if (auth.currentPractice?.id) {
				endpoint = `/admin/v1/user/bulkLevels?practiceId=`
				endpoint = appendUserFliterAndSortOptionsToEndPoint(endpoint, admin, license)
				params = { levelId, practiceId, type }
			} else {
				endpoint = `/admin/v1/user/bulkLevelsByOrganization/${organizationId}?practiceId=`
				endpoint = appendUserFliterAndSortOptionsToEndPoint(endpoint, admin, license)
				params = { levelId, type }
			}
		}

		makeApiCall(dispatch, "PUT", endpoint, params).then((payload) => {
			dispatch(showNotification(payload, "success", ""))
			dispatch(getUsersByOrgOrPractice())

		}).catch((err) => {
			dispatch(showNotification("Error", "error", String(err && err.message)))
		})
	}
}

export const setUsersFilterAndSortOptions = (usersFilterText: string, usersPageNumber: number, usersPageSize: number, usersSortBy: string, usersSortAsc: boolean,
	filterHaveAnyProducts?: boolean, filterProductsIds?: string[], filterAccountStatus?: string[]) => {
	return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
		dispatch({ type: 'SET_USERS_FILTER_AND_SORT_OPTIONS', payload: { usersFilterText, usersSortBy, usersSortAsc, usersPageNumber, usersPageSize, filterHaveAnyProducts, filterProductsIds, filterAccountStatus } })
	}
}

export const bulkActionUserList = (users: any, type: string) => {
	localStorage.setItem("users", JSON.stringify(users))
	return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
		dispatch({ type: 'SET_USERS', payload: { users: users } })
	}
}

export const setSelectedPracticeLocation = (location: string) => {
	return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
		dispatch({ type: 'SET_SELECTED_PRACTICE_LOCATION', payload: { sLocation: location } })
	}
}
export const setSelectedProfile = (profile: string) => {
	return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
		dispatch({ type: 'SET_SELECTED_PROFILE', payload: { sProfile: profile } })
	}
}

export const unlockAccountAdmin = (userName: string, onStatusChanged?: any) => {
	return async (dispatch: ThunkDispatch<{}, {}, any>) => {
		makeApiCall(dispatch, "POST", "/admin/v1/user/unlockAccount?userName=" + encodeURIComponent(userName)).then((payload) => {
			dispatch(showNotification("Account Unlocked", "success"))
			onStatusChanged(true)
		}).catch(err => {
			dispatch(showNotification("Error", "error", String(err && err.message)))
			onStatusChanged(false)
		});
	}
}
export const changeAccountStatus = (profileRoleIds: any, status: string) => {
	return async (dispatch: ThunkDispatch<{}, {}, any>, getState: () => AppReduxStore) => {
		const { admin, license, auth } = getState()
		let endpoint = "/admin/v1/users/practice/status"
		let params = { ids: profileRoleIds, status: status } as any
		if (admin.isAllSelectedBulk) {
			if (auth.currentPractice) {
				endpoint = `/admin/v1/users/practice/bulkstatus?practiceId=${auth.currentPractice?.id}&updateStatus=${status}`
			} else {
				endpoint = `/admin/v1/users/organization/bulkstatus?orgId=${auth.currentOrg?.id}&updateStatus=${status}`
			}
			endpoint = appendUserFliterAndSortOptionsToEndPoint(endpoint, admin, license)
			params = {}
		}
		makeApiCall(dispatch, "PUT", endpoint, params).then((payload) => {
			dispatch(showNotification(payload, "success"))
			dispatch(getUsersByOrgOrPractice())
		}).catch(err => {
			dispatch(showNotification("Error", "error", String(err && err.message)))
		});

	}
}
export const changePatientAccountStatus = (profileRoleIds: any, status: string) => {
	return async (dispatch: ThunkDispatch<{}, {}, any>, getState: () => AppReduxStore) => {
		const { auth, admin } = getState()
		let endpoint = "/admin/v1/users/practice/status"
		let params = { ids: profileRoleIds, status: status } as any
		if (admin.isAllSelectedBulk) {
			endpoint = `/admin/v1/patients/bulk/registration/status?updateStatus=${status}&practiceId=${auth.currentPractice?.id}`
			endpoint = appendPatientFliterAndSortOptionsToEndPoint(endpoint, admin)
			params = {}
		}
		makeApiCall(dispatch, "PUT", endpoint, params).then((payload) => {
			dispatch(showNotification(payload, "success"))
			dispatch(getUsersByOrgOrPractice())
		}).catch(err => {
			dispatch(showNotification("Error", "error", String(err && err.message)))
		});

	}
}
export const sendInvites = (userRoleIds: string[]) => {

	return async (dispatch: ThunkDispatch<{}, {}, any>, getState: () => AppReduxStore) => {
		const { auth, admin, license } = getState();
		if (admin.isAllSelectedBulk) {
			let endpoint = auth.currentPractice?.id || admin.configurePracticeId ? `/admin/v1/users/invitation/bulkregistration?practiceId=${auth.currentPractice?.id || admin.configurePracticeId}`
				: `/admin/v1/users/invitation/bulkregistrationByOrgnization?orgId=${auth.currentOrg?.id}`
			endpoint = appendUserFliterAndSortOptionsToEndPoint(endpoint, admin, license)
			await makeApiCall(dispatch, "POST", endpoint).then((payload) => {
				if (payload) {
					if (Array.isArray(payload)) {
						if (payload.length > 0) {
							dispatch(showNotification("ERROR SENDING INVITES", "error", payload.length + " Missing Email Address, please update in EHR"))
						} else {
							dispatch(showNotification(`INVITES PENDING`, "success"))
						}
					}
					else {
						dispatch(showNotification(`INVITES PENDING`, "success"))
					}

				}
			}).catch(err => {
				dispatch(showNotification("ERROR SENDING INVITES", "error", String(err && err.message)))
			});
		}
		else {
			let invitesCount = 0
			for (let i = 0; i < userRoleIds.length; i++) {
				const params = {
					userRoleId: userRoleIds[i]
				}
				// eslint-disable-next-line no-loop-func
				await makeApiCall(dispatch, "POST", `/admin/v1/users/invitation/registration`, params).then((payload) => {
					if (payload) {
						invitesCount = invitesCount + 1
					}
				}).catch(err => {
					dispatch(showNotification("ERROR SENDING INVITES", "error", String(err && err.message)))
				});
			}

			if (invitesCount > 0) {
				dispatch(getUsersByOrgOrPractice())
				dispatch(showNotification(`${invitesCount} INVITES PENDING`, "success"))
			}
		}
	}
}

export const sendPatientInvite = (patientProfileIds: any[], practiceId: string) => {
	return async (dispatch: ThunkDispatch<{}, {}, any>, getState: () => AppReduxStore) => {
		const { auth, admin } = getState()
		let endpoint = "/admin/v1/patients/registrations"
		let params = { patientProfileIds, practiceId } as any
		if (admin.isAllSelectedBulk) {
			endpoint = `/admin/v1/patients/bulk/registrations?practiceId=${practiceId}`
			endpoint = appendPatientFliterAndSortOptionsToEndPoint(endpoint, admin)
			params = {}
		}
		return makeApiCall(dispatch, "POST", endpoint, params)
			.then((payload) => {
				if (Array.isArray(payload)) {
					if (payload.length > 0) {
						dispatch(showNotification("ERROR SENDING INVITES", "error", payload.length + " Missing Email Address, please update in EHR"))
					} else {
						dispatch(showNotification(`INVITES PENDING`, "success"))
					}
				}
				else {
					dispatch(showNotification(`INVITES PENDING`, "success"))
				}
				dispatch(getPatientsForPractice(auth?.currentPractice?.id));
			})
			.catch((err) => {
				dispatch(showNotification("ERROR SENDING INVITES", "error", String(err && err.message)));
			});
	};
};

export const setSelectedUsers = (sProfileIds: string[]) => {
	return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
		dispatch({ type: 'SET_SELECTED_USERS', payload: { sProfileIds: sProfileIds } })
	}
}
export const setBulkEditUserProduts = (isBulkEditUserProducts: boolean) => {
	return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
		dispatch({ type: 'SET_BULK_EDIT_USERS_PRODUCTS', payload: { isBulkEditUserProducts: isBulkEditUserProducts } })
	}
}
export const getPatientsForPractice = (practiceId: string) => {
	return async (dispatch: ThunkDispatch<{}, {}, any>, getState: () => AppReduxStore) => {
		const { admin } = getState();
		const { usersPageNumber, usersPageSize } = admin
		let endpoint = "/admin/v1/patients?practiceId=" + practiceId + `&pageNumber=${usersPageNumber}&pageSize=${usersPageSize}`
		endpoint = appendPatientFliterAndSortOptionsToEndPoint(endpoint, admin)
		dispatch(setPatientListHasUnder18Patient(practiceId))
		return makeApiCall(dispatch, "GET", endpoint, {},
			"Admin.Actions.FetchPatients", "Admin.Actions.FetchPatientsDesc")
			.then((payload) => {
				const patients: Patient[] = [];

				payload.content.forEach((p: any) => {
					const patient: Patient = {
						fullName: `${p.firstName} ${p.lastName}`,
						firstName: p.firstName,
						lastName: p.lastName,
						ehrPatientId: p.ehrPatientId,
						email: p.email,
						mrn: p.mrn,
						ssn: p.ssn,
						status: p.status,
						profileRoleId: p.profileRoleJoinId,
						profileId: p.profileId,
						dateOfBirth: p?.dateOfBirth,
						age: Math.floor(moment().diff(p?.dateOfBirth, 'years', true)),
						levelName: p?.levelName,
						supportContacts: p?.supportContacts
					};

					patients.push(patient);
				});
				dispatch({ type: 'SET_PATIENTS_CONTENT', payload: { patientsContent: payload } });
				dispatch({ type: 'SET_PATIENTS_FOR_PRACTICE', payload: { patients: patients } });
			})
			.catch((error) => {
				dispatch(showNotification("Get Patients", "error", String(error)));
			});
	}
};
const appendPatientFliterAndSortOptionsToEndPoint = (endpoint: string, adminState: AdminState) => {
	const { usersFilterText, usersSortBy, usersSortAsc } = adminState
	if (usersFilterText && usersFilterText.trim() !== "") {
		endpoint = endpoint + `&name=${usersFilterText}`
	}
	if (usersSortBy && usersSortBy.trim() !== "") {
		let sortBy = usersSortBy.trim()
		if (sortBy === "fullName") {
			sortBy = "firstName"
		}
		endpoint = endpoint + `&sortedBy=${sortBy}&sortAsc=${usersSortAsc}`
	}
	return endpoint
}
export const setPatientListHasUnder18Patient = (practiceId: string) => {
	return async (dispatch: ThunkDispatch<{}, {}, any>, getState: () => AppReduxStore) => {
		const { admin } = getState();
		const { usersPageNumber, usersPageSize } = admin
		let endpoint = "/admin/v1/patients/dependentPatientFlag?practiceId=" + practiceId + `&pageNumber=${usersPageNumber}&pageSize=${usersPageSize}`
		endpoint = appendPatientFliterAndSortOptionsToEndPoint(endpoint, admin)
		return makeApiCall(dispatch, "GET", endpoint)
			.then((payload) => {
				dispatch({ type: 'SET_PATIENT_LIST_HAS_UNDER_18_PATIENT', payload: { patientListHasUnder18Patient: payload } });
			})
			.catch((err) => {
			});
	};
}
export const createPatient = (data: any) => {
	return async (dispatch: ThunkDispatch<{}, {}, any>, getSate: () => AppReduxStore) => {
		const { auth } = getSate()
		return await makeApiCall(dispatch, "POST", `/admin/v1/patient-user`, data).then((payload) => {
			dispatch(showNotification(payload, "success", ""))
			dispatch(getPatientsForPractice(auth.currentPractice?.id))
			return payload;
		}).catch(err => {
			dispatch(showNotification("Error", "error", String(err && err.message)))
			return null;
		});
	}
}

export const editPatient = (data: any) => {
	return async (dispatch: ThunkDispatch<{}, {}, any>, getSate: () => AppReduxStore) => {
		const { auth } = getSate()
		return makeApiCall(dispatch, "PUT", `/admin/v1/patient-user`, data).then((payload) => {
			dispatch(showNotification(payload, "success", ""))
			dispatch(getPatientsForPractice(auth.currentPractice?.id))
		}).catch(err => {
			dispatch(showNotification("Error", "error", String(err && err.message)))
		});
	}
}

export const deletePatient = (patientId: string, practiceId: string) => {
	return async (dispatch: ThunkDispatch<{}, {}, any>, getSate: () => AppReduxStore) => {
		const { auth } = getSate()
		return makeApiCall(dispatch, "DELETE", `/admin/v1/patient-user/${patientId}?practiceId=${practiceId}`, {}).then((payload) => {
			dispatch(showNotification(payload, "success", ""))
			dispatch(getPatientsForPractice(auth.currentPractice?.id))
		}).catch(err => {
			dispatch(showNotification("Error", "error", String(err && err.message)))
		});
	}
}
export const updateRegistrationEmailContent = (Body: any, Warm: string, Greeting: string, Sign: any, type: string) => {
	return async (dispatch: ThunkDispatch<{}, {}, any>) => {
		return makeApiCall(dispatch, "PUT", "/admin/v1/practice/configuration/registration", { Body, Warm, Greeting, Sign, type })
			.then((payload) => {
				dispatch(showNotification("Registration sent", "success", ""));
			})
			.catch((err) => {
				dispatch(showNotification("Error sending invite", "error", ""));
			});
	};
};
export const setIsAllSelectedForBulk = (isAllSelectedBulk: boolean) => {
	return async (dispatch: ThunkDispatch<{}, {}, any>) => {
		dispatch({ type: 'SET_IS_ALL_SELECTED_BULK', payload: { isAllSelectedBulk } })
	}
}
export const userProductsBulkProfileRoleJoinAll = (productIds: any) => {
	return async (dispatch: ThunkDispatch<{}, {}, any>, getSate: () => AppReduxStore) => {
		const { auth, admin, license } = getSate()
		let endpoint = auth.currentPractice ? "/admin/v1/license/product/bulkProfileRoleJoin?practiceId=" + auth.currentPractice?.id
			: "/admin/v1/license/product/bulkProfileRoleJoinByOrg?orgId=" + auth.currentOrg?.id
		endpoint = appendUserFliterAndSortOptionsToEndPoint(endpoint, admin, license)
		await makeApiCall(dispatch, "POST", endpoint, productIds)
			.then((payload) => {
				dispatch(getUsersByOrgOrPractice())
				dispatch(showNotification(`User licenses updated successfully.`, "success"))
			})
			.catch((err) => {
				dispatch(showNotification("Error", "error", err.message))
			})
	}
}
export const forgotUserPasswordBulkAll = () => {
	return async (dispatch: ThunkDispatch<{}, {}, any>, getSate: () => AppReduxStore) => {
		const { auth, admin } = getSate()
		let endpoint = `/admin/v1/user/bulk/forgotPassword?platform=WEB&practiceId=${auth.currentPractice?.id}`
		endpoint = appendPatientFliterAndSortOptionsToEndPoint(endpoint, admin)
		makeApiCall(dispatch, "POST", endpoint).then(() => {
			dispatch(showNotification(`Password change email sent successfully.`, "success"))
		}).catch((err) => {
			dispatch(showNotification("Error", "error", err.message))
		})
	}
}
export const forgotUserPassword = (username: string) => {
	return new Promise((resolve, reject) => {
		makeApiCall(null, "POST", `/admin/v1/user/forgotPassword`, { platform: "WEB", username }).then((response) => {
			resolve(response)
		}).catch((error) => {
			reject(error)
		})
	})
}
export const forgotUserPasswordByAdmin = (username: string) => {
	return new Promise((resolve, reject) => {
		makeApiCall(null, "POST", `/admin/v1/user/forgotPasswordByAdmin`, { platform: "WEB", username }).then((response) => {
			resolve(response)
		}).catch((error) => {
			reject(error)
		})
	})
}
export const verifySecretAnswer = (username: string, secretAnswer: string) => {
	return new Promise((resolve, reject) => {
		makeApiCall(null, "POST", `/admin/v1/user/verify-secret-answer`, { platform: "WEB", username, secretAnswer }).then((response) => {
			resolve(response)
		}).catch((error) => {
			reject(error)
		})
	})
}
export const setSelectedPatientSupportNetwork = (supportNetwork: any) => {
	return async (dispatch: ThunkDispatch<{}, {}, AnyAction>, getState: () => AppReduxStore) => {
		dispatch({ type: 'SET_SELECTED_PATIENT_SUPPORT_NETWORK', payload: { sSupportNetwork: supportNetwork } })
	}
}
export const getWebAdminCacheOrganization = (formType: string,) => {
	return async (dispatch: ThunkDispatch<{}, {}, any>, getState: () => AppReduxStore) => {
		const { auth } = getState()
		return makeApiCall(dispatch, "GET", "/admin/v1/users/admin-cache", { formType, profileRoleJoinId: auth.currentProfileRoleJoinInfo?.id })
			.then((payload) => {
				dispatch({ type: 'SET_ADMIN_WEB_CACHE_ORGANIZATION', payload: { adminWebCacheOrganization: payload === "" ? null : payload } })
			})
			.catch((err) => {
			})
	}
}
export const updateWebAdminCacheOrganization = (formType: string, formData: any,) => {
	return async (dispatch: ThunkDispatch<{}, {}, any>, getState: () => AppReduxStore) => {
		const { auth } = getState()
		return makeApiCall(dispatch, "PUT", "/admin/v1/users/admin-cache", { formData: formData ? formData : {}, formType, profileRoleJoinId: auth.currentProfileRoleJoinInfo?.id })
			.then((payload) => {
				dispatch({ type: 'SET_ADMIN_WEB_CACHE_ORGANIZATION', payload: { adminWebCacheOrganization: { formData, formType, profileRoleJoinId: auth.currentProfileRoleJoinInfo?.id } } })
			})
			.catch((err) => {
				dispatch(showNotification("Error in setting admin cache for organization", "error", ""));
			})
	}
}
export const getWebAdminCachePractice = (formType: string,) => {
	return async (dispatch: ThunkDispatch<{}, {}, any>, getState: () => AppReduxStore) => {
		const { auth } = getState()
		return makeApiCall(dispatch, "GET", "/admin/v1/users/admin-cache", { formType, profileRoleJoinId: auth.currentProfileRoleJoinInfo?.id })
			.then((payload) => {
				dispatch({ type: 'SET_ADMIN_WEB_CACHE_PRACTICE', payload: { adminWebCachePractice: payload === "" ? null : payload } })
			})
			.catch((err) => {
			})
	}
}
export const updateWebAdminCachePractice = (formType: string, formData: any,) => {
	return async (dispatch: ThunkDispatch<{}, {}, any>, getState: () => AppReduxStore) => {
		const { auth } = getState()
		return makeApiCall(dispatch, "PUT", "/admin/v1/users/admin-cache", { formData: formData ? formData : {}, formType, profileRoleJoinId: auth.currentProfileRoleJoinInfo?.id })
			.then((payload) => {
				dispatch({ type: 'SET_ADMIN_WEB_CACHE_PRACTICE', payload: { adminWebCachePractice: { formData, formType, profileRoleJoinId: auth.currentProfileRoleJoinInfo?.id } } })
			})
			.catch((err) => {
				dispatch(showNotification("Error in setting admin cache for practice", "error", ""));
			})
	}
}

export const setConfigurePractice = (configurePracticeId?: string | null, configurePracticeName?: string | null) => {
	return async (dispatch: ThunkDispatch<any, any, AnyAction>, getState: () => AppReduxStore): Promise<any> => {
		return new Promise(async (resolve, reject) => {
			try {
				let p: any
				if (!configurePracticeId) {
					dispatch({ type: 'SET_CONFIGURE_PRACTICE', payload: { configurePracticeId: null, configurePracticeName: null } })
					dispatch({ type: 'SET_CONFIGURE_PRACTICE_PRODUCTS', payload: { configurePracticeProducts: [] } })
				}
				await dispatch(setSelectedPracticeId(configurePracticeId as string))
					.then((payload) => {
						p = payload
					})
					.catch((err) => {
						reject(err)
					})

				if (!p?.id) {
					dispatch(showNotification("Configure Practice", "error", "Practice Id not found"))
					reject(new Error("Practice Id not found"))
					return
				}
				if (configurePracticeId && configurePracticeId.length > 0) {
					const practiceProducts = await makeApiCall(dispatch, "GET", "/admin/v1/license/products/practice/" + configurePracticeId);
					dispatch({ type: 'SET_CONFIGURE_PRACTICE_PRODUCTS', payload: { configurePracticeProducts: practiceProducts } })
					dispatch({ type: 'SET_CONFIGURE_PRACTICE', payload: { configurePracticeId, configurePracticeName } })
					resolve(practiceProducts)
				} else {
					dispatch({ type: 'SET_CONFIGURE_PRACTICE', payload: { configurePracticeId: null, configurePracticeName: null } })
					dispatch({ type: 'SET_CONFIGURE_PRACTICE_PRODUCTS', payload: { configurePracticeProducts: [] } })
				}

			} catch (err: any) {
				reject(err)
				dispatch(showNotification("Get Configure Practice Products", "error", err.message))
			}
		})
	}
}