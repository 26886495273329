import { Grid, TextField, Box, Tooltip} from '@mui/material';
import dayjs from 'dayjs';

type MemberRecordType = {
  id?: string;
  ipaName?: string;
  lineOfBusiness?: string;
  hcpsk?: string;
  mrn?: string;
  memberId?: string;
  patientFirstName?: string;
  patientLastName?: string;
  dateOfBirthAsString?: string;
  mainCcpProvider?: string;
  assignedPcpName?: string;
  programStatus?:string;
  memberProgramStatus?: string;
  memberStatusDateAsString?: string;
};

type MemberDataFormProps = {
  result: MemberRecordType
}
export const BSCMemberDataForm = (props: MemberDataFormProps) => {

  const selectedMember: MemberRecordType = props?.result || [];

  return (
    <div>
      {(props?.result) && (
        <Box className='formContainer'>
          
          <form>
          <Grid container spacing={2} style={{ marginLeft: "18px" ,maxWidth: "98%" }}>
              {[
                { label: "Name", value: `${selectedMember.patientLastName}, ${selectedMember.patientFirstName}` },
                { label: "Member ID", value: selectedMember.memberId },
                { label: "HCPSK", value: selectedMember.hcpsk },
                { label: "Date of Birth", value: selectedMember.dateOfBirthAsString },
                { label: "Main CCP", value: selectedMember.mainCcpProvider },
                { label: "Assigned PCP Name", value: selectedMember.assignedPcpName },
                { label: "Program Status", value: selectedMember.memberProgramStatus },
                { label: "Status DateTime", value: selectedMember.memberStatusDateAsString? dayjs(selectedMember.memberStatusDateAsString).format('MM/DD/YYYY hh:mm A'):'' }, //{row.appCancelDateAsString ?dayjs(row.appCancelDateAsString).format('MM/DD/YYYY hh:mm A') : ''}
                { label: "Line of Business", value: selectedMember.lineOfBusiness },
                { label: "IPA Name", value: selectedMember.ipaName }
              ].map((field, index) => (
                <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
                  <Tooltip title={(field.value?field.value:'')} arrow>
                  <TextField
                    label={field.label}
                    fullWidth
                    margin="normal"
                    InputProps={{ readOnly: true }}
                    InputLabelProps={{ shrink: true }}
                    className='formField'
                    value={field.value}
                  />
                  </Tooltip>
        </Grid>
      ))}
    </Grid>
          </form>
        </Box>
      )}
    </div>
  );
};