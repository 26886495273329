import { Box, Button, Paper, Typography } from "@mui/material";
import { call as adminCall } from "admin/store/api";
import { getAppCurrentProfileId } from "authentication/appAuth";
import Spinner from "common/components/Spinner/Spinner";
import { useUserNotification } from "common/utils/use-user-notification";
import { ReactComponent as BlankFile } from "icon-library/SVG/File-Light_Blank.svg";
import { ReactComponent as UserGroup } from "icon-library/SVG/Chart_Bar.svg";
import { ReactComponent as ContactUs } from "icon-library/SVG/Phone-Light_Answer.svg";
import { ReactComponent as Warning } from "icon-library/SVG/Warning_Circle.svg";
import { useEnabledPermissions } from "lib/security/permission-hooks";
import { useCallback, useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { call } from "store/api";
import { AppReduxStore } from "store/reducerTypes";
import { find } from "underscore";
import { createSvgIcon } from "utils";
import { AdditionalResources } from "views/PEPHome/components/additional-resources/additional-resources";
import { handleDownload } from "../components/file-download/file-download";
import { useStyles } from "./styles";

type PracticeType = {
    fileName: string;
    id: string;
    npi: string;
    organizationId: string;
    practiceId: string;
    profileRoleJoinId: string;
    reportType: string;
    status: string;
    firstName: string;
    middleName: string;
    lastName: string;
    prefix: string;
    providerName: string;
};

export const PEPAhaPaymentSummaryReports = () => {
    const { user } = useSelector((store: AppReduxStore) => store);
    const { currentProfileRoleJoin } = user;
    const hasAdminAccess =
        currentProfileRoleJoin?.level?.name === "PRACTICE_ADMIN" || currentProfileRoleJoin?.level?.name === "STAFF_2";
    const { enqueueError } = useUserNotification();
    const [reports, setReports] = useState<any[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const classes = useStyles();
    const intl = useIntl();
    const dispatch = useDispatch();
    const UserGroupIcon = createSvgIcon(UserGroup);
    const WarningIcon = createSvgIcon(Warning);
    const grantedPermissions = useEnabledPermissions();
    const licenseOptionName = "AHA_PAYMENT_SUMMARY";
    const reportName = "AHA_PAYMENT_REPORT";
    const requiredPermissionsList = [licenseOptionName];
    const hasPermission = () => {
        return requiredPermissionsList.every((permission: any) => grantedPermissions.includes(permission));
    };
    const emailBodyString = (): string => {
        const providerProfile = currentProfileRoleJoin?.providerProfile;
        const practice = currentProfileRoleJoin?.practice;
        const middleName = providerProfile?.middleName ? ` ${providerProfile.middleName} ` : ` `;
        const fullName = `${providerProfile?.firstName}${middleName}${providerProfile?.lastName}`;
        const practiceName = practice?.name;
        const npi = providerProfile?.npi;
        const phone = currentProfileRoleJoin?.profilePrimaryPhone;
        const newLine = "%0d%0a";
        const fullNameStr = providerProfile?.firstName && providerProfile?.lastName ? `Full Name: ${fullName} ` : "";
        const practiceNameStr = practiceName ? `${newLine}Practice Name: ${practiceName} ` : "";
        const npiStr = npi ? `${newLine}NPI: ${npi} ` : "";
        const phoneStr = phone ? `${newLine}Best Contact Phone Number: ${phone}` : "";
        const bodyStrEmpty = fullNameStr === "" && practiceNameStr === "" && npiStr === "" && phoneStr === "";
        return bodyStrEmpty ? "" : `&body=${fullNameStr}${practiceNameStr}${npiStr}${phoneStr}`;
    };

    const getAdminReports = useCallback(async () => {
        const practiceId = currentProfileRoleJoin?.practice?.id;
        const endpointURL = `/admin/v1/practice/reports?reportType=${reportName}`;

        if (practiceId) {
            const response = await adminCall("GET", endpointURL, {}, { "x-application-practiceid": practiceId }).catch(
                () => {
                    enqueueError("aha.payment.summary.report.get.listing.error");
                }
            );
            if (response) {
                setReports(response);
            }
        }
    }, [currentProfileRoleJoin?.practice, enqueueError]);

    const getProviderReports = useCallback(async () => {
        const profileRoleJoinId = currentProfileRoleJoin?.id;
        const practiceId = currentProfileRoleJoin?.practice?.id;
        const endpointURL = `/admin/v1/provider/reports?profileRoleJoinId=${profileRoleJoinId}&practiceId=${practiceId}&reportType=${reportName}`;

        if (profileRoleJoinId) {
            const response = await call("GET", endpointURL, {}).catch(() => {
                enqueueError("aha.payment.summary.report.get.listing.error");
            });
            if (response && response.content) {
                setReports(response.content);
            }
        }
    }, [currentProfileRoleJoin?.id, enqueueError]);

    type ObjectType = {
        [key: string]: any;
    };

    const handleDownloadClick = async (index?: number) => {
        const practiceId = currentProfileRoleJoin?.practice?.id;
        const reportsObj = reports.filter((report: any) => {
            return report.reportType === reportName;
        });
        const fileName = reportsObj[index ? index : 0]?.fileName;
        const fileRequest = {
            fileName: fileName,
            fileType: "AHA_PAYMENT_REPORT",
            practiceId: practiceId,
        };
        const endpointURL = `/admin/v1/provider/document/download`;

        await handleDownload(endpointURL, fileRequest).catch(() => {
            enqueueError("aha.payment.summary.report.download.error");
        });
    };

    const [filesAvaibility, setFilesAvaibility] = useState<ObjectType>({});

    const checkFilesAvailability = useCallback(async (reportsListing: any) => {
        const endpointURL = `/admin/v1/provider/documents/status`;
        const fileNames: string[] = reportsListing.map((report: any) => {
            return report.fileName;
        });
        const fileList: string[] = fileNames.filter((fileName: string, index: number) => {
            return fileNames.indexOf(fileName) === index;
        });
        const s3FileStatusRequest = {
            fileNames: fileList,
            fileType: "AHA_PAYMENT_REPORT",
            practiceId: currentProfileRoleJoin?.practice?.id,
        };
        const response = await call("POST", endpointURL, s3FileStatusRequest);
        if (response) {
            setFilesAvaibility(response);
        }
    }, []);

    useEffect(() => {
        const practiceId = currentProfileRoleJoin?.practice?.id;
        if (practiceId && practiceId !== "" && reports && reports.length > 0) {
            checkFilesAvailability(reports);
        }
    }, [reports]);

    useEffect(() => {
        const getUser = async () => {
            const currentProfileId: string | undefined | null = getAppCurrentProfileId();
            const getCurrentRoleJoin = (
                profileRoleJoinInfos: any,
                currentProfileRoleJoinId: string | undefined | null
            ) => {
                if (!profileRoleJoinInfos && !currentProfileRoleJoinId) return null;
                return find(
                    profileRoleJoinInfos,
                    (profileRoleJoinInfo: any) => profileRoleJoinInfo.id === currentProfileRoleJoinId
                );
            };
            try {
                const { user, twilioToken } = await call("GET", "/admin/v1/user/current");
                const currentProfileRoleJoin = getCurrentRoleJoin(user?.profileRoleJoinInfos, currentProfileId);
                dispatch({ type: "SET_USER", payload: { ...user, currentProfileRoleJoin } });
                dispatch({
                    type: "SET_CURRENT_PROFILE_ID",
                    payload: {
                        currentProfileId: currentProfileRoleJoin?.profileId,
                        currentUserType: currentProfileRoleJoin?.type,
                    },
                });
                dispatch({ type: "SET_TWILIO_TOKEN", payload: { token: twilioToken } });
            } catch (error: any) {
                enqueueError("aha.payment.summary.report.get.user.error");
            } finally {
                setIsLoading(false);
            }
        };
        if (!user.username) {
            setIsLoading(true);
            getUser();
        }
    }, [dispatch, user.username, enqueueError]);

    useEffect(() => {
        if (!!currentProfileRoleJoin?.id) {
            if (hasAdminAccess) getAdminReports();
            else getProviderReports();
        }
    }, [currentProfileRoleJoin?.id, getAdminReports, getProviderReports, hasAdminAccess]);

    useEffect(() => {
        const documentRoot = document.getElementById("root");
        if (documentRoot) {
            documentRoot.style.height = "85%";
            documentRoot.style.minWidth = "768px";
        }
    }, []);

    const additionalResourcesConfig = [
        {
            name: "patient_care_resources",
            action: {
                id: "patient_care_resources",
                name: "Patient Care Resources",
                href: "https://www.brownandtoland.com/contact-us/",
                target: "_blank",
            },
            icon: BlankFile,
            enabled: true,
        },
        {
            name: "contact_us",
            action: {
                id: "contact_us",
                name: intl.formatMessage({ id: "pep.fee.schedules.right.link.contact.us" }),
                href: "https://www.brownandtoland.com/contact-us/",
                target: "_blank",
            },
            icon: ContactUs,
            enabled: true,
        },
    ];

    return isLoading ? (
        <Spinner />
    ) : (
        <div id="pep_aha_payment_summary_reports_row" className={classes.pep_aha_payment_summary_reports_row}>
            <div
                id="pep_aha_payment_summary_reportscontainer"
                className={classes.pep_aha_payment_summary_reports_container}
            >
                <div id="pep_aha_payment_summary_reports_left" className={classes.pep_aha_payment_summary_reports_left}>
                    <div
                        id="pep_aha_payment_summary_reports_top"
                        className={classes.pep_aha_payment_summary_reports_top}
                    >
                        <div
                            id="pep_aha_payment_summary_reports_banner"
                            className={classes.pep_aha_payment_summary_reports_banner}
                        >
                            <Typography
                                id="pep_aha_payment_summary_reports_banner_top"
                                className={classes.pep_aha_payment_summary_reports_banner_top}
                            >
                                <FormattedMessage id={"aha.payment.summary.banner.line1"} />
                            </Typography>
                            <Typography
                                id="pep_aha_payment_summary_reports_banner_top"
                                className={classes.pep_aha_payment_summary_reports_banner_bottom}
                            >
                                <FormattedMessage id={"aha.payment.summary.banner.line2"} />
                            </Typography>
                        </div>
                    </div>
                    {isLoading || user.username === "" ? (
                        <Spinner />
                    ) : hasPermission() ? (
                        <Box
                            flexGrow={1}
                            justifyContent="center"
                            alignItems="center"
                            height="calc(100% - 235px)"
                            width="100%"
                            style={{ paddingLeft: "20px", paddingRight: "20px" }}
                        >
                            <Typography variant="h3" className={classes.paragraphStyle}>
                                <FormattedMessage id={"aha.payment.summary.description.paragraph.1"} />
                            </Typography>
                            <Typography variant="h3" className={classes.paragraphStyle}>
                                <FormattedMessage id={"aha.payment.summary.description.paragraph.2"} />
                            </Typography>
                            {!hasAdminAccess ? (
                                filesAvaibility[reports[0]?.fileName] ? (
                                    <div
                                        style={{
                                            display: "block",
                                            width: "656px",
                                            marginTop: "60px",
                                            marginLeft: "auto",
                                            marginRight: "auto",
                                        }}
                                    >
                                        <Paper
                                            onClick={() => {
                                                handleDownloadClick();
                                            }}
                                            elevation={6}
                                            style={{
                                                width: "656px",
                                                display: "flex",
                                                flexDirection: "row",
                                                alignItems: "flexStart",
                                                background:
                                                    "linear-gradient(180deg, #F6F6F6 0%, rgba(246, 246, 246, 0) 100%)",
                                                border: "1px solid #6E1E80",
                                                borderRadius: "16px",
                                                padding: "24px",
                                                gap: "16px",
                                                cursor: "pointer",
                                            }}
                                        >
                                            <UserGroupIcon className={classes.userGroupIcon} />
                                            <Box p={1}>
                                                <Typography className={classes.ahaButtonTitle}>
                                                    <FormattedMessage id={"aha.payment.summary.report.button.title"} />
                                                </Typography>
                                                <Typography className={classes.ahaButtonContent}>
                                                    <FormattedMessage
                                                        id={"aha.payment.summary.report.button.content"}
                                                    />
                                                </Typography>
                                            </Box>
                                        </Paper>
                                    </div>
                                ) : (
                                    <div
                                        style={{
                                            display: "block",
                                            width: "656px",
                                            marginTop: "60px",
                                            marginLeft: "auto",
                                            marginRight: "auto",
                                        }}
                                    >
                                        <Paper
                                            elevation={6}
                                            style={{
                                                width: "656px",
                                                display: "flex",
                                                flexDirection: "row",
                                                alignItems: "flexStart",
                                                background: "rgba(0, 0, 0, 0.12)",
                                                border: "1px solid rgba(0, 0, 0, 0.26)",
                                                borderRadius: "16px",
                                                padding: "24px",
                                                gap: "16px",
                                                cursor: "default",
                                                boxShadow: "none",
                                            }}
                                        >
                                            <UserGroupIcon className={classes.userGroupIconDisabled} />
                                            <Box p={1}>
                                                <Typography className={classes.ahaButtonTitleDisabled}>
                                                    {`Your AHA Payment Summary Report is currently unavailable`}
                                                </Typography>
                                                <Typography className={classes.ahaButtonContentDisabled}>
                                                    {`Please check back later`}
                                                </Typography>
                                            </Box>
                                        </Paper>
                                    </div>
                                )
                            ) : (
                                <>
                                    <br />
                                    <div className={classes.tableContainer}>
                                        <table className={classes.tableMain}>
                                            <colgroup>
                                                <col style={{ width: "400px" }}></col>
                                                <col style={{ width: "150px" }}></col>
                                            </colgroup>
                                            <thead>
                                                <tr className={classes.tableHeaderRow}>
                                                    <th className={classes.tableHeaderRowLeft} align="left">
                                                        Primary Care Physician
                                                    </th>
                                                    <th className={classes.tableHeaderRowRight} align="left">
                                                        Action
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {reports.length > 0 ? (
                                                    reports.map((report: PracticeType, index: number) => {
                                                        return (
                                                            <tr
                                                                key={`pep_performance_report_table_row_${index}`}
                                                                className={classes.tableBodyRow}
                                                            >
                                                                <td
                                                                    style={{
                                                                        paddingLeft: "10px",
                                                                        fontWeight: "700",
                                                                        fontSize: "14px",
                                                                    }}
                                                                >
                                                                    {`${report.prefix ? `${report.prefix} ` : ``} ${
                                                                        report.providerName
                                                                    }`}
                                                                </td>
                                                                <td>
                                                                    <Button
                                                                        className={classes.tableDownloadButton}
                                                                        variant="contained"
                                                                        color="primary"
                                                                        disabled={
                                                                            !filesAvaibility[reports[index].fileName]
                                                                        }
                                                                        onClick={() => {
                                                                            handleDownloadClick(index);
                                                                        }}
                                                                    >
                                                                        <FormattedMessage
                                                                            id={"aha.report.download.button.label"}
                                                                        />
                                                                    </Button>
                                                                </td>
                                                            </tr>
                                                        );
                                                    })
                                                ) : (
                                                    <tr className={classes.tableBodyRow}>
                                                        <td colSpan={2} style={{ paddingLeft: "10px" }}>
                                                            <FormattedMessage id={"aha.report.download.no.reports"} />
                                                        </td>
                                                    </tr>
                                                )}
                                            </tbody>
                                        </table>
                                    </div>
                                </>
                            )}
                            <br />
                        </Box>
                    ) : (
                        <Box
                            flexGrow={1}
                            justifyContent="center"
                            alignItems="center"
                            height="calc(100% - 235px)"
                            width="100%"
                            style={{ paddingTop: "15px" }}
                        >
                            <br />
                            <Paper
                                elevation={6}
                                style={{
                                    minWidth: "734.4px",
                                    width: "56%",
                                    display: "block",
                                    marginLeft: "auto",
                                    marginRight: "auto",
                                    border: "2px solid red",
                                    borderRadius: "10px",
                                    paddingTop: "10px",
                                    paddingBottom: "10px",
                                    paddingLeft: "20px",
                                    paddingRight: "20px",
                                }}
                            >
                                <Box component="span" display="flex" alignItems="center">
                                    <WarningIcon className={classes.warningIcon} />
                                    <Box p={1}>
                                        <Typography className={classes.errorButtonTitle}>
                                            <FormattedMessage id={"error.description.title"} />
                                        </Typography>
                                        <Typography className={classes.ahaButtonContent}>
                                            <FormattedMessage id={"error.description.details.1"} />
                                            <a
                                                href={`mailto:support@btmg.com?subject=Provider%20Report%20Support%20Request${emailBodyString()}`}
                                                target="_top"
                                            >
                                                support@btmg.com.
                                            </a>
                                        </Typography>
                                        <Typography className={classes.ahaButtonContentLine2}>
                                            <FormattedMessage id={"error.description.details.2"} />
                                        </Typography>
                                    </Box>
                                </Box>
                            </Paper>
                            <br />
                        </Box>
                    )}
                </div>
                <div id="aha_payment_summary_reports_right" className={classes.pep_aha_payment_summary_reports_right}>
                    <AdditionalResources config={additionalResourcesConfig} />
                </div>
            </div>
        </div>
    );
};
