import { makeStyles } from "@mui/styles";
export const useStyles = makeStyles(() => ({
  underline: {
    "&&&:before": {
      borderBottom: "none"
    },
    "&&:after": {
      borderBottom: "none"
    }
  },
  root: {
    width: 'auto',
    marginLeft: '8px',
    "& .MuiSelect-select": {
      paddingLeft: '10px',
      paddingRight: '10px'
    },
    "& .MuiInputBase-input": {
      fontFamily: "OpenSans",
      paddingLeft: '8px',
      paddingRight: '10px',
      fontSize: '14px',
      minWidth: '0px'
    }
  },
  iconButtonStyle: {
    width: '40px',
    height: '40px',
    marginLeft: '2px',
    marginRight: '2px',
    padding: 0
  }
}));