import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles(() => ({
    pep_header_row: {
        width: '100%',
        background: '#FFFFFF',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        userSelect: 'none'
    },
    pep_header_container: {
        width: '100%',
        maxWidth: '1440px',
        background: '#FFFFFF'
    },
    pep_header_left: {
        display: 'inline-block',
        width: '50%',
        '@media (max-width:800px)': {
            width: '100%'
        }
    },
    pep_header_left_content: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'left',
        // marginLeft: '16px',
        marginRight: '16px'
    },
    pep_header_left_image: {
        '@media (max-width:396px)': {
            width: '100%'
        }
    },
    pep_header_center: {
        display: 'inline-block',
        width: '10%',
        '@media (max-width:975px)': {
            display: 'none'
        }
    },
    pep_header_right: {
        display: 'inline-block',
        width: '40%',
        '@media (max-width:975px) and (min-width:800px)': {
            width: '50%'
        },
        '@media (max-width:800px)': {
            display: 'none'
        }
    },
    pep_header_right_content: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'right'
    },
    pep_footer_row: {
        width: '100%',
        background: '#EDEEEF',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        userSelect: 'none'
    },
    pep_footer_container: {
        width: '100%',
        maxWidth: '1440px',
        background: '#EDEEEF',
        paddingLeft: '15px',
        paddingRight: '15px'
    },
    pep_footer_columns: {
        float: 'left',
        width: '33.33%',
        marginTop: '20px',
        boxSizing: 'border-box'
    },
    pep_footer_column_links: {
        margin: 0,
        padding: 0,
        listStyle: "none"
    },
    pep_footer_link_fb: {
        boxSizing: 'border-box',
        float: 'left',
        height: '30px',
        width: '30px',
        fontSize: '13px',
        lineHeight: '26px',
        textAlign: 'left',
        whiteSpace: 'nowrap',
        color: 'transparent',
        backgroundColor: 'transparent',
        cursor: 'pointer',
        listStyleType: 'none'
    },
    pep_footer_link_li: {
        boxSizing: 'border-box',
        float: 'left',
        height: '30px',
        width: '30px',
        fontSize: '13px',
        lineHeight: '26px',
        textAlign: 'left',
        whiteSpace: 'nowrap',
        color: 'transparent',
        backgroundColor: '#6E1E80',
        cursor: 'pointer',
        listStyleType: 'none',
        backgroundPosition: '0px 0px',
        marginLeft: '30px'
    },
    pep_footer_link: {
        fontWeight: 400,
        fontSize: '14px',
        lineHeight: '140%',
        color: '#404040',
        "&:hover": {
            color: '#006385'
        }
    },
    pep_footer_column_links_ul: {
        display: 'flex',
        flexWrap: 'wrap',
        paddingLeft: '0px',
        margin: '10px 0',
    },
    pep_footer_column_links_li: {
        listStyle: 'none',
        flex: '0 0 33.333333%',
        marginTop: '10px',
        marginBottom: '10px',
        fontStyle: 'normal',
        fontWeight: '400',
        fontSize: '14px',
        lineHeight: '140%',
        '@media (max-width:900px) and (min-width:601px)': {
            flex: '0 0 50%'
        },
        '@media (max-width:600px)': {
            flex: '0 0 100%'
        }
    },
    pep_navigation_row: {
        width: '100%',
        minWidth: '1440px',
        background: '#F0F1F3',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        userSelect: 'none'
    },
    pep_navigation_container: {
        width: '100%',
        maxWidth: '1440px',
        background: '#F0F1F3',
        paddingLeft: '15px',
        paddingRight: '15px',
        display: 'flex'
    },
    pep_navigation_top_home: {
        padding: '3px 16px',
        height: '48px',
        "& path": {
            fill: '#6E1E80'
        },
        "&:hover": {
            backgroundColor: '#CCE8EF',
            "& path": {
                fill: '#0084D5'
            }
        }
    },
    pep_navigation_top_home_selected: {
        padding: '3px 16px',
        height: '48px',
        backgroundColor: '#CCE8EF',
        "& path": {
            fill: '#6E1E80'
        }
    },
    pep_navigation_top_item: {
        fontWeight: '600',
        fontSize: '16px',
        lineHeight: '140%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '3px 16px',
        gap: '12px',
        height: '48px',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        color: '#6E1E80',
        "&:hover": {
            backgroundColor: '#CCE8EF',
            color: '#0084D5'
        }
    },
    pep_navigation_top_item_selected: {
        fontWeight: '600',
        fontSize: '16px',
        lineHeight: '140%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '3px 16px',
        gap: '12px',
        height: '48px',
        whiteSpace: 'nowrap',
        color: "#6E1E80",
        backgroundColor: '#CCE8EF',
        overflow: 'hidden',
    },
    pep_tiles_row: {
        height: '424px',
        width: '100%',
        minWidth: '1440px',
        background: '#F0F1F3',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        userSelect: 'none'
    },
    pep_tiles_container: {
        height: '424px',
        width: '100%',
        maxWidth: '1440px',
        background: '#F0F1F3',
        paddingLeft: '15px',
        paddingRight: '15px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    pep_tile_icon: {
        display: 'block',
        marginLeft: 'auto',
        marginRight: 'auto',
        height: '60px',
        width: '60px',
        background: '#CEE8EF',
        border: '2px solid #6E1E80',
        boxSizing: 'border-box',
        borderRadius: '60px',
        alignItems: 'center',
        "& path": {
            fill: '#6E1E80'
        },
    },
    pep_tile_name: {
        fontStyle: 'normal',
        fontWeight: '600',
        fontSize: '20px',
        lineHeight: '140%',
        textAlign: 'center',
        marginTop: '10px',
        marginBottom: '10px',
        letterSpacing: '0.02em',
        color: '#6E1E80',
        userSelect: 'none'
    },
    pep_tile_description: {
        fontStyle: 'normal',
        fontWeight: '400',
        fontSize: '16px',
        lineHeight: '140%',
        textAlign: 'center',
        color: '#404040',
        userSelect: 'none'
    },
    pep_tile_button: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '16px 24px',
        height: '60px',
        background: '#139AED',
        boxShadow: '0px 0px 2px rgba(0, 0, 0, 0.25), 0px 4px 8px rgba(0, 0, 0, 0.25)',
        borderRadius: '64px',
        textAlign: 'center'
    },
    copyright_row: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        height: '51px',
        alignItems: 'center',
        fontStyle: 'normal',
        fontWeight: '400',
        fontSize: '13px',
        lineHeight: '140%',
        color: '#000000',
        userSelect: 'none'
    },
    copyright_container: {
        width: '100%',
        height: '51px',
        maxWidth: '1440px',
        paddingLeft: '15px',
        paddingRight: '15px',
        display: 'flex',
        alignItems: 'center'
    },
    wp_pep_content: {
        width: '100%',
        border: 'none',
        overflow: 'hidden',
        userSelect: 'none'
    },
    wp_pep_home_top: {
        width: '100%',
        height: '600px',
        border: 'none',
        overflow: 'hidden',
        userSelect: 'none'
    },
    wp_pep_home_bottom: {
        width: '100%',
        height: '800px',
        border: 'none',
        overflow: 'hidden',
        userSelect: 'none'
    },
    pep_fee_schedules_row: {
        width: '100%',
        minWidth: '1440px',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        fontStyle: 'normal',
        fontWeight: '400',
        fontSize: '13px',
        lineHeight: '140%',
        color: '#000000',
        userSelect: 'none'
    },
    pep_fee_schedules_container: {
        width: '100%',
        maxWidth: '1440px',
        paddingLeft: '15px',
        paddingRight: '15px',
        display: 'flex'
    },
    pep_fee_schedules_tableContainer: {
        display: 'block',
        marginLeft: 'auto',
        marginRight: 'auto',
        marginBottom: '20px',
        width: '900px'
    },
    pep_fee_schedules_tableMain: {
        borderCollapse: 'collapse'
    },
    pep_fee_schedules_tableHeaderRow: {
        height: '48px',
        background: '#EDEDEF',
        boxShadow: '0 0 0 1px #E7E7ED',
        borderRadius: '8px 8px 0px 0px'
    },
    pep_fee_schedules_tableHeaderRowLeft: {
        borderRadius: '8px 0 0 0',
        paddingLeft: '10px',
        fontWeight: '600',
        fontSize: '14px'
    },
    pep_fee_schedules_tableHeaderRowRight: {
        borderRadius: '0 8px 0 0',
        paddingLeft: '10px',
        fontWeight: '600',
        fontSize: '14px'
    },
    pep_fee_schedules_tableBodyRow: {
        height: '64px',
        background: '#FFFFFF',
        border: '1px solid #E4E4E6'
    },
    pep_fee_schedules_tableDownloadButton: {
        cursor: 'pointer',
        fontWeight: '600',
        fontSize: '16px',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '4px 16px',
        gap: '10px',
        width: '111px',
        height: '30px',
        background: '#0F7BBD',
        boxShadow: '0px 0px 2px rgba(0, 0, 0, 0.25), 0px 2px 2px rgba(33, 33, 49, 0.24)',
        borderRadius: '64px',
        color: '#FFFFFF'
    }
}));