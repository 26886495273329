import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles(() => ({
    pep_clickable_card_disabled: {
        margin: '25px',
        width: '656px',
        height: '150px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        padding: '24px',
        gap: '16px',
        background: 'linear-gradient(180deg, #F6F6F6 0%, rgba(246, 246, 246, 0) 100%)',
        border: '1px solid darkgray',
        borderRadius: '16px',
        "& path": {
            fill: 'darkgray'
        },
        "& .pep_clickable_card_name": {
            fontFamily: 'BentonSansCond',
            fontWeight: '500',
            fontSize: '20px',
            lineHeight: '140%',
            letterSpacing: '0.02em',
            color: 'darkgray',
            margin: '0px 5px'
        },
        "& .pep_clickable_card_description": {
            fontWeight: '400',
            fontSize: '16px',
            lineHeight: '140%',
            color: 'darkgray',
            margin: '5px'
        }
    },
    pep_clickable_card: {
        cursor: 'pointer',
        margin: '25px',
        width: '656px',
        height: '150px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        padding: '24px',
        gap: '16px',
        background: 'linear-gradient(180deg, #F6F6F6 0%, rgba(246, 246, 246, 0) 100%)',
        border: '1px solid #6E1E80',
        borderRadius: '16px',
        "&:hover": {
            background: 'linear-gradient(180deg, #CEE8EF 0%, rgba(246, 246, 246, 0.1) 100%)',
            border: '2px solid #0084D5',
            borderRadius: '16px',
        }
    },
    pep_clickable_card_tall: {
        cursor: 'pointer',
        margin: '25px',
        width: '656px',
        height: '170px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        padding: '25px',
        gap: '16px',
        background: 'linear-gradient(180deg, #F6F6F6 0%, rgba(246, 246, 246, 0) 100%)',
        border: '1px solid #6E1E80',
        borderRadius: '16px',
        "&:hover": {
            background: 'linear-gradient(180deg, #CEE8EF 0%, rgba(246, 246, 246, 0.1) 100%)',
            border: '2px solid #0084D5',
            borderRadius: '16px',
        }
    },
    pep_clickable_card_icon: {
        height: '60px',
        width: '60px',
        "& path": {
            fill: '#0084D5'
        }
    },
    pep_clickable_card_name: {
        fontFamily: 'BentonSansCond',
        fontWeight: '500',
        fontSize: '20px',
        lineHeight: '140%',
        letterSpacing: '0.02em',
        color: '#6E1E80',
        margin: '0px 5px'
    },
    pep_clickable_card_description: {
        fontWeight: '400',
        fontSize: '16px',
        lineHeight: '140%',
        color: '#404040',
        margin: '5px'
    }
}))