import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles(() => ({
    pep_billing_claims_resources_row: {
        width: '100%', 
        minWidth: '1440px', 
        display: 'flex', 
        flexDirection: 'row', 
        justifyContent: 'center', 
        alignItems: 'center', 
        fontStyle: 'normal', 
        fontWeight: '400', 
        fontSize: '13px', 
        lineHeight: '140%', 
        color: '#000000',
        userSelect: 'none',
        marginBottom: '20px'
    },
    pep_billing_claims_resources_container: {
        width: '100%', 
        maxWidth: '1440px', 
        paddingLeft: '15px', 
        paddingRight: '15px'
    },
    pep_billing_claims_resources_top: {
        width: '100%'
    },
    pep_billing_claims_resources_left: {
        width: '50%'
    },
    pep_billing_claims_resources_banner: {
        marginTop: '20px', 
        marginBottom: '20px', 
        display: 'flex', 
        flexDirection: 'row', 
        alignItems: 'center', 
        padding: '16px 32px', 
        gap: '4px', 
        width: '1408px', 
        height: '80px', 
        background: 'linear-gradient(90deg, #F6F6F6 66.29%, rgba(246, 246, 246, 0) 100%)', 
        borderRadius: '4px'
    },
    pep_billing_claims_resources_banner_top: {
        fontFamily: 'BentonSansCond',
        fontStyle: 'normal', 
        fontWeight: '500', 
        fontSize: '32px', 
        lineHeight: '140%', 
        color: '#083d78'
    },
    pep_billing_claims_resources_banner_bottom: {
        fontStyle: 'normal', 
        fontWeight: '400', 
        fontSize: '16px', 
        lineHeight: '140%', 
        color: '#083d78'
    },
    pep_billing_claims_resources_description: {
        marginBottom: '20px', 
        fontStyle: 'normal', 
        fontWeight: '400', 
        fontSize: '16px', 
        lineHeight: '140%', 
        color: '#404040'
    },

    pep_billing_claims_resources_bottom: {
        marginTop: '20px', 
        marginBottom: '20px', 
        fontStyle: 'normal', 
        fontWeight: '400', 
        fontSize: '16px', 
        lineHeight: '140%', 
        color: '#404040'
    },
    pep_billing_claims_resources_right: {
        width: '50%'
    },
    pep_billing_claims_resources_right_additional_resources: {
        marginTop: '60px', 
        marginBottom: '10px', 
        fontStyle: 'normal', 
        fontWeight: '500', 
        fontSize: '24px', 
        lineHeight: '140%', 
        letterSpacing: '0.02em', 
        color: '#083d78'
    },
    pep_billing_claims_resources_right_links: {
        marginTop: '15px', 
        marginBottom: '15px', 
        fontStyle: 'normal', 
        fontWeight: '400', 
        fontSize: '16px', 
        lineHeight: '140%', 
        color: '#0084D5'
    },
    pep_billing_claims_resources_right_fee_schedules_right_links: {
        marginTop: '15px', 
        marginBottom: '15px', 
        fontStyle: 'normal', 
        fontWeight: '400', 
        fontSize: '16px', 
        lineHeight: '140%', 
        color: '#0084D5'
    },
    pep_billing_claims_resources_right_icons: {
        verticalAlign: 'middle', 
        marginRight: '10px', 
        width: '22px', 
        height: '22px', 
        fill: '#083d78'
    }
}));