import { Box, Button, Typography } from "@mui/material";
import { createGUIDString } from "admin/common/utils";
import { Form, useForm } from 'admin/components/useForm';
import { showLoading } from "admin/store/actions/loading";
import { AppReduxStore } from "admin/store/reducerTypes";
import { EMAIL_PATTERN, SESSION_STORAGE_REGISTRATION_ID } from "constant";
import { forwardRef, useEffect, useImperativeHandle } from "react";
import { FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { useStyles } from "./styles";

const initialFValues = {
    email: "",
    password: "",
    secretAnswer: ""
}
const Login = forwardRef((props: any, ref) => {
    const dispatch = useDispatch()
    const classes = useStyles()
    const { email: savedEmail, regToken } = useSelector((store: AppReduxStore) => store.registration)

    useImperativeHandle(
        ref,
        () => ({
            submitForm(e: any) {
                return handleSubmit(e)
            }
        })
    )

    const validate = (fieldValues = values) => {
        const err: any = { ...errors };
        if ('email' in fieldValues) {
            err.email = fieldValues.email.trim() ? "" : "This is a required field"
            if (fieldValues.email.trim() !== "" && !(EMAIL_PATTERN).test(fieldValues.email?.trim())) {
                err.email = "Email is not valid."
            }
        }
        if ('password' in fieldValues)
            err.password = fieldValues.password?.trim() ? "" : "This is a required field"
        setErrors({
            ...err
        })

        if (fieldValues === values)
            return Object.values(err).every(x => x === "")
    }
    const {
        values,
        errors,
        setErrors,
    } = useForm({
        ...initialFValues,
        email: savedEmail
    }, false, validate)

    const handleSubmit = async (e: any) => {
        e.preventDefault()
        await props.signIn();

        if (validate()) {
            setErrors({ password: "" })
            const guid = createGUIDString()
            dispatch(showLoading(guid, "Login.Auth.LoadingTitle", "Login.Auth.LoadingDescription"))
            if (regToken && regToken.length > 0) {
                //await dispatch(associateAccount(values.email?.trim(), values.password.trim()))
            }
            else {
                sessionStorage.removeItem(SESSION_STORAGE_REGISTRATION_ID)
            }

            return true
        }
        return false
    }

    useEffect(() => {
        const listener = (e: any) => {
            if (e.code === "Enter" || e.code === "NumpadEnter") {
                handleSubmit(e)
            }
        };
        document.addEventListener("keydown", listener)
        return () => {
            document.removeEventListener("keydown", listener)
        }
    })

    return (
        <Form onSubmit={handleSubmit}>
            <div>
                <Box className={classes.loginBtnWrapper} style={{ marginBottom: '15px' }}>
                    <Button id="btnLogin" type="submit" variant="contained" color="primary" className={classes.btnLogin} onClick={handleSubmit}>
                        <FormattedMessage id={"Login.btnLogin"} />
                    </Button>
                </Box>
                <br />
                <Typography variant="h6" className={classes.loginInformationText}>
                    <FormattedMessage id={"Login.lbl.LoginInformation"} />
                    <a href="mailto:support@btmg.com?subject=Provider%20Report%20Login/Access%20Request" target="_top">support@btmg.com.</a>
                </Typography>
            </div>
        </Form>
    )
})

export default Login