import {
    Box,
    Button,
    Checkbox,
    Input,
    ListItemText,
    MenuItem,
    Paper,
    Radio,
    RadioGroup,
    Select,
    SelectChangeEvent,
    Tooltip,
    Typography,
} from "@mui/material";
import { call } from "admin/store/api";
import DatePicker from "common/components/DatePicker/DatePicker";
import Spinner from "common/components/Spinner/Spinner";
import { useDateFormatters } from "common/utils/use-date-formatter";
import { useUserNotification } from "common/utils/use-user-notification";
import { ReactComponent as ScrollToTop } from "icon-library/SVG/Chevron_Up.svg";
import { useCallback, useEffect, useState } from "react";
import { addMinuteToCurrentDate, createSvgIcon } from "utils";
import { PEPCopyright } from "../pep-copyright";
import { PEPFooter } from "../pep-footer";
import { PEPHeader } from "../pep-header";
import { form_config, form_questions } from "./pep-newuser-config";
import { useStyles } from "./styles";

const PEPNewUserRequest = () => {
    const classes = useStyles();
    const [formState, setFormState] = useState<any[]>(form_questions);
    const [formSubmitted, setFormSubmitted] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [formHasError, setFormHasError] = useState<boolean>(false);
    const [providers, setProviders] = useState<string[]>([]);
    const dateFormatters = useDateFormatters();
    const { enqueueError } = useUserNotification();
    const ScrollToTopIcon = createSvgIcon(ScrollToTop);

    const validate = (section: any, question: any, value: any): boolean => {
        const questionValue = value;
        if (question.hidden && !canShowQuestion(section, question)) {
            return true;
        } else if (question.isRequired) {
            if (question.type === "checkbox") {
                if (questionValue && questionValue.length > 0) {
                    return true;
                } else {
                    return false;
                }
            } else {
                if (questionValue && questionValue !== "") {
                    if (question.dataType === "email") {
                        return /^\w+([.\-+]?\w+)*@\w+([.-]?\w+)*(\.\w{2,63})+$/.test(questionValue.trim());
                    } else if (question.dataType === "phone") {
                        return /^\(?(\d{3})\)?[- ]?(\d{3})[ ]?[-]?[ ]?(\d{4})$/.test(questionValue.trim());
                    } else {
                        return true;
                    }
                } else {
                    return false;
                }
            }
        } else {
            return true;
        }
    };

    const validateAll = (): boolean => {
        let passValidations: boolean = true;
        const formStateCopy = Object.assign([], formState);
        const newFormState = formStateCopy.map((sectionState: any) => {
            const sectionCopy = Object.assign({}, sectionState);
            sectionCopy.questions = sectionState.questions.map((questionState: any) => {
                const validateResult = validate(sectionState, questionState, questionState?.value);
                if (!validateResult) {
                    passValidations = false;
                }
                return { ...questionState, error: !validateResult };
            });
            return sectionCopy;
        });
        setFormState(newFormState);
        return passValidations;
    };

    const hasError = (groupName: string, question: any) => {
        return formState
            .find((sectionState: any) => {
                return sectionState.group.name === groupName;
            })
            .questions.find((questionState: any) => {
                return questionState.type === question.type && questionState.name === question.name;
            })?.error;
    };

    const updateValue = (groupName: string, question: any, value: any) => {
        const formStateCopy = Object.assign([], formState);
        const newFormState = formStateCopy.map((section: any) => {
            if (section.group.name === groupName) {
                const sectionCopy = Object.assign({}, section);
                sectionCopy.questions = section.questions.map((questionState: any) => {
                    if (questionState.type === question.type && questionState.name === question.name) {
                        const validateResult = validate(section, question, value);
                        return { ...questionState, value: value, error: !validateResult };
                    } else {
                        return questionState;
                    }
                });
                return sectionCopy;
            } else {
                return section;
            }
        });
        setFormHasError(false);
        setFormState(newFormState);
    };

    const canShowQuestion = (section: any, question: any) => {
        const groupName = section.group.name;
        const lookupName = question.lookupName;
        const showForValue = question.showForValue;
        const showWhenEmpty = question.showWhenEmpty;
        const lookupQuestion = formState
            .find((section: any) => {
                return section.group.name === groupName;
            })
            ?.questions?.find((question: any) => {
                return question.name === lookupName;
            });
        const lookupQuestionType = lookupQuestion?.type;
        if (lookupQuestionType === "checkbox") {
            const currentValues: string[] = lookupQuestion?.value;
            const searchResult = currentValues?.find((value: string) => {
                return value === showForValue;
            });
            if (showWhenEmpty) {
                return currentValues?.length === 0;
            } else {
                return searchResult === showForValue;
            }
        } else {
            const currentValue: string = lookupQuestion?.value;
            if (currentValue === showForValue) return true;
            else return false;
        }
    };

    const getValue = (groupName: string, question: any) => {
        const value = formState
            .find((sectionState: any) => {
                return sectionState.group.name === groupName;
            })
            .questions.find((questionState: any) => {
                return questionState.type === question.type && questionState.name === question.name;
            })?.value;
        return value;
    };

    const getValueByQuestionName = (groupName: string, questionName: string) => {
        const value = formState
            .find((sectionState: any) => {
                return sectionState.group.name === groupName;
            })
            .questions.find((questionState: any) => {
                return questionState.name === questionName;
            })?.value;
        return value;
    };

    type ObjectType = {
        [key: string]: any;
    };

    const handleSubmit = async (formState: any) => {
        setIsLoading(true);
        const bodyMap: ObjectType = {};
        formState.map((section: any) => {
            return section.questions
                .filter((questionState: any) => {
                    return !(questionState.hidden && !canShowQuestion(section, questionState));
                })
                .forEach((questionState: any) => {
                    bodyMap[questionState.name] =
                        questionState.type === "date"
                            ? `${dateFormatters["MMMM DD, YYYY"](questionState.value)}`
                            : questionState.type === "checkbox"
                            ? questionState.value?.toString()
                            : questionState.dataType === "phone"
                            ? questionState.value?.replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3")
                            : questionState.value;
                });
        });
        const requestorFormRequest = {
            firstName: getValueByQuestionName("User Information", "User's First Name"),
            lastName: getValueByQuestionName("User Information", "User's Last Name"),
            middleName: getValueByQuestionName("User Information", "User's Middle Initial"),
            orgName: getValueByQuestionName("Practice Information", "Practices"),
            bodyMap: bodyMap,
        };
        const endpointURL = `/admin/v1/registration/user-request-form`;
        await call("POST", endpointURL, requestorFormRequest)
            .then(() => {
                setFormSubmitted(true);
            })
            .catch((error: any) => {
                enqueueError("", "Error sending request for the new user build form.", "");
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    const getProvidersList = useCallback(async () => {
        setIsLoading(true);
        const endpointURL = `/admin/v1/registration/user-request-form/practice-names`;
        await call("POST", endpointURL, {})
            .then((providers: string[]) => {
                setProviders(providers);
            })
            .catch((error: any) => {
                enqueueError("", "Error sending request for the new user build form.", "");
            })
            .finally(() => {
                setIsLoading(false);
            });
    }, []);

    const handleScroll = () => {
        const availableHeight = document.body.scrollHeight - window.innerHeight;
        const scrollPercent = ((document.body.scrollTop || document.documentElement.scrollTop) / availableHeight) * 100;
        const scrollButton = document.getElementById("scrollToTop");
        if (scrollButton) {
            if (scrollPercent >= 20) {
                scrollButton.style.display = "block";
            } else {
                scrollButton.style.display = "none";
            }
        }
    };

    const gotoTop = (): void => {
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
    };

    window.onscroll = () => {
        handleScroll();
    };

    useEffect(() => {
        getProvidersList();
        handleScroll();
    }, []);

    return (
        <>
            <PEPHeader />
            <div id="header_row" className={classes.pep_newuser_header_row}>
                <div id="header_container" className={classes.pep_newuser_header_container}>
                    <div id="header_left" className={classes.pep_newuser_header_left}>
                        <div id="pep_newuser_top" className={classes.pep_newuser_top}>
                            <div id="pep_newuser_banner" className={classes.pep_newuser_banner}>
                                <Typography id="pep_newuser_banner_top" className={classes.pep_newuser_banner_top}>
                                    {`New User Build Form`}
                                </Typography>
                            </div>
                        </div>
                        <div id="header_left_content" className={classes.pep_newuser_header_left_content}>
                            {!formSubmitted ? (
                                <Paper style={{ paddingBottom: "30px", width: "100%", boxShadow: "none" }}>
                                    {false && (
                                        <>
                                            <p>
                                                <a
                                                    href="https://epiclink.brownandtoland.com/EpicCareLink_PRD/common/account_request_main.asp"
                                                    rel="noopener, noreferrer"
                                                    target="_blank"
                                                    style={{ color: "#0F7BBD", fontWeight: "500" }}
                                                >
                                                    Request access to EpicLink
                                                </a>{" "}
                                                for users from the practices not using BTP instance of Epic.
                                            </p>
                                            <p>
                                                Request EpicLink access by visiting the{" "}
                                                <a
                                                    href="https://epiclink.brownandtoland.com/EpicCareLink_PRD/common/account_request_main.asp"
                                                    rel="noopener, noreferrer"
                                                    target="_blank"
                                                    style={{ color: "#0F7BBD", fontWeight: "500", paddingLeft: "2px" }}
                                                >
                                                    EpicLink - New Account Request website.
                                                </a>
                                            </p>
                                        </>
                                    )}
                                    <h2 style={{ marginTop: "30px", marginBottom: "0px" }}>
                                        Epic Hyperspace Users – Complete the form below
                                    </h2>
                                    <p>
                                        For practices currently using the full BTP instance of EPIC, please complete and
                                        submit the below form when you need to:
                                    </p>
                                    <ul>
                                        <li>Add a new Epic user to your practice</li>
                                        <li>Remove a user who is no longer with your practice</li>
                                        <li>Reactivate a previous Epic user</li>
                                    </ul>
                                    <h2 style={{ marginTop: "30px", marginBottom: "0px" }}>
                                        EpicLink Users –{" "}
                                        <a
                                            href="https://epiclink.brownandtoland.com/EpicCareLink_PRD/common/epic_login.asp"
                                            rel="noopener, noreferrer"
                                            target="_blank"
                                            style={{ color: "#0F7BBD", fontWeight: "500", paddingLeft: "2px" }}
                                        >
                                            EpicLink
                                        </a>
                                    </h2>
                                    <p>
                                        For practices that are NOT using the full BTP instance of EPIC but need access
                                        to the claims and referrals components on EpicLink, please use the following
                                        link to complete and submit the{" "}
                                        <a
                                            href="https://epiclink.brownandtoland.com/EpicCareLink_PRD/common/account_request_main.asp"
                                            rel="noopener, noreferrer"
                                            target="_blank"
                                            style={{ color: "#0F7BBD", fontWeight: "500", paddingLeft: "2px" }}
                                        >
                                            EpicLink Access Request form
                                        </a>
                                        . {" "}
                                        <b>
                                            <i>
                                                Practices using EpicLink should not submit the form below as it will
                                                significantly delay request processing.
                                            </i>
                                        </b>
                                    </p>
                                    {form_questions.map((section: any, index: number) => {
                                        const groupName = section.group.name;
                                        const questions = section.questions;
                                        return (
                                            <div key={`newuser_${groupName}_${index}`}>
                                                <div>
                                                    <div>
                                                        <h2 style={{ marginTop: "30px", marginBottom: "0px" }}>
                                                            {groupName}
                                                        </h2>
                                                    </div>
                                                </div>
                                                {questions.map((question: any, index: number) => {
                                                    const questionType = question.type;
                                                    const questionName = question.name;
                                                    const options = question.options;
                                                    const showQuestion =
                                                        !question.hidden ||
                                                        (question.hidden && canShowQuestion(section, question));
                                                    return (
                                                        showQuestion && (
                                                            <div key={`newuser_${groupName}_${questionName}_${index}`}>
                                                                <div style={{ fontSize: "14px", paddingTop: "15px" }}>
                                                                    <label>
                                                                        <span
                                                                            style={{
                                                                                fontSize: "16px",
                                                                                paddingTop: "15px",
                                                                                fontFamily: "BentonSansCond,OpenSans",
                                                                            }}
                                                                        >
                                                                            {question.name}
                                                                        </span>
                                                                        {question.isRequired && (
                                                                            <span
                                                                                style={{
                                                                                    fontSize: "16px",
                                                                                    paddingTop: "15px",
                                                                                    color: "red",
                                                                                    fontFamily:
                                                                                        "BentonSansCond,OpenSans",
                                                                                }}
                                                                            >
                                                                                *
                                                                            </span>
                                                                        )}
                                                                    </label>
                                                                </div>
                                                                {question.description && (
                                                                    <Typography
                                                                        style={{ fontSize: "11px", color: "#537393" }}
                                                                    >
                                                                        {question.description}
                                                                    </Typography>
                                                                )}
                                                                {(questionType === "text" && (
                                                                    <>
                                                                        <Input
                                                                            className={classes.pep_newuser_textfield}
                                                                            disableUnderline={true}
                                                                            onChange={(event: any) => {
                                                                                const value = event.target.value;
                                                                                updateValue(groupName, question, value);
                                                                            }}
                                                                            placeholder={question.placeholder}
                                                                        />
                                                                        {hasError(groupName, question) && (
                                                                            <ul
                                                                                style={{
                                                                                    listStyle: "none",
                                                                                    margin: "0 0 5px",
                                                                                    width: "100%",
                                                                                    paddingLeft: "5px",
                                                                                    color: "#f2545b",
                                                                                    lineHeight: "18px",
                                                                                    whiteSpace: "normal",
                                                                                    fontWeight: "normal",
                                                                                    float: "none",
                                                                                }}
                                                                            >
                                                                                <li>
                                                                                    <label>
                                                                                        {question.validateError
                                                                                            ? question.validateError
                                                                                            : `Please complete this required field.`}
                                                                                    </label>
                                                                                </li>
                                                                            </ul>
                                                                        )}
                                                                    </>
                                                                )) ||
                                                                    (questionType === "select" && (
                                                                        <>
                                                                            <Select
                                                                                key={`ioa_forms_pcp_select`}
                                                                                variant="outlined"
                                                                                displayEmpty
                                                                                value={
                                                                                    getValue(groupName, question) || ""
                                                                                }
                                                                                onChange={(
                                                                                    event: SelectChangeEvent
                                                                                ) => {
                                                                                    const value = event.target.value;
                                                                                    updateValue(
                                                                                        groupName,
                                                                                        question,
                                                                                        value
                                                                                    );
                                                                                }}
                                                                                disabled={false}
                                                                                error={false}
                                                                                fullWidth
                                                                                style={{
                                                                                    width: "100%",
                                                                                    height: "38px",
                                                                                    border: "1px solid #909098",
                                                                                    boxShadow:
                                                                                        "0px 1px 0px rgba(0, 0, 0, 0.05)",
                                                                                    borderRadius: "4px",
                                                                                    background: "#FFFFFF",
                                                                                    boxSizing: "border-box",
                                                                                }}
                                                                            >
                                                                                <MenuItem key={`select_empty`} value="">
                                                                                    {`Please Select`}
                                                                                </MenuItem>
                                                                                {options?.map(
                                                                                    (value: any, index: number) => (
                                                                                        <MenuItem
                                                                                            key={`select_index_${index}`}
                                                                                            value={value}
                                                                                        >
                                                                                            {value}
                                                                                        </MenuItem>
                                                                                    )
                                                                                )}
                                                                            </Select>
                                                                            {hasError(groupName, question) && (
                                                                                <ul
                                                                                    style={{
                                                                                        listStyle: "none",
                                                                                        margin: "5px 0 5px",
                                                                                        width: "100%",
                                                                                        paddingLeft: "5px",
                                                                                        color: "#f2545b",
                                                                                        lineHeight: "18px",
                                                                                        whiteSpace: "normal",
                                                                                        fontWeight: "normal",
                                                                                        float: "none",
                                                                                    }}
                                                                                >
                                                                                    <li>
                                                                                        <label>
                                                                                            Please select an option.
                                                                                        </label>
                                                                                    </li>
                                                                                </ul>
                                                                            )}
                                                                        </>
                                                                    )) ||
                                                                    (questionType === "multi-select" && (
                                                                        <>
                                                                            <Select
                                                                                multiple
                                                                                value={
                                                                                    getValue(groupName, question)
                                                                                        ?.split("| ")
                                                                                        .filter(
                                                                                            (item: string) =>
                                                                                                item.trim() !== ""
                                                                                        ) || []
                                                                                }
                                                                                onChange={(event) => {
                                                                                    const value = event.target.value;
                                                                                    const pipeDelimitedValue = value
                                                                                        .filter(
                                                                                            (v: string) =>
                                                                                                v.trim() !== ""
                                                                                        )
                                                                                        .join("| ");
                                                                                    updateValue(
                                                                                        groupName,
                                                                                        question,
                                                                                        pipeDelimitedValue
                                                                                    );
                                                                                }}
                                                                                renderValue={(selected) => {
                                                                                    if (
                                                                                        !selected ||
                                                                                        selected.length === 0
                                                                                    ) {
                                                                                        return (
                                                                                            <span
                                                                                                style={{
                                                                                                    color: "#000",
                                                                                                }}
                                                                                            >
                                                                                                Please select one or
                                                                                                more practices
                                                                                            </span>
                                                                                        );
                                                                                    }
                                                                                    return (
                                                                                        <Tooltip
                                                                                            title={
                                                                                                Array.isArray(
                                                                                                    selected
                                                                                                ) &&
                                                                                                selected.length > 0 ? (
                                                                                                    <div>
                                                                                                        {selected.map(
                                                                                                            (
                                                                                                                practice,
                                                                                                                index
                                                                                                            ) => (
                                                                                                                <div
                                                                                                                    key={
                                                                                                                        index
                                                                                                                    }
                                                                                                                >
                                                                                                                    {
                                                                                                                        practice
                                                                                                                    }
                                                                                                                </div>
                                                                                                            )
                                                                                                        )}
                                                                                                    </div>
                                                                                                ) : (
                                                                                                    "No practices selected"
                                                                                                )
                                                                                            }
                                                                                            arrow
                                                                                            sx={{
                                                                                                "& .MuiTooltip-tooltip":
                                                                                                    {
                                                                                                        backgroundColor:
                                                                                                            "#f5f5f5",
                                                                                                        color: "#000",
                                                                                                        fontSize:
                                                                                                            "0.875rem",
                                                                                                        maxWidth:
                                                                                                            "none",
                                                                                                        width: "400px",
                                                                                                        wordWrap:
                                                                                                            "break-word",
                                                                                                    },
                                                                                                "& .MuiTooltip-arrow": {
                                                                                                    color: "#f5f5f5",
                                                                                                },
                                                                                            }}
                                                                                        >
                                                                                            <span
                                                                                                style={{
                                                                                                    overflow: "hidden",
                                                                                                    textOverflow:
                                                                                                        "ellipsis",
                                                                                                    whiteSpace:
                                                                                                        "nowrap",
                                                                                                    display: "block",
                                                                                                }}
                                                                                            >
                                                                                                {Array.isArray(selected)
                                                                                                    ? selected.join(
                                                                                                          ", "
                                                                                                      )
                                                                                                    : ""}
                                                                                            </span>
                                                                                        </Tooltip>
                                                                                    );
                                                                                }}
                                                                                displayEmpty
                                                                                key={`multi_select`}
                                                                                variant="outlined"
                                                                                fullWidth
                                                                                style={{
                                                                                    width: "100%",
                                                                                    height: "38px",
                                                                                    border: "1px solid #909098",
                                                                                    boxShadow:
                                                                                        "0px 1px 0px rgba(0, 0, 0, 0.05)",
                                                                                    borderRadius: "4px",
                                                                                    background: "#FFFFFF",
                                                                                    boxSizing: "border-box",
                                                                                }}
                                                                            >
                                                                                {providers?.map(
                                                                                    (
                                                                                        provider: string,
                                                                                        index: number
                                                                                    ) => (
                                                                                        <MenuItem
                                                                                            key={`practice_select_${index}`}
                                                                                            value={provider}
                                                                                        >
                                                                                            <Checkbox
                                                                                                checked={
                                                                                                    (
                                                                                                        getValue(
                                                                                                            groupName,
                                                                                                            question
                                                                                                        ) || []
                                                                                                    ).indexOf(
                                                                                                        provider
                                                                                                    ) > -1
                                                                                                }
                                                                                            />
                                                                                            <ListItemText
                                                                                                primary={provider}
                                                                                            />
                                                                                        </MenuItem>
                                                                                    )
                                                                                )}
                                                                            </Select>

                                                                            {hasError(groupName, question) && (
                                                                                <ul
                                                                                    style={{
                                                                                        listStyle: "none",
                                                                                        margin: "0 0 5px",
                                                                                        width: "100%",
                                                                                        paddingLeft: "5px",
                                                                                        color: "#f2545b",
                                                                                        lineHeight: "18px",
                                                                                        whiteSpace: "normal",
                                                                                        fontWeight: "normal",
                                                                                        float: "none",
                                                                                    }}
                                                                                >
                                                                                    <li>
                                                                                        <label>
                                                                                            {question.validateError
                                                                                                ? question.validateError
                                                                                                : `Please complete this required field.`}
                                                                                        </label>
                                                                                    </li>
                                                                                </ul>
                                                                            )}
                                                                        </>
                                                                    )) ||
                                                                    (questionType === "checkbox" && (
                                                                        <>
                                                                            <ul
                                                                                className={
                                                                                    classes.pep_newuser_checkbox_ul
                                                                                }
                                                                            >
                                                                                {options.map(
                                                                                    (value: string, index: number) => {
                                                                                        return (
                                                                                            <li
                                                                                                key={`newuser_${question.name}_${index}`}
                                                                                                className={
                                                                                                    question.fixed &&
                                                                                                    question.column ===
                                                                                                        1
                                                                                                        ? classes.pep_newuser_checkbox_li_1col
                                                                                                        : classes.pep_newuser_checkbox_li
                                                                                                }
                                                                                            >
                                                                                                <Checkbox
                                                                                                    key={`newuser_${question.name}_checkbox_${index}`}
                                                                                                    color="primary"
                                                                                                    disableRipple
                                                                                                    disableFocusRipple
                                                                                                    value={value}
                                                                                                    defaultChecked={
                                                                                                        question?.default
                                                                                                    }
                                                                                                    onChange={(
                                                                                                        event
                                                                                                    ) => {
                                                                                                        const checked =
                                                                                                            event.target
                                                                                                                .checked;
                                                                                                        const currentValue: string[] =
                                                                                                            formState
                                                                                                                .find(
                                                                                                                    (
                                                                                                                        section: any
                                                                                                                    ) => {
                                                                                                                        return (
                                                                                                                            section
                                                                                                                                .group
                                                                                                                                .name ===
                                                                                                                            groupName
                                                                                                                        );
                                                                                                                    }
                                                                                                                )
                                                                                                                ?.questions?.find(
                                                                                                                    (
                                                                                                                        question: any
                                                                                                                    ) => {
                                                                                                                        return (
                                                                                                                            question.type ===
                                                                                                                                questionType &&
                                                                                                                            question.name ===
                                                                                                                                questionName
                                                                                                                        );
                                                                                                                    }
                                                                                                                )
                                                                                                                .value ||
                                                                                                            [];
                                                                                                        let newValue: string[] =
                                                                                                            Object.assign(
                                                                                                                [],
                                                                                                                currentValue
                                                                                                            );
                                                                                                        if (
                                                                                                            checked ===
                                                                                                                true &&
                                                                                                            !newValue.includes(
                                                                                                                value
                                                                                                            )
                                                                                                        ) {
                                                                                                            newValue.push(
                                                                                                                value
                                                                                                            );
                                                                                                        } else if (
                                                                                                            checked ===
                                                                                                                false &&
                                                                                                            newValue.includes(
                                                                                                                value
                                                                                                            )
                                                                                                        ) {
                                                                                                            newValue =
                                                                                                                newValue.filter(
                                                                                                                    (
                                                                                                                        item: string
                                                                                                                    ) => {
                                                                                                                        return (
                                                                                                                            item !==
                                                                                                                            value
                                                                                                                        );
                                                                                                                    }
                                                                                                                );
                                                                                                        }
                                                                                                        updateValue(
                                                                                                            groupName,
                                                                                                            question,
                                                                                                            newValue
                                                                                                        );
                                                                                                    }}
                                                                                                    id={`newuser_checkbox_${question.name}_${index}`}
                                                                                                />
                                                                                                {value}
                                                                                            </li>
                                                                                        );
                                                                                    }
                                                                                )}
                                                                            </ul>
                                                                            {hasError(groupName, question) && (
                                                                                <ul
                                                                                    style={{
                                                                                        listStyle: "none",
                                                                                        margin: "5px 0 5px",
                                                                                        width: "100%",
                                                                                        paddingLeft: "5px",
                                                                                        color: "#f2545b",
                                                                                        lineHeight: "18px",
                                                                                        whiteSpace: "normal",
                                                                                        fontWeight: "normal",
                                                                                        float: "none",
                                                                                    }}
                                                                                >
                                                                                    <li>
                                                                                        <label>
                                                                                            Please select at least one
                                                                                            option.
                                                                                        </label>
                                                                                    </li>
                                                                                </ul>
                                                                            )}
                                                                        </>
                                                                    )) ||
                                                                    (questionType === "radio" && (
                                                                        <>
                                                                            <RadioGroup>
                                                                                <ul
                                                                                    className={
                                                                                        classes.pep_newuser_checkbox_ul
                                                                                    }
                                                                                >
                                                                                    {options.map(
                                                                                        (
                                                                                            value: string,
                                                                                            index: number
                                                                                        ) => {
                                                                                            return (
                                                                                                <li
                                                                                                    key={`newuser_${question.name}_${index}`}
                                                                                                    className={
                                                                                                        classes.pep_newuser_checkbox_li
                                                                                                    }
                                                                                                >
                                                                                                    <Radio
                                                                                                        key={`newuser_${question.name}_radio_${index}`}
                                                                                                        color="primary"
                                                                                                        disableRipple
                                                                                                        disableFocusRipple
                                                                                                        value={value}
                                                                                                        defaultChecked={
                                                                                                            question?.default
                                                                                                        }
                                                                                                        onChange={() => {
                                                                                                            updateValue(
                                                                                                                groupName,
                                                                                                                question,
                                                                                                                value
                                                                                                            );
                                                                                                        }}
                                                                                                        id={`newuser_radio_${question.name}_${index}`}
                                                                                                    />
                                                                                                    {value}
                                                                                                </li>
                                                                                            );
                                                                                        }
                                                                                    )}
                                                                                </ul>
                                                                            </RadioGroup>
                                                                            {hasError(groupName, question) && (
                                                                                <ul
                                                                                    style={{
                                                                                        listStyle: "none",
                                                                                        margin: "5px 0 5px",
                                                                                        width: "100%",
                                                                                        paddingLeft: "5px",
                                                                                        color: "#f2545b",
                                                                                        lineHeight: "18px",
                                                                                        whiteSpace: "normal",
                                                                                        fontWeight: "normal",
                                                                                        float: "none",
                                                                                    }}
                                                                                >
                                                                                    <li>
                                                                                        <label>
                                                                                            Please select at least one
                                                                                            option.
                                                                                        </label>
                                                                                    </li>
                                                                                </ul>
                                                                            )}
                                                                        </>
                                                                    )) ||
                                                                    (questionType === "date" && (
                                                                        <>
                                                                            <div
                                                                                className={
                                                                                    classes.pep_newuser_calendar_wrapper
                                                                                }
                                                                            >
                                                                                <DatePicker
                                                                                    allowTime={false}
                                                                                    minDate={addMinuteToCurrentDate(5)}
                                                                                    placeholderText={`Select a date in MM/DD/YYYY format`}
                                                                                    selected={getValue(
                                                                                        groupName,
                                                                                        question
                                                                                    )}
                                                                                    onChange={(date) => {
                                                                                        updateValue(
                                                                                            groupName,
                                                                                            question,
                                                                                            date
                                                                                        );
                                                                                    }}
                                                                                />
                                                                            </div>
                                                                            {hasError(groupName, question) && (
                                                                                <ul
                                                                                    style={{
                                                                                        listStyle: "none",
                                                                                        margin: "5px 0 5px",
                                                                                        width: "100%",
                                                                                        paddingLeft: "5px",
                                                                                        color: "#f2545b",
                                                                                        lineHeight: "18px",
                                                                                        whiteSpace: "normal",
                                                                                        fontWeight: "normal",
                                                                                        float: "none",
                                                                                    }}
                                                                                >
                                                                                    <li>
                                                                                        <label>{`Please Select Value For ${question.name}`}</label>
                                                                                    </li>
                                                                                </ul>
                                                                            )}
                                                                        </>
                                                                    )) ||
                                                                    (questionType === "initial" && (
                                                                        <>
                                                                            <Input
                                                                                className={
                                                                                    classes.pep_newuser_initialfield
                                                                                }
                                                                                disableUnderline={true}
                                                                                onChange={(event: any) => {
                                                                                    const value = event.target.value;
                                                                                    const initial = value?.charAt(
                                                                                        value.length - 1
                                                                                    );
                                                                                    event.target.value = initial;
                                                                                    updateValue(
                                                                                        groupName,
                                                                                        question,
                                                                                        initial
                                                                                    );
                                                                                }}
                                                                                placeholder={question.placeholder}
                                                                            />
                                                                            {hasError(groupName, question) && (
                                                                                <ul
                                                                                    style={{
                                                                                        listStyle: "none",
                                                                                        margin: "0 0 5px",
                                                                                        width: "100%",
                                                                                        paddingLeft: "5px",
                                                                                        color: "#f2545b",
                                                                                        lineHeight: "18px",
                                                                                        whiteSpace: "normal",
                                                                                        fontWeight: "normal",
                                                                                        float: "none",
                                                                                    }}
                                                                                >
                                                                                    <li>
                                                                                        <label>
                                                                                            {question.validateError
                                                                                                ? question.validateError
                                                                                                : `Please complete this required field.`}
                                                                                        </label>
                                                                                    </li>
                                                                                </ul>
                                                                            )}
                                                                        </>
                                                                    ))}
                                                            </div>
                                                        )
                                                    );
                                                })}
                                            </div>
                                        );
                                    })}
                                    <Box style={{ paddingTop: "30px", display: "flex", flexDirection: "row" }}>
                                        <Button
                                            className={classes.pep_newuser_submitButton}
                                            variant="contained"
                                            color="primary"
                                            disabled={isLoading || formHasError}
                                            onClick={() => {
                                                if (validateAll()) {
                                                    handleSubmit(formState);
                                                } else {
                                                    setFormHasError(true);
                                                }
                                            }}
                                        >
                                            {`Submit`}
                                        </Button>
                                        {formHasError && (
                                            <Typography
                                                id="pep_newuser_form_error"
                                                style={{
                                                    fontSize: "18px",
                                                    fontWeight: "500",
                                                    paddingLeft: "25px",
                                                    fontFamily: "BentonSansCond,OpenSans",
                                                    color: "red",
                                                    alignItems: "center",
                                                    display: "flex",
                                                    flexDirection: "row",
                                                }}
                                            >
                                                {`Please fix the errors to submit the form.`}
                                            </Typography>
                                        )}
                                        {isLoading && <Spinner />}
                                    </Box>
                                </Paper>
                            ) : (
                                <div>
                                    <Typography
                                        id="pep_newuser_banner_top"
                                        style={{
                                            fontSize: "18px",
                                            fontWeight: "500",
                                            paddingTop: "25px",
                                            fontFamily: "BentonSansCond,OpenSans",
                                        }}
                                    >
                                        {`Thank you for submitting our New User Build Form.`}
                                    </Typography>
                                    <Typography
                                        id="pep_newuser_banner_top"
                                        style={{
                                            fontSize: "16px",
                                            fontWeight: "500",
                                            paddingTop: "15px",
                                            paddingBottom: "100px",
                                            fontFamily: "BentonSansCond,OpenSans",
                                        }}
                                    >
                                        {`Your request for access has been received.`}
                                    </Typography>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
            <button
                className={classes.pep_newuser_scroll_to_top_button}
                onClick={() => gotoTop()}
                id="scrollToTop"
                title="Scroll to top"
            >
                <ScrollToTopIcon className={classes.pep_newuser_scroll_to_top_icon} />
            </button>
            <PEPFooter providerConfig={form_config} />
            <PEPCopyright />
        </>
    );
};

export default PEPNewUserRequest;
