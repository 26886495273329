import Typography from '@mui/material/Typography';
import { getAppCurrentProfileId } from 'authentication/appAuth';
import Spinner from "common/components/Spinner/Spinner";
import { useUserNotification } from "common/utils/use-user-notification";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { call } from "store/api";
import { AppReduxStore } from "store/reducerTypes";
import { find } from "underscore";
import { AdditionalResources } from "views/PEPHome/components/additional-resources/additional-resources";
import { ConfigProps } from '../types';
import { getProviderConfig } from './pep-help-config';
import { getProviderStyles } from "./styles";

export const PEPHelp = ({ providerConfig: config, handleFormDownload, onSelect: handleSelection }: { providerConfig: ConfigProps, handleFormDownload: () => Promise<void>, onSelect?: (menuItem: string) => void }) => {
    const { user } = useSelector((store: AppReduxStore) => store);
    const classes = getProviderStyles(config?.provider)();
    const { content } = getProviderConfig(config?.provider, classes, handleFormDownload);
    const dispatch = useDispatch();
    const { enqueueError } = useUserNotification();
    const [isLoading, setIsLoading] = useState<boolean>(false);

    useEffect(() => {
        const getUser = async () => {
            const currentProfileId: string | undefined | null = getAppCurrentProfileId();
            const getCurrentRoleJoin = (profileRoleJoinInfos: any, currentProfileRoleJoinId: string | undefined | null) => {
                if (!profileRoleJoinInfos && !currentProfileRoleJoinId) return null
                return find(profileRoleJoinInfos, (profileRoleJoinInfo: any) => profileRoleJoinInfo.id === currentProfileRoleJoinId)
            }
            try {
                const { user, twilioToken } = await call("GET", "/admin/v1/user/current");
                const currentProfileRoleJoin = getCurrentRoleJoin(user?.profileRoleJoinInfos, currentProfileId);
                dispatch({ type: 'SET_USER', payload: { ...user, currentProfileRoleJoin } });
                dispatch({ type: 'SET_CURRENT_PROFILE_ID', payload: { currentProfileId: currentProfileRoleJoin?.profileId, currentUserType: currentProfileRoleJoin?.type } });
                dispatch({ type: 'SET_TWILIO_TOKEN', payload: { token: twilioToken } });
            } catch (error: any) {
                enqueueError("pep.get.user.error");
            } finally {
                setIsLoading(false);
            }
        }
        if (!user.username) {
            setIsLoading(true);
            getUser();
        }
    }, [dispatch, user.username, enqueueError])

    return (
        isLoading ? <Spinner /> : (
            <div id="help_row" className={classes.pep_help_row}>
                <div id="help_container" className={classes.pep_help_container}>
                    <div id="help_left" className={classes.pep_help_left}>
                        <div id="help_banner" className={classes.pep_help_banner}>
                            <Typography id="help_banner_top" className={classes.pep_help_banner_top}>
                                {`Help`}
                            </Typography>
                        </div>
                        {content}
                    </div>
                    <div id="help_right" className={classes.pep_help_right}>
                        <AdditionalResources onSelect={handleSelection} provider={config?.provider} />
                    </div>
                </div>
            </div >)
    )
}