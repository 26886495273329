import { ReactComponent as AhaPaymentSummaryReports } from "icon-library/SVG/Chart_Bar.svg";
import { ReactComponent as FeeSchedules } from "icon-library/SVG/Circles_3x3.svg";
import { ReactComponent as IOAReport } from "icon-library/SVG/Clipboard_Checklist.svg";
import { ReactComponent as Heart } from "icon-library/SVG/Heart_Bolt.svg";
import { ReactComponent as Laptop } from "icon-library/SVG/Laptop_EHR.svg";
import { ReactComponent as Mortarboard } from "icon-library/SVG/Mortarboard.svg";
import { ReactComponent as MembershipReports } from "icon-library/SVG/Person.svg";
import { ReactComponent as ProviderDirectory } from "icon-library/SVG/Stethoscope.svg";
import BNTLogo from "./assests/images/logo-bnt.png";
import FCSLogo from "./assests/images/logo-fcs.png";
import { ConfigProps } from "./types";

const { PEP_WP_URL_BNT, PEP_WP_URL_FCS } = window.env;
// const { ENV } = window.env;
// const isProduction = ENV === "production" || ENV === "prodplatform";

const bntConfig: ConfigProps = {
    provider: "bnt",
    headerLogo: {
        src: BNTLogo,
        width: "351px",
        height: "104px",
    },
    wpRoot: PEP_WP_URL_BNT, // isProduction ? "https://providerengage.wpengine.com" : "https://btppdev.wpengine.com",
    navigations: {
        header: {
            links: {
                left: [
                    {
                        id: "practice_resources",
                        name: "Practice Resources",
                        href: "",
                    },
                    {
                        id: "billing_claims_resolution",
                        name: "Billing & Claims Resources",
                        href: "",
                    },
                    {
                        id: "referrals_epicLink",
                        name: "Referrals / EpicLink",
                        href: "https://epiclink.brownandtoland.com/EpicCareLink_PRD/common/epic_login.asp",
                        target: "_blank",
                    },
                    {
                        id: "all_reports",
                        name: "Reports",
                        href: "",
                    },
                    {
                        id: "education",
                        name: "Education",
                        href: "",
                    },
                ],
                right: [
                    {
                        id: "my_account",
                        name: "My Account",
                        href: "",
                        target: "_self",
                    },
                    {
                        id: "help",
                        name: "Help",
                        href: "",
                    },
                ],
            },
        },
        footer: {
            links: [
                {
                    id: "request_access_to_epicLink",
                    name: "Request Access to EpicLink",
                    href: "https://epiclink.brownandtoland.com/EpicCareLink_PRD/common/account_request_main.asp",
                    target: "_blank",
                },
                {
                    id: "about_brown_toland_physicians",
                    name: "About Brown & Toland Physicians",
                    href: "https://www.brownandtoland.com/about-us/why-partner-with-us/",
                },
                {
                    id: "privacy_policy",
                    name: "Privacy Policy",
                    href: "https://www.brownandtoland.com/privacy-policy/",
                },
                {
                    id: "help",
                    name: "Provider Engagement Portal Help",
                    href: "",
                },
                {
                    id: "contact_us",
                    name: "Contact Us",
                    href: "https://www.brownandtoland.com/contact-us/",
                },
                {
                    id: "terms_of_use",
                    name: "Terms of Use",
                    href: "https://www.brownandtoland.com/terms-of-use/",
                },
                {
                    id: "btp_news_events",
                    name: "BTP News & Events",
                    href: "https://www.brownandtoland.com/news-and-events/",
                    target: "_blank",
                },
                {
                    id: "careers",
                    name: "Careers",
                    href: "https://altais.com/join-us/",
                    target: "_blank",
                },
                {
                    id: "non_discrimination_notice",
                    name: "Non-Discrimination Notice",
                    href: "https://www.brownandtoland.com/non-discrimination-notice/",
                },
            ],
        },
    },
    tiles: [
        {
            key: 1,
            name: "Your Latest Quality Report",
            description: `Access your 2024 Quality Performance Rates and member level detail for your HMO Medicare and Commercial patients.`,
            action: {
                id: "performance_reports",
                name: "View Reports",
                href: "",
                target: "_self",
            },
            comment: "For Authenticated Users",
            permissions: ["QUALITY_GAPS_REPORT"],
            icon: Heart,
            enabled: true,
        },

        {
            key: 2,
            name: "AHA Payment Summary Report",
            description: `You can find details about your Annual Health Assessment (AHA) program in the monthly Payment Summary Report. This report gives you a clear overview and detailed information about your AHA patient visits and compensation.`,
            action: {
                id: "aha_payment_summary_reports",
                name: "Get Report",
                href: "",
                target: "_self",
            },
            comment: "For Authenticated Users",
            permissions: ["AHA_PAYMENT_SUMMARY"],
            icon: AhaPaymentSummaryReports,
            enabled: true,
        },

        {
            key: 3,
            name: "Membership Assignment Report",
            description: `Get your Managed Care Membership Report. Updated monthly to ensure you have the latest information from our health plan partners regarding your new managed care patients.`,
            action: {
                id: "membership_reports",
                name: "Get Report",
                href: "",
                target: "_self",
            },
            comment: "For Authenticated Users",
            permissions: ["MEMBERSHIP_REPORT"],
            icon: MembershipReports,
            enabled: true,
        },
        {
            key: 4,
            name: "Annual Health Assessment Forms",
            description: `To meet the AHA program requirements and receive the incentives, use provided AHA forms to conduct Medicare Advantage patient exams and assess all care gaps and screenings identified on the assessment.`,
            action: {
                id: "ioa_forms",
                name: "Get AHA Forms",
                href: "",
                target: "_self",
            },
            comment: "For Authenticated Users",
            permissions: ["IOA_REPORT"],
            icon: IOAReport,
            enabled: true,
        },

        {
            key: 5,
            name: "Fee Schedules",
            description: `View your complete listings of fee maximums BTP uses for reimbursement.`,
            action: {
                id: "fee_schedules",
                name: "View Schedules",
                href: "",
                target: "_self",
            },
            comment: "For Unauthenticated Users",
            permissions: ["BILLING_SCHEDULE"],
            icon: FeeSchedules,
            enabled: true,
        },
        {
            key: 6,
            name: "EpicLink Claims & Referrals",
            description: `Manage authorizations, referrals and claims through EpicLink.`,
            action: {
                id: "access_epicLink",
                name: "Access EpicLink",
                href: "https://epiclink.brownandtoland.com/EpicCareLink_PRD/common/epic_login.asp",
                target: "_blank",
            },
            comment: "For Unauthenticated and Authenticated Users",
            icon: Laptop,
            enabled: true,
        },
        {
            key: 7,
            name: "Education",
            description: `Find learning resources to stay on top of ever-changing regulations, coding, quality measures and more.`,
            action: {
                id: "access_learning_platform",
                name: "Access Learning",
                href: "https://browntoland.csod.com/ui/lms-learner-home/home?tab_page_id=-200300006&tab_id=-2",
                target: "_blank",
            },
            comment: "For Authenticated Users",
            icon: Mortarboard,
            enabled: true,
        },
        {
            key: 8,
            name: "Provider Search",
            description: `Find BTP heath care providers by location and specialty.`,
            action: {
                id: "find_a_doctor",
                name: "Find a Doctor",
                href: "https://www.brownandtoland.com/find-a-doctor/",
                target: "_blank",
            },
            comment: "For Unauthenticated Users",
            icon: ProviderDirectory,
            enabled: true,
        },
    ],
};

const bscConfig: ConfigProps = {
    provider: "bsc",
    navigations: {
        header: {
            links: {
                left: [],
                right: [
                    {
                        id: "my_account",
                        name: "My Account",
                        href: "",
                        target: "_self",
                    },
                ],
            },
        },
        footer: {
            links: [],
        },
    },
    tiles: [],
};

const fcsConfig: ConfigProps = {
    provider: "fcs",
    headerLogo: {
        src: FCSLogo,
        width: "460px",
        height: "120px",
    },
    wpRoot: PEP_WP_URL_FCS,
    navigations: {
        header: {
            links: {
                left: [
                    {
                        id: "practice_resources",
                        name: "Practice Resources",
                        href: "",
                    },
                    {
                        id: "billing_claims_resolution",
                        name: "Billing & Claims Resources",
                        href: "",
                    },
                    {
                        id: "referrals_epicLink",
                        name: "Referrals / EpicLink",
                        href: "https://epiclink.brownandtoland.com/EpicCareLink_PRD/common/epic_login.asp",
                        target: "_blank",
                    },
                    {
                        id: "all_reports",
                        name: "Reports",
                        href: "",
                    },
                    {
                        id: "education",
                        name: "Education",
                        href: "",
                    },
                ],
                right: [
                    {
                        id: "my_account",
                        name: "My Account",
                        href: "",
                        target: "_self",
                    },
                    {
                        id: "help",
                        name: "Help",
                        href: "",
                    },
                ],
            },
        },
        footer: {
            links: [
                {
                    id: "request_access_to_epicLink",
                    name: "Request Access to EpicLink",
                    href: "https://epiclink.brownandtoland.com/EpicCareLink_PRD/common/account_request_main.asp",
                    target: "_blank",
                },
                {
                    id: "help",
                    name: "Provider Engagement Portal Help",
                    href: "",
                },
                {
                    id: "contact_us",
                    name: "Contact Us",
                    href: "https://altais.com/contact-us/",
                    target: "_blank",
                },
                {
                    id: "terms_of_use",
                    name: "Terms of Use",
                    href: "https://altais.com/terms-of-use/",
                    target: "_blank",
                },
                {
                    id: "careers",
                    name: "Careers",
                    href: "https://altais.com/join-us/",
                    target: "_blank",
                },
            ],
            hideFB: true,
            hideLI: true,
        },
    },
    tiles: [
        {
            key: 1,
            name: "Annual Health Assessment Forms",
            description: `To meet the AHA program requirements and receive the incentives, use provided AHA forms to conduct Medicare Advantage patient exams and assess all care gaps and screenings identified on the assessment.`,
            action: {
                id: "ioa_forms",
                name: "Get AHA Forms",
                href: "",
                target: "_self",
            },
            comment: "For Authenticated Users",
            permissions: ["IOA_REPORT"],
            icon: IOAReport,
            enabled: true,
        },
        {
            key: 2,
            name: "AHA Payment Summary Report",
            description: `You can find details about your Annual Health Assessment (AHA) program in the monthly Payment Summary Report. This report gives you a clear overview and detailed information about your AHA patient visits and compensation.`,
            action: {
                id: "aha_payment_summary_reports",
                name: "Get Report",
                href: "",
                target: "_self",
            },
            comment: "For Authenticated Users",
            permissions: ["AHA_PAYMENT_SUMMARY"],
            icon: AhaPaymentSummaryReports,
            enabled: true,
        },

        {
            key: 3,
            name: "Membership Assignment Report",
            description: `Get your Managed Care Membership Report. Updated monthly to ensure you have the latest information from our health plan partners regarding your new managed care patients.`,
            action: {
                id: "membership_reports",
                name: "Get Report",
                href: "",
                target: "_self",
            },
            comment: "For Authenticated Users",
            permissions: ["MEMBERSHIP_REPORT"],
            icon: MembershipReports,
            enabled: true,
        },

        {
            key: 4,
            name: "Fee Schedules",
            description: `View your complete listings of fee maximums Altais uses for reimbursement.`,
            action: {
                id: "fee_schedules",
                name: "View Schedules",
                href: "",
                target: "_self",
            },
            comment: "For Unauthenticated Users",
            permissions: ["BILLING_SCHEDULE"],
            icon: FeeSchedules,
            enabled: true,
        },
        {
            key: 5,
            name: "EpicLink Claims & Referrals",
            description: `Manage authorizations, referrals and claims through EpicLink.`,
            action: {
                id: "access_epicLink",
                name: "Access EpicLink",
                href: "https://epiclink.brownandtoland.com/EpicCareLink_PRD/common/epic_login.asp",
                target: "_blank",
            },
            comment: "For Unauthenticated and Authenticated Users",
            icon: Laptop,
            enabled: true,
        },
        {
            key: 6,
            name: "Education",
            description: `Find learning resources to stay on top of ever-changing regulations, coding, quality measures and more.`,
            action: {
                id: "access_learning_platform",
                name: "Access Learning",
                href: "https://browntoland.csod.com/ui/lms-learner-home/home?tab_page_id=-200300006&tab_id=-2",
                target: "_blank",
            },
            comment: "For Authenticated Users",
            icon: Mortarboard,
            enabled: true,
        },
        {
            key: 7,
            name: "Provider Search",
            description: `Coming Soon`,
            action: {
                id: "find_a_doctor",
                name: "Find a Doctor",
                href: "https://www.brownandtoland.com/find-a-doctor/",
                target: "_blank",
                enabled: false,
            },
            comment: "For Unauthenticated Users",
            icon: ProviderDirectory,
            enabled: true,
        },
        {
            key: 8,
            name: "Clinical Performance & Quality Reports",
            description: `Access your 2024 Quality Performance Rates and member level detail for your HMO Medicare and Commercial patients.`,
            action: {
                id: "performance_reports",
                name: "View Reports",
                href: "",
                target: "_self",
            },
            comment: "For Authenticated Users",
            permissions: ["QUALITY_GAPS_REPORT"],
            icon: Heart,
            enabled: true,
        },
    ],
};

export const getConfig = (providerName: string) => {
    const allConfigs: ConfigProps[] = [];
    allConfigs.push(bntConfig);
    allConfigs.push(bscConfig);
    allConfigs.push(fcsConfig);

    const config: ConfigProps[] = allConfigs.filter((config: ConfigProps) => {
        return config.provider === providerName;
    });
    return config[0];
};
