import Typography from '@mui/material/Typography';
import { getAppCurrentProfileId } from 'authentication/appAuth';
import Spinner from "common/components/Spinner/Spinner";
import { useUserNotification } from "common/utils/use-user-notification";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { call } from "store/api";
import { AppReduxStore } from "store/reducerTypes";
import { find } from "underscore";
import PageImage from '../assests/images/pep-education.jpg';
import { ClickableCard } from '../components/clickable-card/clickable-card';
import { ConfigProps } from '../types';
import { getProviderConfig } from './pep-education-config';
import { getProviderStyles } from "./styles";

export const PEPEducation = ({ providerConfig: config, onSelect: handleSelection }: { providerConfig: ConfigProps, onSelect?: (menuItem: string) => void }) => {
    const { user } = useSelector((store: AppReduxStore) => store);
    const classes = getProviderStyles(config?.provider)();
    const content = getProviderConfig(config?.provider);
    const dispatch = useDispatch();
    const { enqueueError } = useUserNotification();
    const [isLoading, setIsLoading] = useState<boolean>(false);

    useEffect(() => {
        const getUser = async () => {
            const currentProfileId: string | undefined | null = getAppCurrentProfileId();
            const getCurrentRoleJoin = (profileRoleJoinInfos: any, currentProfileRoleJoinId: string | undefined | null) => {
                if (!profileRoleJoinInfos && !currentProfileRoleJoinId) return null
                return find(profileRoleJoinInfos, (profileRoleJoinInfo: any) => profileRoleJoinInfo.id === currentProfileRoleJoinId)
            }
            try {
                const { user, twilioToken } = await call("GET", "/admin/v1/user/current");
                const currentProfileRoleJoin = getCurrentRoleJoin(user?.profileRoleJoinInfos, currentProfileId);
                dispatch({ type: 'SET_USER', payload: { ...user, currentProfileRoleJoin } });
                dispatch({ type: 'SET_CURRENT_PROFILE_ID', payload: { currentProfileId: currentProfileRoleJoin?.profileId, currentUserType: currentProfileRoleJoin?.type } });
                dispatch({ type: 'SET_TWILIO_TOKEN', payload: { token: twilioToken } });
            } catch (error: any) {
                enqueueError("pep.get.user.error");
            } finally {
                setIsLoading(false);
            }
        }
        if (!user.username) {
            setIsLoading(true);
            getUser();
        }
    }, [dispatch, user.username, enqueueError])

    const config_left = content["config_left"];
    const config_right = content["config_right"];

    return (
        isLoading ? <Spinner /> : (
            <div id="education_row" className={classes.pep_education_row}>
                <div id="education_container" className={classes.pep_education_container}>
                    <div id="education_top" className={classes.pep_education_top}>
                        <div id="education_banner" className={classes.pep_education_banner}>
                            <Typography id="education_banner_top" className={classes.pep_education_banner_top}>
                                {`Education`}
                            </Typography>
                        </div>
                        <div style={{ width: '100%', height: '360px', marginBottom: '20px' }}>
                            <img src={PageImage} alt='Billing & Claims Resources Logo' />
                        </div>
                        <Typography id="education_description" className={classes.pep_education_description}>
                            {`We help you stay informed and keep up with changing regulations, coding and quality measures critical for compensation.  Here, you’ll find resources to stay on top of the ever-changing landscape – from manuals, guides, compliance and population health.`}
                        </Typography>
                    </div>
                    <div id="education_middle" style={{ display: 'flex', marginTop: '20px' }}>
                        <div id="education_left" className={classes.pep_education_left}>
                            {config_left.map((card, index: number) => {
                                return <ClickableCard provider={config?.provider} key={`clickable_card_left_${index}`} config={card} onSelect={handleSelection} />
                            })}
                        </div>
                        <div id="education_right" className={classes.pep_education_right}>
                            {config_right.map((card, index: number) => {
                                return <ClickableCard provider={config?.provider} key={`clickable_card_right_${index}`} config={card} onSelect={handleSelection} />
                            })}
                        </div>
                    </div>


                </div>
            </div>)
    )
}