import { TableCell, TableFooter, TableRow } from "@mui/material";
import ListTablePagination from "../table-pagination";
import { PaginationProps } from "../table-pagination/types";

const ListTableFooter = ({ result, onChange, pagination }: { result: any, onChange: (pagination: PaginationProps) => void, pagination: PaginationProps }) => {
    const searchResults: any[] = result?.content || [];

    return (
        <TableFooter>
            {(searchResults && searchResults.length === 0) ?
                <TableRow>
                    <TableCell colSpan={10} align="center">{`No Records`}</TableCell>
                </TableRow> :
                <TableRow>
                    <ListTablePagination result={result} onChange={onChange} pagination={pagination} />
                </TableRow>
            }
        </TableFooter>
    );
}

export default ListTableFooter