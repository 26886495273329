import { Paper, Grid, Table, TableBody, TableCell, TableContainer, TableHead,  TableRow, TableSortLabel } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useStyles } from "./styles";
import { isAfter } from 'date-fns'; 
import ListTableFooter from 'common/components/Table/table-footer';
import { PaginationProps } from 'common/components/Table/table-pagination/types';
import dayjs from 'dayjs';

type  VisitRecordType = {
  id:string,
  bscCcpMemberId:string,
  hcpsk: string,
  mrn: string,
  memberId: string,
  apptDateAsString: string,
  apptDate: string,
  apptStatus: string,
  apptType:string,
  visitType:string,
  pcpProviderName:string,
  visitProviderName:string,
  visitProviderType:string,
  riskLevel:string,
  appCancelDateAsString:string,
  appCancelDate:string
}

type PaginatedSort = {
  empty: boolean,
  sorted: boolean,
  unsorted: boolean,
}

type PaginatedPagable = {
  offset: number,
  pageNumber: number,
  pageSize: number,
  paged: boolean,
  sort: PaginatedSort,
  unpaged: boolean
}

export type PaginatedVisitRecordTProps = {
  content: VisitRecordType[],
  empty: boolean,
  first: boolean,
  last: boolean,
  number: number,
  numberOfElements: number,
  pageable: PaginatedPagable,
  size: number,
  sort: PaginatedSort,
  totalElements: number,
  totalPages: number,
}

type Order = 'asc' | 'desc';

type ApptListProps = {
  onSearch: (pageNumber: number, pageSize: number, sortedBy: string, sortAsc: boolean) => Promise<void>,
  result: PaginatedVisitRecordTProps
}


  export const BSCVisits= (props: ApptListProps) => {
  const classes = useStyles();
  const [orderBy, setOrderBy] = useState<keyof VisitRecordType>('apptDate');
  const [order, setOrder] = useState<Order>('asc');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const searchResults: any[] = props.result?.content || [];



  useEffect(() => {
    const sortAsc = (order === 'desc') ? true : false;
    props.onSearch(page, rowsPerPage, orderBy, sortAsc).then(() => {
     });
}, [page, rowsPerPage, orderBy, order])


  const handleRequestSort = (event: React.MouseEvent<unknown>, property: keyof VisitRecordType) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
    setPage(0);
  };

  const createSortHandler = (property: keyof VisitRecordType) => (event: React.MouseEvent<unknown>) => {
    handleRequestSort(event, property);
  };

 
  const pagination: PaginationProps = { page: page, rowsPerPage: rowsPerPage }
  const setPagination = (pagination: PaginationProps) => {
    setPage(pagination.page);
    setRowsPerPage(pagination.rowsPerPage);
  }
  
  return (<>
   <div id="search_container" className={classes.search_container} style={{width:'1380px'}}>
    <div><div><h5 style={{ fontSize: '1.25rem', fontWeight: '600', fontFamily: 'OpenSans', marginTop: '0px', marginBottom: '0px' }}>{`Encounters / Future Appointments`}</h5></div></div>
   
              <Paper className={classes.paper} > 
                <TableContainer className={classes.stickyHeader} > 
                  <Table id="tableVisits"
                    stickyHeader
                    className={classes.table}
                    aria-labelledby="tableTitle"
                    size={'medium'}
                    aria-label="enhanced table"
                   > 
                    <TableHead>
                      <TableRow>
                        <TableCell style={{ background: '#EDEDEF' }} width={'10%'}  key={"apptDateAsString"} align={'left'} padding="normal">
                          <TableSortLabel active={orderBy === "apptDate"} direction={orderBy === "apptDate" ? order : 'asc'} onClick={createSortHandler("apptDate")}>
                          <strong style={{ marginLeft: "18px" }}>{`Appt DateTime`}</strong>
                            {orderBy === "apptDate" ? (<span className={classes.visuallyHidden}>{order === 'desc' ? 'sorted descending' : 'sorted ascending'}</span>) : null}
                          </TableSortLabel>
                        </TableCell>
                        <TableCell style={{ background: '#EDEDEF' }} width={'5%'} key={"apptStatus"} align={'left'} padding="normal">
                          <TableSortLabel active={orderBy === "apptStatus"} direction={orderBy === "apptStatus" ? order : 'asc'} onClick={createSortHandler("apptStatus")}>
                          <strong>{'Appt Status'}</strong>
                            {orderBy === "apptStatus" ? (<span className={classes.visuallyHidden}>{order === 'desc' ? 'sorted descending' : 'sorted ascending'}</span>) : null}
                          </TableSortLabel>
                        </TableCell>
                        <TableCell style={{ background: '#EDEDEF' }} width={'10%'} key={"apptType"} align={'left'} padding="normal">
                          <TableSortLabel active={orderBy === "apptType"} direction={orderBy === "apptType" ? order : 'asc'} onClick={createSortHandler("apptType")}>
                          <strong>{'Appt Type'}</strong>
                            {orderBy === "apptType" ? (<span className={classes.visuallyHidden}>{order === 'desc' ? 'sorted descending' : 'sorted ascending'}</span>) : null}
                          </TableSortLabel>
                        </TableCell>
                        <TableCell style={{ background: '#EDEDEF' }} width={'10%'} key={"riskLevel"} align={'left'} padding="normal">
                          <TableSortLabel active={orderBy === "riskLevel"} direction={orderBy === "riskLevel" ? order : 'asc'} onClick={createSortHandler("riskLevel")}>
                          <strong>{`Member Risk Level`}</strong>
                            {orderBy === "riskLevel" ? (<span className={classes.visuallyHidden}>{order === 'desc' ? 'sorted descending' : 'sorted ascending'}</span>) : null}
                          </TableSortLabel>
                        </TableCell>
                        <TableCell style={{ background: '#EDEDEF' }} width={'10%'} key={"visitType"} align={'left'} padding="normal">
                          <TableSortLabel active={orderBy === "visitType"} direction={orderBy === "visitType" ? order : 'asc'} onClick={createSortHandler("visitType")}>
                          <strong>{`Visit Modality`}</strong>
                            {orderBy === "visitType" ? (<span className={classes.visuallyHidden}>{order === 'desc' ? 'sorted descending' : 'sorted ascending'}</span>) : null}
                          </TableSortLabel>
                        </TableCell>
                      
                        <TableCell style={{ background: '#EDEDEF' }} width={'10%'} key={"pcpProviderName"} align={'left'} padding="normal">
                          <TableSortLabel active={orderBy === "pcpProviderName"} direction={orderBy === "pcpProviderName" ? order : 'asc'} onClick={createSortHandler("pcpProviderName")}>
                          <strong>{'PCP'}</strong>
                            {orderBy === "pcpProviderName" ? (<span className={classes.visuallyHidden}>{order === 'desc' ? 'sorted descending' : 'sorted ascending'}</span>) : null}
                          </TableSortLabel>
                        </TableCell>
                        <TableCell style={{ background: '#EDEDEF' }} width={'10%'} key={"visitProviderName"} align={'left'} padding="normal">
                          <TableSortLabel active={orderBy === "visitProviderName"} direction={orderBy === "visitProviderName" ? order : 'asc'} onClick={createSortHandler("visitProviderName")}>
                          <strong>{'Visit Provider Name'}</strong>
                            {orderBy === "visitProviderName" ? (<span className={classes.visuallyHidden}>{order === 'desc' ? 'sorted descending' : 'sorted ascending'}</span>) : null}
                          </TableSortLabel>
                        </TableCell>
                        <TableCell style={{ background: '#EDEDEF' }} width={'5%'} key={"visitProviderType"} align={'left'} padding="normal">
                         
                          <TableSortLabel active={orderBy === "visitProviderType"} direction={orderBy === "visitProviderType" ? order : 'asc'} onClick={createSortHandler("visitProviderType")}>
                          <strong>{'Provider Type'}</strong>
                            {orderBy === "visitProviderType" ? (<span className={classes.visuallyHidden}>{order === 'desc' ? 'sorted descending' : 'sorted ascending'}</span>) : null}
                          </TableSortLabel>
                        </TableCell>
                         <TableCell style={{ background: '#EDEDEF' }} width={'10%'} key={"appCancelDateAsString"} align={'left'} padding="normal">
                          <TableSortLabel active={orderBy === "appCancelDate"} direction={orderBy === "appCancelDate" ? order : 'asc'} onClick={createSortHandler("appCancelDate")}>
                          <strong>{'Appt Cancel DateTime'}</strong>
                            {orderBy === "appCancelDate" ? (<span className={classes.visuallyHidden}>{order === 'desc' ? 'sorted descending' : 'sorted ascending'}</span>) : null}
                          </TableSortLabel>
                        </TableCell> 
                      </TableRow>
                     
                    </TableHead>
                    <TableBody>
                      {searchResults.map((row: any, index: number) => {
                        const labelId = `enhanced-table-checkbox-${index}`;
                        const appointmentDate = new Date(row.apptDateAsString);
                        const isFutureDate = isAfter(appointmentDate, new Date()); // Check if date is in the future //new Date("10/01/2024")
                        const rowStyle = {
                          backgroundColor: isFutureDate ? '#f5fcfd' : 'inherit', 
                          fontStyle: isFutureDate ? 'italic' : 'normal', 
                        };
                        return (<React.Fragment key={`search_result_table_body_${index}`}>
                          <TableRow hover role="checkbox" tabIndex={-1} key={`search_result_table_row_${index}`} >
                            <TableCell id={labelId} scope="row" align="left" style={{ ...rowStyle}}  width={'10%'} >
                              <Grid style={{ whiteSpace: 'normal', wordWrap: 'break-word', marginLeft: "18px"}}>
                              {dayjs(row.apptDateAsString).format('MM/DD/YYYY hh:mm A')}
                              </Grid>
                            </TableCell>
                            <TableCell className={classes.tableFonts} style={rowStyle} align="left" width={'5%'}>{row.apptStatus}</TableCell>
                            <TableCell className={classes.tableFonts} align="left" width={'10%'}>{row.apptType}</TableCell>
                            <TableCell className={classes.tableFonts} align="left" width={'10%'}>{row.riskLevel}</TableCell>
                            <TableCell className={classes.tableFonts} align="left" width={'10%'}>{row.visitType}</TableCell>
                            <TableCell className={classes.tableFonts} align="left" width={'10%'}>{row.pcpProviderName}</TableCell>
                            <TableCell className={classes.tableFonts} align="left" width={'10%'}>{row.visitProviderName}</TableCell>
                            <TableCell className={classes.tableFonts} align="left" width={'5%'}>{row.visitProviderType}</TableCell>
                            <TableCell className={classes.tableFonts} align="left" width={'10%'}>{row.appCancelDateAsString ?dayjs(row.appCancelDateAsString).format('MM/DD/YYYY hh:mm A') : ''}</TableCell> 
                            
                          </TableRow>
                        </React.Fragment>);
                      })}
                    </TableBody>
                    <ListTableFooter result={props.result} pagination={pagination} onChange={setPagination} />
                  </Table>
                </TableContainer>
              </Paper> 
              </div>
  </>);
}
