import { getProviderStyles } from "./styles";
import { ConfigProps } from "./types";

export const PEPCopyright = ({ providerConfig: config }: { providerConfig?: ConfigProps }) => {
    const classes = getProviderStyles(config?.provider)();
    const copyrightText = (config?.provider === 'bnt') ? `Copyright © Brown and Toland 2024` : `Copyright © Altais Health Solutions 2024`;

    return (
        <div id="copyright_row" className={classes.copyright_row}>
            <div id="copyright_container" className={classes.copyright_container}>
                {copyrightText}
            </div>
        </div>
    )
}
