import { getProviderStyles } from "./styles";
import { ConfigProps } from "./types";

export const PEPWPContent = ({ providerConfig: config, src, height = 1150 }: { providerConfig: ConfigProps, src: string, height?: number }) => {
    const classes = getProviderStyles(config?.provider)();

    return (<>
        <iframe id="pep_wp_content" title="PEP Content Page" className={classes.wp_pep_content} height={height} src={src} />
    </>)
}
