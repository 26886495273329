import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles(() => ({
    pep_fee_schedules_row: {
        width: '100%', 
        minWidth: '1440px', 
        display: 'flex', 
        flexDirection: 'row', 
        justifyContent: 'center', 
        alignItems: 'center', 
        fontStyle: 'normal', 
        fontWeight: '400', 
        fontSize: '13px', 
        lineHeight: '140%', 
        color: '#000000',
        userSelect: 'none'
    },
    pep_fee_schedules_container: {
        width: '100%', 
        maxWidth: '1440px', 
        paddingLeft: '15px', 
        paddingRight: '15px', 
        display: 'flex'
    },
    pep_fee_schedules_left: {
        marginRight: '10px', 
        width: '1065px',  
        paddingBottom: '30px'
    },
    pep_fee_schedules_banner: {
        width: '1065px',
        marginTop: '20px',
        marginBottom: '20px',
        flexDirection: 'row',
        alignItems: 'center',
        padding: '16px 32px',
        gap: '4px',
        height: '111px',
        background: 'linear-gradient(90deg, #F6F6F6 66.29%, rgba(246, 246, 246, 0) 100%)',
        borderRadius: '4px'
    },
    o_pep_fee_schedules_banner: {
        marginTop: '20px', 
        marginBottom: '20px', 
        display: 'flex', 
        flexDirection: 'column', 
        alignItems: 'flex-start', 
        padding: '16px 32px 24px', 
        gap: '4px', 
        width: '1065px', 
        height: '111px', 
        background: 'linear-gradient(90deg, #F6F6F6 66.29%, rgba(246, 246, 246, 0) 100%)', 
        borderRadius: '4px'
    },
    pep_fee_schedules_banner_top: {
        fontFamily: 'BentonSansCond',
        fontStyle: 'normal',
        fontWeight: '500',
        fontSize: '32px',
        lineHeight: '140%',
        color: '#083d78'
    },
    pep_fee_schedules_banner_bottom: {
        fontStyle: 'normal', 
        fontWeight: '400', 
        fontSize: '16px', 
        lineHeight: '140%', 
        color: '#083d78'
    },
    pep_fee_schedules_description: {
        marginBottom: '30px', 
        fontStyle: 'normal', 
        fontWeight: '400', 
        fontSize: '16px', 
        lineHeight: '140%', 
        color: '#404040'
    },
    pep_fee_schedules_tableContainer: {
        display: 'block', 
        marginLeft: 'auto', 
        marginRight: 'auto', 
        marginBottom: '20px',
        width: '900px'
    },
    pep_fee_schedules_tableMain: {
        borderCollapse: 'collapse'
    },
    pep_fee_schedules_tableColGroup1: {
        width: '350px'
    },
    pep_fee_schedules_tableColGroup2: {
        width: '250px'
    },
    pep_fee_schedules_tableColGroup3: {
        width: '150px'
    },
    pep_fee_schedules_tableColGroup4: {
        width: '150px'
    },
    pep_fee_schedules_tableHeaderRow: {
        height: '48px', 
        background: '#EDEDEF', 
        boxShadow: '0 0 0 1px #E7E7ED', 
        borderRadius: '8px 8px 0px 0px'
    },
    pep_fee_schedules_tableHeaderRowLeft: {
        borderRadius: '8px 0 0 0', 
        paddingLeft: '10px', 
        fontWeight: '600', 
        fontSize: '14px'
    },
    pep_fee_schedules_tableHeaderRowMiddle: {
        borderRadius: '0 0 0 0', 
        paddingLeft: '10px', 
        fontWeight: '600', 
        fontSize: '14px'
    },
    pep_fee_schedules_tableHeaderRowRight: {
        borderRadius: '0 8px 0 0', 
        paddingLeft: '10px', 
        fontWeight: '600', 
        fontSize: '14px'
    },
    pep_fee_schedules_tableBodyRow: {
        height: '64px', 
        background: '#FFFFFF', 
        border: '1px solid #E4E4E6'
    },
    pep_fee_schedules_tableBodyCol: {
        paddingLeft: '10px', 
        fontWeight: '700', 
        fontSize: '14px'
    },
    pep_fee_schedules_tableDownloadButton: {
        cursor: 'pointer', 
        fontWeight: '600', 
        fontSize: '16px', 
        display: 'flex', 
        flexDirection: 'row', 
        justifyContent: 'center', 
        alignItems: 'center', 
        padding: '4px 16px', 
        gap: '10px', 
        width: '111px', 
        height: '30px', 
        background: '#0F7BBD', 
        boxShadow: '0px 0px 2px rgba(0, 0, 0, 0.25), 0px 2px 2px rgba(33, 33, 49, 0.24)', 
        borderRadius: '64px',
        color: '#FFFFFF'
    },
    pep_fee_schedules_bottom: {
        marginTop: '40px', 
        marginBottom: '40px', 
        fontStyle: 'normal', 
        fontWeight: '400', 
        fontSize: '16px', 
        lineHeight: '140%', 
        color: '#404040'
    },
    pep_fee_schedules_right: {
        marginLeft: '20px', 
        marginRight: '20px', 
        width: '100%'
    },
    pep_fee_schedules_right_additional_resources: {
        marginTop: '60px', 
        marginBottom: '10px', 
        fontStyle: 'normal', 
        fontWeight: '500', 
        fontSize: '24px', 
        lineHeight: '140%', 
        letterSpacing: '0.02em', 
        color: '#083d78'
    },
    pep_fee_schedules_right_links: {
        marginTop: '15px', 
        marginBottom: '15px', 
        fontStyle: 'normal', 
        fontWeight: '400', 
        fontSize: '16px', 
        lineHeight: '140%', 
        color: '#0084D5'
    },
    pep_fee_schedules_right_fee_schedules_right_links: {
        marginTop: '15px', 
        marginBottom: '15px', 
        fontStyle: 'normal', 
        fontWeight: '400', 
        fontSize: '16px', 
        lineHeight: '140%', 
        color: '#0084D5'
    },
    pep_fee_schedules_right_icons: {
        verticalAlign: 'middle', 
        marginRight: '10px', 
        width: '22px', 
        height: '22px', 
        fill: '#083d78'
    }
}));