import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles(() => ({
    search_row: {
        paddingTop: '15px',
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        fontStyle: 'normal',
        fontWeight: '400',
        fontSize: '13px',
        lineHeight: '140%',
        color: '#000000',
        userSelect: 'none'
    },
    search_container: {
        display: 'block',
        width: '100%'
    },
   search_left: {
        width: '100%'
    },
    search_root: {
        marginBottom: '5px',
        padding: '8px 12px',
        gap: '8px',
        display: 'flex',
        alignItems: 'left',
        height: '35px',
        borderRadius: '20px',
        background: '#FFFFFF',
        border: '1px solid #909098;',
        boxShadow: 'inset 0px 1px 2px rgba(0, 0, 0, 0.12), inset 0px 1px 1px rgba(0, 0, 0, 0.04)'
    },
    search_input: {
        flex: 1,
        minWidth:'1330px'
    },
    search_iconButton: {
        padding: 10,
    },
    input: {
        flex: 1,
    },
    iconButton: {
        padding: 10,
    },
    paper: {
        width: '100%',
        marginTop: '16px',
        marginBotttom: '52px'
    },
    table: {
    },
    stickyHeader: {
        
        zIndex: 2
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    },
    footerIcon: {
        right: -15
    },
    footerCaption: {
        fontSize: '12px',
        fontWeight: 'normal'
    },
    tableFooterRow: {
        background: '#EDEDEF',
        border: '1px solid #E4E4E6',
        boxSizing: 'border-box',
        boxShadow: 'inset 0px -1px 0px #E7E7ED',
        borderRadius: '0px 0px 8px 8px',
        position: "sticky",
        bottom: 0,
        zIndex: 2
    },
    paginationSpacer: {
        flex: 0
    },
    tableFonts: {
        fontFamily: 'OpenSans',
        fontWeight: '400',
        fontSize: '14px',
        lineHeight: '140%'
    },
    formContainer: {
        padding: '16px',
        borderRadius: 4,
        backgroundColor: '#f5f5f5',
        marginTop:'16px',
      },
      formField: {
        marginBottom: '16px',
        margin:"normal" ,
        variant:"outlined"
      },
    icon_enabled: {
        "& path": {
            fill: '#6E1E80'
        },
        verticalAlign: 'middle'
    },
    icon_disabled: {
        "& path": {
            fill: 'lightgray'
        },
        verticalAlign: 'middle'
    },
    pep_newuser_submitButton: {
        cursor: 'pointer',
        fontWeight: '600',
        fontSize: '22px',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '4px 16px',
        gap: '10px',
        width: '180px',
        height: '40px',
        background: '#0F7BBD',
        boxShadow: '0px 0px 2px rgba(0, 0, 0, 0.25), 0px 2px 2px rgba(33, 33, 49, 0.24)',
        borderRadius: '64px',
        color: '#FFFFFF',
        margin: '0 10px',
        fontFamily: 'OpenSans',
        border: '0px',
        '&:hover': {
            textDecoration: 'none',
            backgroundColor: 'rgb(9, 109, 217)'
        }
    },

    pep_bsc_ccp_form_row:{ 
        width: '100%',
        minWidth: '1440px',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        fontStyle: 'normal',
        fontWeight: '400',
        fontSize: '13px',
        lineHeight: '140%',
        color: '#000000',
        userSelect: 'none',
        marginBottom: '20px'
    },
    pep_bsc_ccp_form_container: {
        display: 'flex',
        width: '100%',
        maxWidth: '1440px',
        paddingLeft: '15px',
        paddingRight: '15px'
    },
    accordionRoot: {
        width: '100%',
        boxShadow: 'none',
        margin: 0,
      },
      accordionSummaryRoot: {
        fontFamily: 'OpenSans, sans-serif',
        fontSize: '16px',
        backgroundColor: '#f0f0f0',
        minHeight: '64px',
      },
      accordionRegion: {
        height: 'auto',
      },
      accordionDetailsRoot: {
        display: 'block',
        padding: 0,
      },
      accordionSummary: {
        backgroundColor: 'rgba(0, 0, 0, 0.03)',
        flexDirection: 'row-reverse',
      },
      accordionSummaryExpandedIconWrapper: {
        marginRight: '1em',
        transform: 'rotate(180deg)',
      },
      accordionSummaryContent: {
        marginRight: '1em',
      },
      accordionTitle: {
        fontSize: '1.25rem',
        fontWeight: 600,
        fontFamily: 'OpenSans, sans-serif',
        margin: 0,
        userSelect: 'none',
      },
      cardRoot: {
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: '8px',
      },
      cardHover: {
        boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.15)', // equivalent to customShadowZ1
      },
      cardContentPre: {
        margin: 0,
        padding: '16px !important',
        fontFamily: 'OpenSans',
        fontSize: '0.75rem',
      },
      bsc_ccp_filterButton: {
        cursor: 'pointer',
        fontWeight: '600',
        fontSize: '16px',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '4px 16px',
        gap: '10px',
        width: '120px',
        height: '30px',
        background: '#0084D5',
        boxShadow: '0px 0px 2px rgba(0, 0, 0, 0.25), 0px 2px 2px rgba(33, 33, 49, 0.24)',
        borderRadius: '64px',
        color: '#FFFFFF',
        marginLeft: '20px'
    }
      
}));