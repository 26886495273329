import { Button } from "@mui/material";
import Typography from '@mui/material/Typography';
import { call as adminCall } from "admin/store/api";
import { handleDownloadAttachment } from "admin/views/FeeSchedules/components/files/download-view-attachments";
import { getAppCurrentProfileId } from "authentication/appAuth";
import Spinner from "common/components/Spinner/Spinner";
import { useUserNotification } from "common/utils/use-user-notification";
import { ReactComponent as BlankFile } from "icon-library/SVG/File-Light_Blank.svg";
import { ReactComponent as Phone } from "icon-library/SVG/Phone-Light_Answer.svg";
import { useEnabledPermissions } from "lib/security/permission-hooks";
import moment from 'moment';
import { useCallback, useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { call } from "store/api";
import { AppReduxStore } from "store/reducerTypes";
import { find } from "underscore";
import { AdditionalResources } from "views/PEPHome/components/additional-resources/additional-resources";
import { ConfigProps } from "../types";
import { getProviderStyles } from "./styles";
import { FeeScheduleProps } from "./types";

export const PEPFeeSchedules = ({ providerConfig: config }: { providerConfig: ConfigProps }) => {
    const { user } = useSelector((store: AppReduxStore) => store);
    const { currentProfileRoleJoin } = user;
    const practiceId = currentProfileRoleJoin?.practice?.id || "";
    const orgId = currentProfileRoleJoin?.practice?.organizationId;
    const classes = getProviderStyles(config?.provider)();
    const intl = useIntl();
    const dispatch = useDispatch();
    const { enqueueError } = useUserNotification();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [feeSchedules, setFeeSchedules] = useState<FeeScheduleProps[]>([]);
    const grantedPermissions = useEnabledPermissions();
    const licenseOptionName = "BILLING_SCHEDULE"
    const requiredPermissionsList = [licenseOptionName];
    const hasPermission = () => {
        return requiredPermissionsList.every((permission: any) => grantedPermissions.includes(permission));
    };

    const getFeeSchedules = useCallback(async () => {
        const practiceId = currentProfileRoleJoin?.practice?.id;
        const endpointURL = `/admin/v1/schedule/assigned/practice/${practiceId}`;

        if (practiceId) {
            setIsLoading(true);
            const response = await adminCall("GET", endpointURL, {}, { "x-application-practiceid": practiceId })
                .catch(() => {
                    enqueueError("pep.fee.schedules.get.listing.error");
                }).finally(() => {
                    setIsLoading(false);
                });
            if (response) {
                setFeeSchedules(response?.content);
            }
        }
    }, [currentProfileRoleJoin?.practice?.id, enqueueError]);

    const handleDownloadClick = async (index?: number) => {
        const fileName = feeSchedules[(index ? index : 0)]?.feeSchedulefile;
        if (fileName && currentProfileRoleJoin) {
            handleDownloadAttachment(currentProfileRoleJoin, undefined, undefined, fileName, (error) => {
                enqueueError("pep.fee.schedules.download.error");
            })
        }
    };

    const getBillingGroupNames = (content: any) => {
        const groups: string[] = content.substring(1, content.length - 1).split(",");
        const billingGroups = (groups.length === 0) ? (<p></p>) : groups.map((group: string, index: number) => {
            const groupStr = group.substring(1, group.length - 1);
            return (<p key={`billing_group_${index}`}>{groupStr}</p>)
        });
        return billingGroups;
    };

    useEffect(() => {
        const getUser = async () => {
            const currentProfileId: string | undefined | null = getAppCurrentProfileId();
            const getCurrentRoleJoin = (profileRoleJoinInfos: any, currentProfileRoleJoinId: string | undefined | null) => {
                if (!profileRoleJoinInfos && !currentProfileRoleJoinId) return null
                return find(profileRoleJoinInfos, (profileRoleJoinInfo: any) => profileRoleJoinInfo.id === currentProfileRoleJoinId)
            }
            try {
                const { user, twilioToken } = await call("GET", "/admin/v1/user/current");
                const currentProfileRoleJoin = getCurrentRoleJoin(user?.profileRoleJoinInfos, currentProfileId);
                dispatch({ type: 'SET_USER', payload: { ...user, currentProfileRoleJoin } });
                dispatch({ type: 'SET_CURRENT_PROFILE_ID', payload: { currentProfileId: currentProfileRoleJoin?.profileId, currentUserType: currentProfileRoleJoin?.type } });
                dispatch({ type: 'SET_TWILIO_TOKEN', payload: { token: twilioToken } });
            } catch (error: any) {
                enqueueError("pep.get.user.error");
            } finally {
                setIsLoading(false);
            }
        }
        if (!user.username) {
            setIsLoading(true);
            getUser();
        }
    }, [dispatch, user.username, enqueueError])

    useEffect(() => {
        if (!!currentProfileRoleJoin?.id) {
            getFeeSchedules();
        }
    }, [currentProfileRoleJoin?.id, getFeeSchedules])

    const additionalResourcesConfig = [{
        name: "request_access_to_epicLink",
        action: {
            id: "request_access_to_epicLink",
            name: intl.formatMessage({ id: "pep.fee.schedules.right.link.request.access.to.epicLink" }),
            href: "https://epiclink.brownandtoland.com/EpicCareLink_PRD/common/account_request_main.asp",
            target: "_blank"
        },
        icon: BlankFile,
        enabled: true
    },
    {
        name: "contact_us",
        action: {
            id: "contact_us",
            name: intl.formatMessage({ id: "pep.fee.schedules.right.link.contact.us" }),
            href: "https://www.brownandtoland.com/contact-us/",
            target: "_blank"
        },
        icon: Phone,
        enabled: true
    }];

    type ObjectType = {
        [key: string]: any
    }

    const [filesAvaibility, setFilesAvaibility] = useState<ObjectType>({});

    const checkFilesAvailability = useCallback(async (feeScheduleListing: any) => {
        const endpointURL = `/admin/v1/provider/documents/status`;
        const fileNames: string[] = feeScheduleListing.map((feeSchedule: any) => { return feeSchedule.feeSchedulefile });
        const fileList: string[] = fileNames.filter((fileName: string, index: number) => {
            return (fileNames.indexOf(fileName) === index);
        });
        const s3FileStatusRequest = {
            "fileNames": fileList,
            "fileType": "FEE_SCHEDULE",
            "practiceId": practiceId,
            "orgId": orgId
        }
        const response = await call("POST", endpointURL, s3FileStatusRequest);
        if (response) {
            setFilesAvaibility(response);
        }
    }, [])

    useEffect(() => {
        if (practiceId && (practiceId !== "") && feeSchedules && feeSchedules.length > 0) {
            checkFilesAvailability(feeSchedules);
        }
    }, [feeSchedules])

    return (
        isLoading ? <Spinner /> : (
            <div id="fee_schedules_row" className={classes.pep_fee_schedules_row}>
                <div id="fee_schedules_container" className={classes.pep_fee_schedules_container}>
                    <div id="fee_schedules_left" className={classes.pep_fee_schedules_left}>
                        <div id="fee_schedules_banner" className={classes.pep_fee_schedules_banner}>
                            <Typography id="fee_schedules_banner_top" className={classes.pep_fee_schedules_banner_top}>
                                <FormattedMessage id={"pep.fee.schedules.banner.title"} />
                            </Typography>
                            <Typography id="fee_schedules_banner_bottom" className={classes.pep_fee_schedules_banner_bottom}>
                                <FormattedMessage id={"pep.fee.schedules.banner.description"} />
                            </Typography>
                        </div>
                        <div style={{ paddingLeft: '20px', paddingRight: '20px' }}>
                            <Typography id="fee_schedules_description" className={classes.pep_fee_schedules_description}>
                                <FormattedMessage id={"pep.fee.schedules.description.1"} />
                            </Typography>
                            <Typography id="fee_schedules_description" className={classes.pep_fee_schedules_description}>
                                <FormattedMessage id={"pep.fee.schedules.description.2"} />
                                <a href={`mailto:physicianrelations@btmg.com`} target="_top" style={{ color: '#0084D5' }}>physicianrelations@btmg.com.</a>
                            </Typography>
                            {(hasPermission() && <div className={classes.pep_fee_schedules_tableContainer}>
                                <table className={classes.pep_fee_schedules_tableMain}>
                                    <colgroup>
                                        <col className={classes.pep_fee_schedules_tableColGroup1} />
                                        <col className={classes.pep_fee_schedules_tableColGroup2} />
                                        <col className={classes.pep_fee_schedules_tableColGroup3} />
                                        <col className={classes.pep_fee_schedules_tableColGroup4} />
                                    </colgroup>
                                    <thead>
                                        <tr className={classes.pep_fee_schedules_tableHeaderRow}>
                                            <th className={classes.pep_fee_schedules_tableHeaderRowLeft} align="left">
                                                <FormattedMessage id={"pep.fee.schedules.table.column.fee.schedule"} />
                                            </th>
                                            <th className={classes.pep_fee_schedules_tableHeaderRowMiddle} align="left">
                                                <FormattedMessage id={"pep.fee.schedules.table.column.line.of.business"} />
                                            </th>
                                            <th className={classes.pep_fee_schedules_tableHeaderRowMiddle} align="left">
                                                <FormattedMessage id={"pep.fee.schedules.table.column.effective.date"} />
                                            </th>
                                            <th className={classes.pep_fee_schedules_tableHeaderRowRight} align="left">
                                                <FormattedMessage id={"pep.fee.schedules.table.column.action"} />
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {(feeSchedules.length > 0) ? feeSchedules.map((feeSchedule: FeeScheduleProps, index: number) => {
                                            return (
                                                <tr className={classes.pep_fee_schedules_tableBodyRow} key={`pep_fee_schedule_row_${index}`}>
                                                    <td className={classes.pep_fee_schedules_tableBodyCol}>{
                                                        `${feeSchedule.feeScheduleName ? feeSchedule.feeScheduleName : feeSchedule.feeSchedulefile}`
                                                    }</td>
                                                    <td className={classes.pep_fee_schedules_tableBodyCol}>
                                                        {getBillingGroupNames(feeSchedule.billingGroupNames)}
                                                    </td>
                                                    <td className={classes.pep_fee_schedules_tableBodyCol}>{
                                                        `${moment(feeSchedule.effectiveDate).format('MM/DD/YYYY')}`
                                                    }</td>
                                                    <td>
                                                        <Button
                                                            className={classes.pep_fee_schedules_tableDownloadButton}
                                                            variant="contained"
                                                            color="primary"
                                                            disabled={!filesAvaibility[feeSchedule["feeSchedulefile"]]}
                                                            onClick={() => {
                                                                handleDownloadClick(index);
                                                            }}>
                                                            <FormattedMessage id={"pep.fee.schedules.download.button.label"} />
                                                        </Button>
                                                    </td>
                                                </tr>
                                            )
                                        }) : <tr className={classes.pep_fee_schedules_tableBodyRow}><td colSpan={2} style={{ paddingLeft: '10px' }} ><FormattedMessage id={"pep.fee.schedules.unavailable"} /></td></tr>}
                                    </tbody>
                                </table>
                            </div>)}
                        </div>
                    </div>
                    <div id="fee_schedules_right" className={classes.pep_fee_schedules_right}>
                        <AdditionalResources provider={config?.provider} config={additionalResourcesConfig} />
                    </div>
                </div>
            </div>)
    )
}