import { TablePagination } from "@mui/material";
import { ElementType } from "react";
import TablePaginationActions from "../table-pagination-actions";
import { useStyles } from "./styles";
import { PaginationProps } from "./types";

const ListTablePagination = ({ result, onChange, pagination, component }: { result: any, onChange: (pagination: PaginationProps) => void, pagination: PaginationProps, component?: ElementType<any> }) => {
    const classes = useStyles();
    const totalElements: number = result?.totalElements || 0
    const page = pagination.page;
    const rowsPerPage = pagination.rowsPerPage;

    const handleChangePage = (event: unknown, newPage: number) => {
        onChange({
            page: newPage,
            rowsPerPage: rowsPerPage
        });
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        const rowsperPage = parseInt(event.target.value)
        onChange({
            page: 0,
            rowsPerPage: rowsperPage
        });
    };

    return (
        <TablePagination className={classes.tableFooterRow}
            component={component || 'td'}
            rowsPerPageOptions={[5, 10, 25, 50, 100]}
            classes={{ selectIcon: classes.footerIcon, select: classes.footerCaption, menuItem: classes.footerCaption, spacer: classes.paginationSpacer }}
            count={totalElements}
            rowsPerPage={rowsPerPage}
            labelRowsPerPage="Rows per page"
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            sx={{
                "& .MuiTablePagination-selectLabel, & .MuiTablePagination-displayedRows": {
                    marginTop: "0px",
                    marginBottom: "0px",
                    fontFamily: "OpenSans",
                    fontSize: "14px"
                },
                "& .MuiTablePagination-select": {
                    marginTop: "2px",
                    marginBottom: "0px",
                    fontFamily: "OpenSans",
                    userSelect: "none",
                    fontSize: "14px",
                    minHeight: "0px"
                },
                "& .MuiTablePagination-actions": {
                    "& .MuiButtonBase-root": {
                        marginLeft: "2px",
                        marginRight: "2px",
                        "&:hover": {
                            backgroundColor: "lightgray"
                        }
                    }
                }
            }}
            ActionsComponent={TablePaginationActions} />
    )
}

export default ListTablePagination