import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles(() => ({
    pep_additional_resources_header: {
        fontFamily: 'BentonSansCond',
        marginTop: '20px',
        marginBottom: '10px',
        paddingTop: '16px',
        fontStyle: 'normal',
        fontWeight: '500',
        fontSize: '24px',
        lineHeight: '140%',
        letterSpacing: '0.02em',
        color: '#6E1E80'
    },
    pep_additional_resources_link: {
        marginTop: '15px',
        marginBottom: '15px',
        fontStyle: 'normal',
        fontWeight: '400',
        fontSize: '16px',
        lineHeight: '140%',
        color: '#0084D5'
    },
    pep_additional_resources_link_icons: {
        verticalAlign: 'middle',
        marginRight: '10px',
        width: '22px',
        height: '22px',
        "& path": {
            fill: '#6E1E80'
        }
    }
}))