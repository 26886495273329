import { ReactComponent as BTPMarketplace } from "icon-library/SVG/Briefcase-Light_ALT.svg";
import { ReactComponent as PracticeManagementResources } from "icon-library/SVG/Building_Hospital-Plus.svg";
import { ReactComponent as PatientCareManagement } from "icon-library/SVG/Chart-Light_Line.svg";
import { ReactComponent as RequestAccess } from "icon-library/SVG/Checkbox_Square.svg";
import { ReactComponent as HealthPlanPolicies } from "icon-library/SVG/File-Light_Blank-Multiple.svg";
import { ReactComponent as EpicEHRImplementation } from "icon-library/SVG/Laptop_EMR.svg";
import { ReactComponent as ProviderDirectory } from "icon-library/SVG/Stethoscope.svg";
import { ReactComponent as Forms } from "icon-library/SVG/Tasks-1of3.svg";

const fcsConfig = {
    provider: "fcs",
    description: `Altais is your partner for successful practice management.  Search this section for the tools and reference materials you will need to efficiently manage your practice and patients.`,
    config_left: [{
        name: `Practice Management Resources`,
        description: "Find the Practice Operations Manual, products, services, and other resources that can help you manage your practice.",
        action: {
            id: "practice_management_resources"
        },
        icon: PracticeManagementResources,
        enabled: true
    },
    {
        name: `Forms`,
        description: "All the forms that you need for practice management, authorization, referrals, patient care, claims, dispute resolution and more.",
        action: {
            id: "pep-forms"
        },
        icon: Forms,
        enabled: true
    },
    {
        name: `Patient Care Resources`,
        description: "Information about patient care programs, Care Management, mental health resources, reference guides, authorization lists, after-hours clinics, and more.",
        action: {
            id: "pep-patient-care-management"
        },
        icon: PatientCareManagement,
        enabled: true
    },
    {
        name: `Epic EHR and EpicLink Information and Training`,
        description: "Read about the implementation progress and latest system updates.",
        action: {
            id: "epic_ehr_implementation"
        },
        icon: EpicEHRImplementation,
        enabled: true
    }],
    config_right: [{
        name: `Provider Directory - Find a Doctor`,
        description: "Coming soon",
        action: {
            id: "provider_directory",
            href: "https://www.brownandtoland.com/find-a-doctor/",
            target: "_blank",
            enabled: false
        },
        icon: ProviderDirectory,
        enabled: true
    },
    {
        name: `Health Plan Information`,
        description: "Find information about Altais contracted plans and links to important resources.",
        action: {
            id: "pep-health-plan-policies"
        },
        icon: HealthPlanPolicies,
        enabled: true
    },
    {
        name: `New Epic User Access Request`,
        description: "Coming soon",
        action: {
            id: "request_access_to_provider_tools",
            href: window.location.origin + "/newuser",
            target: "_blank",
            enabled: false
        },
        icon: RequestAccess,
        enabled: true
    }]
}

const bntConfig = {
    provider: "bnt",
    description: `Brown & Toland is your partner for successful practice management.  Search this section for the tools and reference materials you will need to efficiently manage your practice and patients.`,
    config_left: [{
        name: `Practice Management Resources`,
        description: "Find the Practice Operations Manual, products, services, and other resources that can help you manage your practice.",
        action: {
            id: "practice_management_resources"
        },
        icon: PracticeManagementResources,
        enabled: true
    },
    {
        name: `Forms`,
        description: "All the forms that you need for practice management, authorization, referrals, patient care, claims, dispute resolution and more.",
        action: {
            id: "pep-forms"
        },
        icon: Forms,
        enabled: true
    },
    {
        name: `Patient Care Resources`,
        description: "Information about patient care programs, Care Management, mental health resources, reference guides, authorization lists, after-hours clinics, and more.",
        action: {
            id: "pep-patient-care-management"
        },
        icon: PatientCareManagement,
        enabled: true
    },
    {
        name: `Epic EHR and EpicLink Information and Training`,
        description: "Read about the implementation progress and latest system updates.",
        action: {
            id: "epic_ehr_implementation"
        },
        icon: EpicEHRImplementation,
        enabled: true
    }],
    config_right: [{
        name: `BTP Marketplace`,
        description: "Learn about the new framework for enhanced services BTP has designed to improve practice operations, reduce physician burnout, create cost savings and increase the quality of care.",
        action: {
            id: "pep-btp-provider-marketplace"
        },
        icon: BTPMarketplace,
        enabled: true
    },
    {
        name: `Provider Directory - Find a Doctor`,
        description: "Find doctors by availability, location and specialty",
        action: {
            id: "provider_directory",
            href: "https://www.brownandtoland.com/find-a-doctor/",
            target: "_blank"
        },
        icon: ProviderDirectory,
        enabled: true
    },
    {
        name: `Health Plan Information`,
        description: "Find information about BTP contracted plans and links to important resources.",
        action: {
            id: "pep-health-plan-policies"
        },
        icon: HealthPlanPolicies,
        enabled: true
    },
    {
        name: `New Epic User Access Request`,
        description: "Submit this form to request access to the provider tools for your practice physicians and staff.",
        action: {
            id: "request_access_to_provider_tools",
            href: window.location.origin + "/newuser",
            target: "_blank"
        },
        icon: RequestAccess,
        enabled: true
    }]
}

export const getProviderConfig = (providerName: string) => {
    const allConfigs = [];
    allConfigs.push(bntConfig);
    allConfigs.push(fcsConfig);

    const config = allConfigs.filter((config) => {
        return config.provider === providerName;
    });
    return config[0];
};