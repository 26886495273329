import { ConfigProps } from "../types";

export const form_config: ConfigProps = {
    provider: "newuser",
    navigations: {
        header: {
            links: {
                left: [],
                right: [],
            },
        },
        footer: {
            links: [
                {
                    id: "patient_resources",
                    name: "Patient Resources",
                    href: "https://www.brownandtoland.com/patient-resources/",
                    target: "_blank",
                },
                {
                    id: "privacy_policy",
                    name: "Privacy Policy",
                    href: "https://www.brownandtoland.com/privacy-policy/",
                    target: "_blank",
                },
                {
                    id: "career",
                    name: "Career",
                    href: "https://jobs.lever.co/altais",
                    target: "_blank",
                },
                {
                    id: "for_physicians",
                    name: "For Physicians",
                    href: "https://www.brownandtoland.com/for-physicians/",
                    target: "_blank",
                },
                {
                    id: "terms_of_use",
                    name: "Terms Of Use",
                    href: "https://www.brownandtoland.com/terms-of-use/",
                    target: "_blank",
                },
                {
                    id: "site_help",
                    name: "Site Help",
                    href: "https://www.brownandtoland.com/site-help/",
                    target: "_blank",
                },
                {
                    id: "contact_us",
                    name: "Contact Us",
                    href: "https://www.brownandtoland.com/contact-us/",
                    target: "_blank",
                },
                {
                    id: "patient_bill_of_rights",
                    name: "Patient Bill Of Rights",
                    href: "https://www.brownandtoland.com/patient-bill-of-rights/",
                    target: "_blank",
                },
                {
                    id: "non-discrimination_notice",
                    name: "Non-Discrimination Notice",
                    href: "https://www.brownandtoland.com/non-discrimination-notice/",
                    target: "_blank",
                },
            ],
        },
    },
    tiles: [],
};

export const form_questions = [
    {
        group: {
            name: "Practice Information",
        },
        questions: [
            {
                type: "multi-select",
                name: "Practices",
                isRequired: true,
                options: [],
                placeholder: "Please select one or more practices",
                validateError: "Please select at least one practice.",
            },
            {
                type: "text",
                name: "Requestor's First Name",
                isRequired: true,
            },
            {
                type: "text",
                name: "Requestor's Last Name",
                isRequired: true,
            },
            {
                type: "text",
                name: "Requestor's Title",
                isRequired: true,
            },
            {
                type: "text",
                name: "Requestor's Phone Number",
                placeholder: "(123) 456-7890",
                dataType: "phone",
                validateError: "Please enter a valid phone number",
                isRequired: true,
            },
            {
                type: "text",
                name: "Requestor's Email Address",
                dataType: "email",
                validateError: "Please enter a valid email address",
                isRequired: true,
            },
        ],
    },
    {
        group: {
            name: "Requested Action",
        },
        questions: [
            {
                type: "select",
                name: "Requested Action",
                options: [`Add New User`, `Add Access`, `Remove Access`, `Deactivate User`, `Reactivate User`],
                isRequired: true,
            },
            {
                type: "date",
                name: "Expected Start Date",
                isRequired: true,
                hidden: true,
                lookupName: "Requested Action",
                showForValue: `Add New User`,
            },
            {
                type: "date",
                name: "Effective Date of Activation",
                isRequired: true,
                hidden: true,
                lookupName: "Requested Action",
                showForValue: `Add Access`,
            },
            {
                type: "date",
                name: "Date to Remove",
                isRequired: true,
                hidden: true,
                lookupName: "Requested Action",
                showForValue: `Remove Access`,
            },
            {
                type: "date",
                name: "Effective Date of Deactivation",
                isRequired: true,
                hidden: true,
                lookupName: "Requested Action",
                showForValue: `Deactivate User`,
            },
            {
                type: "date",
                name: "Expected Start Date",
                isRequired: true,
                hidden: true,
                lookupName: "Requested Action",
                showForValue: `Reactivate User`,
            },
            {
                type: "text",
                name: "Other Action",
                description: "Please specify",
                isRequired: true,
                hidden: true,
                lookupName: "Requested Action",
                showForValue: `Other`,
            },
        ],
    },
    {
        group: {
            name: "User Information",
        },
        questions: [
            {
                type: "text",
                name: "User's First Name",
                description: "User's (new or existing) legal first name",
                isRequired: true,
            },
            {
                type: "initial",
                name: "User's Middle Initial",
                description: "User's (new or existing) legal middle initial",
                isRequired: false,
            },
            {
                type: "text",
                name: "User's Last Name",
                description: "User's (new or existing) legal last name",
                isRequired: true,
            },
            {
                type: "text",
                name: "User ID/Login Name",
                description: "For existing user only",
                isRequired: false,
            },
            {
                type: "radio",
                name: "User's Title/Role",
                description: "Choose only one",
                options: [
                    `Back Office (MA, Nurse)`,
                    `Biller`,
                    `Billing Supervisor`,
                    `Front Office`,
                    `Member Connection Specialist`,
                    `Practice Manager/Office Supervisor`,
                    `Provider`,
                    `Read Only User`,
                    `Referral Coordinator`,
                    `Release of Information`,
                    `Resident/Fellow`,
                    `Scribe`,
                    `Other`,
                ],
                isRequired: true,
            },
            {
                type: "select",
                name: "Provider Type",
                options: [
                    `Audiologist`,
                    `Behavioral Health`,
                    `Dietician/Nutritionist`,
                    `Fellow`,
                    `Medical Student`,
                    `Nurse Practitioner`,
                    `Physician Assistant`,
                    `Physician (MD, DO)`,
                    `Resident`,
                ],
                isRequired: true,
                hidden: true,
                lookupName: "User's Title/Role",
                showForValue: `Provider`,
            },
            {
                type: "text",
                name: "Other User Title/Role",
                description: "Please specify",
                isRequired: true,
                hidden: true,
                lookupName: "User's Title/Role",
                showForValue: `Other`,
            },
            {
                type: "text",
                name: "User's Phone Number",
                placeholder: "(123) 456-7890",
                dataType: "phone",
                validateError: "Please enter a valid phone number",
                isRequired: true,
            },
            {
                type: "text",
                name: "User's Email Address",
                dataType: "email",
                validateError: "Please enter a valid email address",
                isRequired: true,
            },
            {
                type: "checkbox",
                name: "Work Location",
                description: "Deselect if address is different.",
                options: [`Yes, the work location is the same as requestors.`],
                default: true,
                isRequired: false,
                fixed: true,
                column: 1,
            },
            {
                type: "text",
                name: "User Address",
                isRequired: true,
                hidden: true,
                lookupName: "Work Location",
                showWhenEmpty: true,
            },
            {
                type: "text",
                name: "Existing Practice User to Mirror",
                description: "Have a user access in your practice you want us to mirror? list the user ID here",
                placeholder: "Example: bbritto",
                isRequired: false,
            },
        ],
    },
];
