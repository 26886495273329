import { ThunkDispatch, ThunkAction } from "redux-thunk";
import { AnyAction } from "redux";
import { terminateClient } from "./chat";
import { call } from "store/api";
import { setError } from "./error";
import { AppReduxStore, Profile } from "store/reducerTypes";
import { USER_TYPE } from "constant";
import { getNotifications } from "./notification";
import { find } from "underscore";
import { isPCR } from "views/SideNav/sideNavGuard"
import { sendEvent, eventRequestProps } from 'admin/store/actions';
import moment from 'moment';
import { renewAppTokens } from "authentication/appAuth";
// getCurrentUser fetches the current user and twilio token then saves the current profile to the store and fetches the
// latest notifications
export const getCurrentUser = (currentProfileId?: string | undefined | null, fromProfileSelector?: boolean, eventParams?: eventRequestProps) => {
    return async (dispatch: ThunkDispatch<{}, {}, AnyAction>, getState: () => AppReduxStore) => {
        const { chat } = getState()
        if (chat.client) {
            // @ts-ignore
            await dispatch(terminateClient())
        }

        try {
            const { user, twilioToken } = await call("GET", "/admin/v1/user/current")
            const { useCurrentPrj, expireCurrentPrj, profileRoleJoinInfos } = user
            const currentProfileRoleJoin = getCurrentRoleJoin(user.profileRoleJoinInfos, currentProfileId)
            dispatch({ type: 'SET_USER', payload: { ...user, currentProfileRoleJoin } })
            dispatch({ type: 'SET_CURRENT_PROFILE_ID', payload: { currentProfileId: currentProfileRoleJoin?.profileId, currentUserType: currentProfileRoleJoin?.type } })
            dispatch({ type: 'SET_TWILIO_TOKEN', payload: { token: twilioToken } })

            // @ts-ignore
            !isPCR(currentProfileRoleJoin?.products) && dispatch(getNotifications(true))
            // Default profile redirects
            if (fromProfileSelector && ((profileRoleJoinInfos?.length === 1) || (useCurrentPrj && expireCurrentPrj && moment(expireCurrentPrj).diff(moment(), "days") >= 0))) {
                dispatch(selectProfile(currentProfileRoleJoin, null, null))
                if (eventParams)
                    dispatch(sendEvent(eventParams))
            }
        } catch (e: any) {
            dispatch(setError("Error.message.findingUser"))
        }
    }
}

export const resetPassword = (params: any): ThunkAction<Promise<void>, AppReduxStore, {}, AnyAction> => {
    return (dispatch: ThunkDispatch<{}, {}, AnyAction>, getState: () => AppReduxStore) => {
        return new Promise(async (resolve, reject) => {

            try {

                await call("POST", `/admin/v1/user/resetPassword`, params)

                resolve()

            } catch (e: any) {
                console.error(e)
                reject(e)
                dispatch(setError("Error.message.failedToResetPassword"))
            }
        })
    }
}

// getProfiles makes a request to fetch the profiles for the current user
export const getProfiles = () => {
    return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
        dispatch({ type: 'FETCHING_USER_PROFILES' })
        try {
            const profiles = await call("GET", "/admin/v1/user/profiles")
            dispatch({ type: 'SET_USER_PROFILES', payload: { profiles } })
        } catch (e: any) {
            dispatch(setError("Error.message.getProfiles"))
            dispatch({ type: 'USER_PROFILE_FETCH_ERROR' })
        }
    }
}

// selectProfile makes a request to set the currently selected and routes to the corresponding page
export const selectProfile = (profile: any, history: any, duration: string | null) => {
    return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
        let url = ""
        try {
            dispatch({ type: 'FETCHING_USER_PROFILES' })
            await call("PATCH", duration ? `/admin/v1/user/current-profile/${profile.id}?expiryDate=${duration}` : `/admin/v1/user/current-profile/${profile.id}`)
        } catch (e: any) {
            dispatch({ type: 'USER_PROFILE_FETCH_ERROR' })
            dispatch(setError("Error.message.setCurrentProfile"))
        }
        switch (profile.type) {
            case USER_TYPE.ADMIN:
                url = "/admin/home"
                break
            case USER_TYPE.PROVIDER:
                if (profile?.level?.name === "CARE_MANAGER") {
                    url = "/pep/bsc";
                } else if ((profile?.shortName?.toLowerCase() === "fcs") || (profile?.practice?.organizationInfo?.shortName?.toLowerCase() === "fcs")) {
                    url = "/pep/fcs";
                } else {
                    url = "/pep/bnt";
                }
                break
            case USER_TYPE.PATIENT:
                url = "/patient"
                break
        }
        await renewAppTokens();
        if (url) {
            window.location.replace(url)
        }
    }
}
// Cancel default profile duration by profile id
export const cancelDefaultProfileDuration = (profileId: string) => {
    return async (dispatch: ThunkDispatch<{}, {}, any>) => {
        try {
            await call("PATCH", `/admin/v1/user/current-profile/${profileId}`)
            dispatch(getCurrentUser(profileId))
        } catch (e: any) {
            dispatch(setError("Error.message.setCurrentProfile"))
        }
    }
}

// updateRegisteredUserProfile updates the registered user profile if they would like to disable the lms modal
export const updateRegisteredUserProfile = (showLmsModal: boolean) => {
    return async (dispatch: ThunkDispatch<{}, {}, AnyAction>, getState: () => AppReduxStore) => {
        try {
            const { userId } = getState().user
            await call("PATCH", `/admin/v1/user/user/profile/${userId}`, { showLmsModal })
            const { user, twilioToken } = await call("GET", "/admin/v1/user/current")
            dispatch({ type: 'SET_USER', payload: { ...user } })
            dispatch({ type: 'SET_TWILIO_TOKEN', payload: { token: twilioToken } })
        } catch (e: any) {
            dispatch(setError("Error.message.updateUserProfile"))
        }
    }
}

// getCurrentRoleJoin pulls the current profile role join from the list of profile role joins
const getCurrentRoleJoin = (profileRoleJoinInfos: Profile[], currentProfileRoleJoinId: string | undefined | null) => {
    if (!profileRoleJoinInfos && !currentProfileRoleJoinId) return null
    return find(profileRoleJoinInfos, (profileRoleJoinInfo: Profile) => profileRoleJoinInfo.id === currentProfileRoleJoinId)
}

export const updateUserTutorialOptions = (setting: any) => {
    return async (dispatch: ThunkDispatch<{}, {}, any>, getState: () => AppReduxStore) => {
        const { user } = getState()
        const { registeredUserProfileInfo, currentProfileRoleJoinId } = user
        const params = {
            ...registeredUserProfileInfo,
            ...setting
        }
        try {
            await call("PATCH", `/admin/v1/user/updateUser`, params)
            dispatch(getCurrentUser(currentProfileRoleJoinId))
        } catch (e: any) {
            dispatch(setError("Error.message.setCurrentProfile"))
        }
    }
}