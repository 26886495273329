import { Grid,Button, Paper, Table, TableBody, TableCell, TableContainer,  TableHead, TableRow, TableSortLabel, Typography } from "@mui/material";
import React, {  useEffect, useState } from "react";
import DatePicker from "common/components/DatePicker/DatePicker";
import ListTableFooter from 'common/components/Table/table-footer';
import { useStyles } from "./styles";
import dayjs from 'dayjs';
import { PaginationProps } from 'common/components/Table/table-pagination/types';
import ErrorMessage from "common/components/ErrorMessage/ErrorMessage";

type OutreachRecordType = {
  id:string,
  bscCcpMemberId:string,
  hcpsk: string,
  mrn: string,
  memberId: string,
  ipaName: string,
  lineOfBusiness: string,
  topicName: string,
  callType: string,
  callSeqAllCalls: string,
  callDatetimeAsString: string,
  callDatetime: string
  communicationType: string,
  providerType:string,
 // callerName:string,
  callOutcome:string,
  callDisposition:string,
  agentNotes:string,
  resolutionReasonCategory:string,

}

type PaginatedSort = {
  empty: boolean,
  sorted: boolean,
  unsorted: boolean,
}

type PaginatedPagable = {
  offset: number,
  pageNumber: number,
  pageSize: number,
  paged: boolean,
  sort: PaginatedSort,
  unpaged: boolean
}

export type PaginatedOutreachRecordProps = {
  content: OutreachRecordType[],
  empty: boolean,
  first: boolean,
  last: boolean,
  number: number,
  numberOfElements: number,
  pageable: PaginatedPagable,
  size: number,
  sort: PaginatedSort,
  totalElements: number,
  totalPages: number,
}

type Order = 'asc' | 'desc';

type newMemberID = '';

type OutreachListProps = {
  onSearch: (pageNumber: number, pageSize: number, sortedBy: string, sortAsc: boolean, startDate?: string, endDate?: string ) => Promise<void>,
  result: PaginatedOutreachRecordProps
  newMember: newMemberID
}


export const BSCOutreachList = (props: OutreachListProps) => {
  const classes = useStyles();
  const [orderBy, setOrderBy] = useState<keyof OutreachRecordType>('callDatetime');
  const [order, setOrder] = useState<Order>('asc');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [callFromDateFilter, setCallFromDateFilter] = useState<any | null>(null);
  const [callToDateFilter, setCallToDateFilter] = useState<any | null>(null);
  const [callFromDate, setCallFromDate] = useState<any | null>(null);
  const [callToDate, setCallToDate] = useState<any | null>(null);

  const searchResults: OutreachRecordType[] = props.result?.content || [];
  const [error, setError] = useState<string | null>(null);

  const handleRequestSort = (event: React.MouseEvent<unknown>, property: keyof OutreachRecordType) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
    setPage(0);
  };

  const createSortHandler = (property: keyof OutreachRecordType) => (event: React.MouseEvent<unknown>) => {
      handleRequestSort(event, property);
    };

  const pagination: PaginationProps = { page: page, rowsPerPage: rowsPerPage }
  const setPagination = (pagination: PaginationProps) => {
      setPage(pagination.page);
      setRowsPerPage(pagination.rowsPerPage);
    }

  const formatToYYYYMMDD = (date: Date) => {
    if(date)
      return dayjs(date).format('YYYY-MM-DD')
      else 
      return undefined;
    }
  
  useEffect(() => {
          const sortAsc = (order === 'desc') ? true : false;
          props.onSearch(page, rowsPerPage, orderBy, sortAsc, formatToYYYYMMDD(callFromDateFilter), formatToYYYYMMDD(callToDateFilter)).then(() => {
          });
    }, [page, rowsPerPage, orderBy, order, formatToYYYYMMDD(callFromDateFilter), formatToYYYYMMDD(callToDateFilter)])

  const handleRemoveFilters = (event: any) => {
    setCallFromDateFilter(null);
    setCallToDateFilter(null);
    setCallFromDate(null);
    setCallToDate(null);
    setError('');
    setPage(0);
    }
  
  useEffect(() => {
        setCallFromDateFilter(null);
        setCallToDateFilter(null);
        setCallFromDate(null);
        setCallToDate(null);
        setError('');
        setPage(0);
        setRowsPerPage(5);
  }, [props.newMember]);


  const validateDates = (fromDate: Date | null, toDate: Date | null): boolean => {
      if (fromDate && toDate && fromDate > toDate) {
          setError("Error: 'From' date cannot be after 'To' date.");
          return false;
      }
      // Clear error if dates are valid
      setError(null);
      return true;
  };

  const handleFilterByDatesClick = () => {
      if (validateDates(callFromDate, callToDate)) {
        setCallFromDateFilter(callFromDate);
        setCallToDateFilter(callToDate);
        setPage(0);
      }
      return;
  };

return (<>
<div style={{ display:'grid'}}>
        <div >
          <h5 className={classes.accordionTitle}>{`Outreach Attempts`}</h5>
        </div>
        </div>
        <div  style={{paddingTop: '30px'}}>
          <Grid container spacing={1}> 
                {/* Call From Date Picker */}
                <Grid item xs={12} sm={6} md={2} zIndex={1300}>
                <Typography variant="h6" style={{paddingLeft:'2px'}}>Call From Date</Typography> 
                <DatePicker allowTime={false} selected={callFromDate} onChange={(date) => setCallFromDate(date)} placeholderText="MM/DD/YYYY" minDate='01/01/2024'/>
                </Grid>

                 {/* Call To Date Picker */}
                <Grid item xs={12} sm={6} md={2} zIndex={1300}>
                <Typography variant="h6" style={{paddingLeft:'2px'}}>Call To Date</Typography>
                <DatePicker allowTime={false} selected={callToDate} onChange={(date) => setCallToDate(date)} placeholderText="MM/DD/YYYY" minDate='01/01/2024'/>
                </Grid>

                 {/* Apply Filter Button */}
                <Grid item xs={12} sm={6} md={2} style={{maxWidth: 'fit-content', paddingTop: '30px'}}>
                  <Button id="btnSearchUsers" variant="contained" size="large" onClick={handleFilterByDatesClick}>
                    <Typography variant="h6">Apply Filter</Typography>
                  </Button>
                </Grid>

                {/* Reset Filter Button */}
                <Grid item xs={12} sm={6} md={2} style={{maxWidth: 'fit-content', paddingTop: '30px'}}>
                  <Button id="btnRemoveFilter" variant="contained" size="large" onClick={handleRemoveFilters}>
                    <Typography variant="h6">Reset Filter</Typography>
                  </Button>
                </Grid>
          </Grid>
        </div>
        <div style={{paddingLeft: '3px'}}>
        {error && <ErrorMessage message={error} />}
        </div> 
              <Paper className={classes.paper} style={{minHeight:"225px"}}> 
                <TableContainer className={classes.stickyHeader}>
                  <Table id="tableOutreachAttempts"
                    stickyHeader
                    className={classes.table}
                    aria-labelledby="tableTitle"
                    size={'medium'}
                    aria-label="enhanced table"
                  >
                    <TableHead>
                      <TableRow>
                        <TableCell style={{ background: '#EDEDEF' }} width={'15%'} key={"callDatetimeAsString"} align={'left'} padding="normal">
                          <TableSortLabel active={orderBy === "callDatetime"} direction={orderBy === "callDatetime" ? order : 'asc'} onClick={createSortHandler("callDatetime")}>
                          <strong style={{ marginLeft: "18px" }}>{`Call DateTime`}</strong>
                            {orderBy === "callDatetime" ? (<span className={classes.visuallyHidden}>{order === 'desc' ? 'sorted descending' : 'sorted ascending'}</span>) : null}
                          </TableSortLabel>
                        </TableCell>
                        
                        <TableCell style={{ background: '#EDEDEF' }} width={'5%'} key={"callType"} align={'left'} padding="normal">
                          <TableSortLabel active={orderBy === "callType"} direction={orderBy === "callType" ? order : 'asc'} onClick={createSortHandler("callType")}>
                          <strong>{'Contact Type'}</strong>
                            {orderBy === "callType" ? (<span className={classes.visuallyHidden}>{order === 'desc' ? 'sorted descending' : 'sorted ascending'}</span>) : null}
                          </TableSortLabel>
                        </TableCell>

                        <TableCell style={{ background: '#EDEDEF' }} width={'15%'} key={"topicName"} align={'left'} padding="normal">
                          <TableSortLabel active={orderBy === "topicName"} direction={orderBy === "topicName" ? order : 'asc'} onClick={createSortHandler("topicName")}>
                          <strong>{`Topic Name`}</strong>
                            {orderBy === "topicName" ? (<span className={classes.visuallyHidden}>{order === 'desc' ? 'sorted descending' : 'sorted ascending'}</span>) : null}
                          </TableSortLabel>
                        </TableCell>
                        <TableCell style={{ background: '#EDEDEF' }} width={'10%'} key={"callDisposition"} align={'left'} padding="normal">
                          <TableSortLabel active={orderBy === "callDisposition"} direction={orderBy === "callDisposition" ? order : 'asc'} onClick={createSortHandler("callDisposition")}>
                          <strong>{`Call Disposition`}</strong>
                            {orderBy === "callDisposition" ? (<span className={classes.visuallyHidden}>{order === 'desc' ? 'sorted descending' : 'sorted ascending'}</span>) : null}
                          </TableSortLabel>
                        </TableCell>
                        <TableCell style={{ background: '#EDEDEF' }} width={'5%'} key={"resolutionReasonCategory"} align={'left'} padding="normal">
                          <TableSortLabel active={orderBy === "resolutionReasonCategory"} direction={orderBy === "resolutionReasonCategory" ? order : 'asc'} onClick={createSortHandler("resolutionReasonCategory")}>
                          <strong>{'Resolution'}</strong>
                            {orderBy === "resolutionReasonCategory" ? (<span className={classes.visuallyHidden}>{order === 'desc' ? 'sorted descending' : 'sorted ascending'}</span>) : null}
                          </TableSortLabel>
                        </TableCell>
                        <TableCell style={{ background: '#EDEDEF' }} width={'10%'} key={"providerType"} align={'left'} padding="normal">
                          <TableSortLabel active={orderBy === "providerType"} direction={orderBy === "providerType" ? order : 'asc'} onClick={createSortHandler("providerType")}>
                          <strong>{'Provider Type'}</strong>
                            {orderBy === "providerType" ? (<span className={classes.visuallyHidden}>{order === 'desc' ? 'sorted descending' : 'sorted ascending'}</span>) : null}
                          </TableSortLabel>
                        </TableCell>
                        <TableCell style={{ background: '#EDEDEF' }} width={'20%'} key={"agentNotes"} align={'left'} padding="normal">                         
                          <strong>{'Agent Notes'}</strong>
                        </TableCell>
                   
                      </TableRow>
                     
                    </TableHead>
                    <TableBody>
                      {searchResults.map((row: any, index: number) => {
                        const labelId = `enhanced-table-checkbox-${index}`;
                       
                        return (<React.Fragment key={`search_result_table_body_${index}`}>
                        
                          <TableRow hover role="checkbox" tabIndex={-1} key={`search_result_table_row_${index}`} >
                            <TableCell id={labelId} scope="row" align="left" width={'15%'} >
                            <Grid style={{ display: "flex", alignItems: "center", flexBasis: 'none', marginLeft:"18px"}}>
                              {dayjs(row.callDatetimeAsString).format('MM/DD/YYYY hh:mm A')}
                              </Grid>
                            </TableCell>
                            <TableCell className={classes.tableFonts} align="left" width={'5%'}>{row.callType}</TableCell>
                            <TableCell className={classes.tableFonts} align="left" width={'15%'}>{row.topicName}</TableCell>
                            <TableCell className={classes.tableFonts} align="left" width={'10%'}>{row.callDisposition}</TableCell>
                            <TableCell className={classes.tableFonts} align="left" width={'5%'}>{row.resolutionReasonCategory}</TableCell>
                            <TableCell className={classes.tableFonts} align="left" width={'10%'}>{row.providerType}</TableCell>
                            <TableCell className={classes.tableFonts} align="left" width={'20%'}>{row.agentNotes}</TableCell>
                            
                          </TableRow>
                        </React.Fragment>);
                      })}
                    </TableBody>
                    <ListTableFooter result={props.result} pagination={pagination} onChange={setPagination} />
                  </Table>
                </TableContainer>
              </Paper> 
  </>);
}