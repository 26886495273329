import { Card, CardContent, Grid, Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import { useState } from "react";
import { call as adminCall } from "admin/store/api";
import ComponentSkeleton from './ComponentSkeleton';
import { BSCMemberLookup } from "./bsc-member-lookup";
import { BSCVisits } from "./bsc-member-visits";
import { BSCOutreachList } from "./bsc-member-outreach";
import { BSCMemberDataForm } from "./bsc-member-data";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useStyles } from "./styles";

  export const BSCCPActivities= ()=>{
      const classes = useStyles();
      const [memberData, setMemberData] = useState<any>(null); 
      const [memberIDValue, setMemberID]= useState<any>(null);
      const [searchResult, setSearchResult] = useState<any>(null);
      const [encounterResult, setEncounterResult] = useState<any>(null);
      const [outreachAttemptsResult, setOutreachAttemptsResult] = useState<any>(null);

      const fetchData = async (url: string, updateFn: React.Dispatch<React.SetStateAction<any>>) => {
        try {
          const response = await adminCall("GET", url);
          updateFn(response);
        } catch (error) {
          console.error(`Failed to fetch data from ${url}:`, error);
        }
      };

      //get member data
      const handleMemberSelect = async (memberID: string, edit: boolean): Promise<void> => {
          setMemberID(memberID);
          await fetchData(`/admin/v1/ccp/member/id/${memberID}`, setMemberData);
          fetchData(`/admin/v1/ccp/member/outreaches?bscCcpMemberId=${memberID}&pageNumber=0&pageSize=5&sortedBy=callDatetime&sortAsc=false`, setOutreachAttemptsResult);
          fetchData(`/admin/v1/ccp/member/visits?bscCcpMemberId=${memberID}&pageNumber=0&pageSize=5&sortedBy=ApptDate&sortAsc=false`, setEncounterResult);
          setExpanded(true);
          setlkpExpanded(false);
     }

      //Get filtered visits
      const handleVisitSearch = async (pageNumber: number = 5, pageSize: number= 0, sortedBy: string, sortAsc: boolean): Promise<void> => { 
          if(memberIDValue)
              fetchData(`/admin/v1/ccp/member/visits?bscCcpMemberId=${memberIDValue}&sortedBy=${sortedBy}&sortAsc=${sortAsc}&pageNumber=${pageNumber}&pageSize=${pageSize}`, setEncounterResult);
      }
      
       //Get filtered outreaches
      const handleOutreachSearch = async (pageNumber: number = 5, pageSize: number= 0, sortedBy: string, sortAsc: boolean, startDate?: string, endDate?: string): Promise<void> => { //searchKey: string, pageNumber: number = 0, pageSize: number = 5): Promise<void> => {
        const startDateStr = startDate || startDate !==undefined? `&start=${startDate}` : `&start=2023-01-01`;
        const endDateStr = endDate || endDate !==undefined? `&end=${endDate}` : `&end=2050-01-01`;
        if (!(endDateStr===`` || startDateStr===`` ||memberIDValue ===``||memberIDValue===null) )
          fetchData(`/admin/v1/ccp/member/outreaches?bscCcpMemberId=${memberIDValue}${startDateStr}${endDateStr}&sortedBy=${sortedBy}&sortAsc=${sortAsc}&pageNumber=${pageNumber}&pageSize=${pageSize}`, setOutreachAttemptsResult);
      }
  
       //member search
       const handleMemberSearch = async (searchKey: string, pageNumber: number = 0, pageSize: number = 5,sortedBy: string, sortAsc: boolean): Promise<void> => {
        if (!(searchKey===`` || searchKey===null ))
          await fetchData(`/admin/v1/ccp/member/report?name=${searchKey}&pageNumber=${pageNumber}&pageSize=${pageSize}&sortedBy=${sortedBy}&sortAsc=${sortAsc}`, setSearchResult);
        else
          setSearchResult(null);
        setOutreachAttemptsResult(null); 
        setEncounterResult(null);
        setMemberData(null);
        setExpanded(false);
      }

     const [expanded, setExpanded] = useState<boolean>(true);
     const handleExpansion = () => {
       setExpanded((prevExpanded) => !prevExpanded);
          };
   
   const accordionDisplayText = memberData ? (expanded ? `Member Details`
     : `Member Name: ${memberData.patientLastName}, ${memberData?.patientFirstName} | Date of Birth: ${memberData?.dateOfBirthAsString}`)
     : `Information not available.`;

    const [lkpExpanded, setlkpExpanded] = useState<boolean>(true);
    const handleLkupExpansion = () => {
    setlkpExpanded((prevExpanded) => !prevExpanded);
        };

return (     
    <ComponentSkeleton>

    <div  id="bsc_ccp_form_row" className={classes.pep_bsc_ccp_form_row}>
      <div  id="bsc_ccp_form_container" className={classes.pep_bsc_ccp_form_container} style={{paddingTop:'40px'}}>
        <Grid container spacing={2}  >
          <Grid item xs={12} sm={12} md={12} lg={12}>
          <div id="pageHeading">
            <h1 style={{ fontFamily: 'OpenSans', textAlign:`center` , marginTop: '10px', marginBottom: '20px', color: '#004b87', backgroundColor: 'transparent', userSelect: 'none'}}>{`BSC Complex Care Engagement Insights`}</h1>
            </div>
          </Grid>
    
          <Grid item xs={12} sm={12} md={12} lg={12}>
              <Accordion expanded={lkpExpanded}  onChange={handleLkupExpansion}  className={classes.accordionRoot}  >
                <AccordionSummary  expandIcon={<ExpandMoreIcon />}  aria-controls="panel1d-content"  id="panel1d-header" className={classes.accordionSummary}  >
                  <h3 className={classes.accordionTitle}>Member Lookup</h3>
                </AccordionSummary>
              
                <AccordionDetails className={classes.accordionDetailsRoot}>
                  <Card className={`${classes.cardRoot} ${classes.cardHover}`}>
                    <CardContent>
                      <Grid container spacing={0}>
                        <BSCMemberLookup onSearch={handleMemberSearch} onSelect={handleMemberSelect} result={searchResult} />
                      </Grid>
                    </CardContent>
                  </Card>
                </AccordionDetails>
              </Accordion>
          </Grid>

          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Accordion
              disabled={!memberData}
              expanded={expanded}
              onChange={handleExpansion}
              className={classes.accordionRoot} >
            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1d-content" id="panel1d-header" className={classes.accordionSummary} >
              <h3 className={classes.accordionTitle}>{accordionDisplayText}</h3>
            </AccordionSummary>
            
              <AccordionDetails className={classes.accordionDetailsRoot}>
                <Grid container spacing={4} >
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                      <Card className={`${classes.cardRoot} ${classes.cardHover}`}>
                        <CardContent>
                          <BSCMemberDataForm result={memberData} />
                        </CardContent>
                      </Card>
                    </Grid> 
                    <br></br>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Card className={`${classes.cardRoot} ${classes.cardHover}`}>
                        <CardContent>
                            <BSCOutreachList onSearch={handleOutreachSearch}  result={outreachAttemptsResult} newMember={memberIDValue}/> 
                        </CardContent>
                      </Card>
                    </Grid>
                    <br></br>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Card className={`${classes.cardRoot} ${classes.cardHover}`}>
                        <CardContent>
                            <BSCVisits result={encounterResult}  onSearch={handleVisitSearch}></BSCVisits>
                        </CardContent>
                      </Card>
                    </Grid>
                  </Grid>
              </AccordionDetails>
            </Accordion>
          </Grid> 
        </Grid>
      </div>
    </div>
    </ComponentSkeleton>
            )
};