import { Grid, IconButton, InputBase, Link, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TableSortLabel, Tooltip, Typography } from "@mui/material";
import { ReactComponent as SearchIcon } from "admin/assets/common/Search.svg";
import { PaginationProps } from 'common/components/Table/table-pagination/types';
import { useStyles } from "./styles";
import ListTableFooter from 'common/components/Table/table-footer';
import React, { MouseEvent, useEffect, useState } from "react";
import Spinner from "common/components/Spinner/Spinner";
import dayjs from 'dayjs';

type  MemberRecordType = {
  id:string,
  ipaName: string,
  lineOfBusiness: string,
  hcpsk: string,
  memberId: string,
  pat_name: string,
  patientFirstName: string,
  patientLastName: string,
  dateOfBirthAsString: string,
  dateOfBirth: string,
  mainCcpProvider: string,
  memberStatus:string,
  memberStatusDateAsString: string,
  memberStatusDate: string,
  memberProgramStatus: string
}

type PaginatedSort = {
  empty: boolean,
  sorted: boolean,
  unsorted: boolean,
}

type PaginatedPagable = {
  offset: number,
  pageNumber: number,
  pageSize: number,
  paged: boolean,
  sort: PaginatedSort,
  unpaged: boolean
}

export type PaginatedMemberRecordProps = {
  content: MemberRecordType[],
  empty: boolean,
  first: boolean,
  last: boolean,
  number: number,
  numberOfElements: number,
  pageable: PaginatedPagable,
  size: number,
  sort: PaginatedSort,
  totalElements: number,
  totalPages: number,
}


type Order = 'asc' | 'desc';


type MemberLookupProps  = {

  onSearch: (searchKey: string, pageNumber: number, pageSize: number, sortBy: string, sortAsc: boolean) => Promise<void>,
  onSelect: (ID: string, edit: boolean) => Promise<void>, 
  result: PaginatedMemberRecordProps }


  export const BSCMemberLookup = (props: MemberLookupProps ) => {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [searchStr, setSearchStr] = useState<string>();
  const [orderBy, setOrderBy] = useState<keyof MemberRecordType>('patientLastName');
  const [order, setOrder] = useState<Order>('asc');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const searchResults: any[] = props.result?.content || [];

  const handleSearch = (event: any = null, searchValue : string ="$$$") => {
    if(searchValue ==="$$$")
     searchValue = event.target.value.trim();

    if (searchValue !== "") {
      setSearchStr(searchValue);
      setPage(0);
    }  else {
      setSearchStr('');
    }
  };

  const handleRequestSort = (event: React.MouseEvent<unknown>, property: keyof MemberRecordType) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
    setPage(0);
  };

  const handleFullNameClick = (e: MouseEvent<HTMLAnchorElement, MouseEvent>, row: MemberRecordType): void => {
    if (row?.id)
      props.onSelect(row.id, false);
  }


  const createSortHandler = (property: keyof MemberRecordType) => (event: React.MouseEvent<unknown>) => {
    handleRequestSort(event, property);
  };

  const pagination: PaginationProps = { page: page, rowsPerPage: rowsPerPage }
    const setPagination = (pagination: PaginationProps) => {
    setPage(pagination.page);
    setRowsPerPage(pagination.rowsPerPage);
  }

  useEffect(() => {
    if (searchStr && searchStr !== "") {
        setIsLoading(true);
        const sortAsc = (order === 'asc') ? true : false;
        props.onSearch(searchStr , page, rowsPerPage, orderBy, sortAsc).then(() => {
          setIsLoading(false);
        });
   }
   else
   props.onSearch('', page, rowsPerPage, orderBy, false).then(() => {
    setIsLoading(false);
    });
  }, [searchStr, page, rowsPerPage, orderBy, order])



  return (<>
   <div id="search_container" className={classes.search_container}>
   <div id="search_left" className={classes.search_left}>
    <Paper component="form" className={classes.search_root}>
        <IconButton id="btnSearchUsers" className={classes.search_iconButton} aria-label="search" size="large" 
                onClick={() => {
                  const searchValue = (document.getElementById('txtSearchUsers') as HTMLInputElement | null)?.value || '';
                  if (searchValue) {
                    handleSearch(null, searchValue); // Call handleSearch with the current input value
                  }
                }}>
              <SearchIcon/>
            </IconButton>
             <InputBase id="txtSearchUsers" type="search"
              className={classes.search_input} 
              onChange={(ev: React.ChangeEvent<HTMLInputElement>) => {
                const { value } = ev.target;
                if (!value) {
                  handleSearch(ev, value);; // Define this function to handle the clear action
                }
              }}
              onKeyPress={(ev: React.KeyboardEvent<HTMLInputElement>) => {
                if (ev.key === 'Enter') {
                  handleSearch(ev); // Call the handleSearch function
                  ev.preventDefault(); // Prevent default form submission 
                }
              }}
              placeholder="Search by Health Plan Member ID, First Name, Last Name or HCPSK"
              inputProps={{ 'aria-label': 'Search by Health Plan Member ID, First Name, Last Name or HCPSK' }}
            /> 
      </Paper>
      </div>
      </div>
    <div  >
          {isLoading ? <><br /><br /><Spinner /></> : 
              <Paper className={classes.paper}>
                <TableContainer className={classes.stickyHeader}>
                  <Table id="tableBSCMemberLookup"  stickyHeader  className={classes.table} aria-labelledby="tableTitle" size={'medium'} aria-label="enhanced table" >
                    <TableHead>
                      <TableRow>
                         <TableCell style={{ background: '#EDEDEF' }} width={'20%'} key={"patientLastName"} align={'left'} padding="normal">
                          <TableSortLabel  active={orderBy === "patientLastName"} direction={orderBy === "patientLastName" ? order : 'asc'} onClick={createSortHandler("patientLastName")}>
                            <strong style={{ marginLeft: "18px" }}>{`Patient Name`}</strong>
                            {orderBy === "patientLastName" ?(<span className={classes.visuallyHidden}>{order === 'desc' ? 'sorted descending' : 'sorted ascending'}</span>) : null}
                          </TableSortLabel>
                        </TableCell> 

                        <TableCell  style={{ background: '#EDEDEF' }} width={'15%'} key={"memberId"} align={'left'} padding="normal" sortDirection={orderBy === "memberId" ? order : false}>
                          <TableSortLabel active={orderBy === "memberId"} direction={orderBy === "memberId" ? order : 'asc'} onClick={createSortHandler("memberId")}>
                             <strong>{`Member ID`}</strong>
                             {orderBy === "memberId" ? (<span className={classes.visuallyHidden}>{order === 'desc' ? 'sorted descending' : 'sorted ascending'}</span>) : null}
                        </TableSortLabel>
                        </TableCell>
                        <TableCell style={{ background: '#EDEDEF' }} width={'5%'} key={"hcpsk"} align={'left'} padding="normal">
                        <TableSortLabel active={orderBy === "hcpsk"} direction={orderBy === "hcpsk" ? order : 'asc'} onClick={createSortHandler("hcpsk")}>
                             <strong>{`HCPSK`}</strong>
                             {orderBy === "hcpsk" ? (<span className={classes.visuallyHidden}>{order === 'desc' ? 'sorted descending' : 'sorted ascending'}</span>) : null}
                        </TableSortLabel>
                        </TableCell>
                        <TableCell style={{ background: '#EDEDEF' }} width={'5%'} key={"dateOfBirthAsString"} align={'left'} padding="normal">
                        <TableSortLabel active={orderBy === "dateOfBirth"} direction={orderBy === "dateOfBirth" ? order : 'asc'} onClick={createSortHandler("dateOfBirth")}>
                             <strong>{`DOB`}</strong>
                             {orderBy === "dateOfBirth" ? (<span className={classes.visuallyHidden}>{order === 'desc' ? 'sorted descending' : 'sorted ascending'}</span>) : null}
                        </TableSortLabel>
                        </TableCell>
                        <TableCell style={{ background: '#EDEDEF' }} width={'10%'} key={"memberProgramStatus"} align={'left'} padding="normal">
                          <TableSortLabel active={orderBy === "memberProgramStatus"} direction={orderBy === "memberProgramStatus" ? order : 'asc'} onClick={createSortHandler("memberProgramStatus")}>
                            <strong>{'Program Status'}</strong>
                            {orderBy === "memberProgramStatus" ? (<span className={classes.visuallyHidden}>{order === 'desc' ? 'sorted descending' : 'sorted ascending'}</span>) : null}
                          </TableSortLabel>
                        </TableCell> 
                        <TableCell style={{ background: '#EDEDEF' }} width={'15%'} key={"memberStatusDateAsString"} align={'left'} padding="normal">
                          <TableSortLabel active={orderBy === "memberStatusDate"} direction={orderBy === "memberStatusDate" ? order : 'asc'} onClick={createSortHandler("memberStatusDate")}>
                          <strong>{'Status DateTime'}</strong>
                            {orderBy === "memberStatusDate" ? (<span className={classes.visuallyHidden}>{order === 'desc' ? 'sorted descending' : 'sorted ascending'}</span>) : null}
                          </TableSortLabel>
                        </TableCell> 
                        <TableCell style={{ background: '#EDEDEF' }} width={'20%'} key={"ipaName"} align={'left'} padding="normal">
                          <TableSortLabel active={orderBy === "ipaName"} direction={orderBy === "ipaName" ? order : 'asc'} onClick={createSortHandler("ipaName")}>
                          <strong>{'IPA'}</strong>
                            {orderBy === "ipaName" ? (<span className={classes.visuallyHidden}>{order === 'desc' ? 'sorted descending' : 'sorted ascending'}</span>) : null}
                          </TableSortLabel>
                        </TableCell> 
                        <TableCell style={{ background: '#EDEDEF' }} width={'10%'} key={"lineOfBusiness"} align={'left'} padding="normal">
                         
                          <TableSortLabel active={orderBy === "lineOfBusiness"} direction={orderBy === "lineOfBusiness" ? order : 'asc'} onClick={createSortHandler("lineOfBusiness")}>
                          <strong>{'Line of Business'}</strong>
                            {orderBy === "lineOfBusiness" ? (<span className={classes.visuallyHidden}>{order === 'desc' ? 'sorted descending' : 'sorted ascending'}</span>) : null}
                          </TableSortLabel>
                        </TableCell> 
                        
   
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {searchResults.map((row: MemberRecordType, index: number) => {
                        const labelId = `enhanced-table-checkbox-${index}`;
                        return (<React.Fragment key={`search_result_table_body_${index}`}>
                          <TableRow hover role="checkbox" tabIndex={-1} key={`search_result_table_row_${index}`} onClick={(e: any) => handleFullNameClick(e, row)}>
                            <TableCell id={labelId} scope="row" padding="none" width={'20%'} >
                              <Grid style={{ display: "flex", alignItems: "center", flexBasis: 'none', marginLeft:"18px"}}>
                                <Link style={{ textDecoration: 'none' }} onClick={(e: any) => handleFullNameClick(e, row)}>
                                  <Tooltip arrow title={(row.patientLastName && row.patientFirstName) ? `${row.patientLastName.toUpperCase()}, ${row.patientFirstName}` : ``}>
                                    <Typography noWrap variant="body1" style={{ color: '#0F7BBD', fontSize: '14px', fontWeight: 600 }}>{`${row.patientLastName.toUpperCase()}, ${row.patientFirstName.toUpperCase()}`}</Typography>
                                  </Tooltip>
                                </Link>
                              </Grid>
                            </TableCell>
                            <TableCell className={classes.tableFonts} align="left" width={'15%'}>{row.memberId}</TableCell>
                            <TableCell className={classes.tableFonts} align="left" width={'5%'}>{row.hcpsk}</TableCell>
                            <TableCell className={classes.tableFonts} align="left" width={'5%'}>{row.dateOfBirthAsString}</TableCell>
                            <TableCell className={classes.tableFonts} align="left" width={'10%'}>{row.memberProgramStatus}</TableCell>
                            <TableCell className={classes.tableFonts} align="left" width={'15%'}>{dayjs(row.memberStatusDateAsString).format('MM/DD/YYYY hh:mm A')}</TableCell>
                            <TableCell className={classes.tableFonts} align="left" width={'20%'}>{row.ipaName}</TableCell>
                            <TableCell className={classes.tableFonts} align="left" width={'10%'}>{row.lineOfBusiness}</TableCell>
                          </TableRow>
                        </React.Fragment>);
                      })}
                    </TableBody>
                    <ListTableFooter result={props.result} pagination={pagination} onChange={setPagination} />
                  </Table>
                </TableContainer>
              </Paper>
          }
    </div>
  </>);
}
