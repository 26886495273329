import React from "react";
import { Box, Typography } from "@mui/material";
import PEPLogo from "admin/assets/PEPLogo.png";
import { useStyles } from "./styles-extr";
import { LayoutExtrProps } from "./types";

const LayoutExtr = ({ heading, subHeading, children, additionalContent='' }: LayoutExtrProps) => {
    const classes = useStyles()
    return (
        <Box className={classes.landingArea}>
            <Box className={classes.boxLeft}>
                {!!additionalContent&&additionalContent}
            </Box>
            <Box className={classes.boxRight}>
                <Box className={classes.boxRightInner}>
                    <img height="114" width="450" alt="altais" src={PEPLogo} />
                    <Box className={classes.innerContent}>
                        <Typography className={classes.heading} variant="h1">
                            {heading}
                        </Typography>
                        <Typography className={classes.subHeading} variant="h2">
                            {subHeading}
                        </Typography>
                    </Box>
                    <Box>
                        {children}
                    </Box>
                </Box>
            </Box>
        </Box>

    )
}

export default LayoutExtr