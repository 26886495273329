import Typography from '@mui/material/Typography';
import SVGIcon from "common/components/svg-icon/svg-icon";
import { ReactComponent as ContactUs } from "icon-library/SVG/Phone-Light_Answer.svg";
import { ReactComponent as FindDoctor } from "icon-library/SVG/Stethoscope.svg";
import { ReactComponent as PatientCare } from "icon-library/SVG/Tasks-1of3.svg";
import { FormattedMessage, useIntl } from "react-intl";
import { getProviderStyles } from "./styles";
import { ProviderType, ResourceProps } from './types';

export const AdditionalResources = ({ provider, config, onSelect: handleSelection }: { provider?: ProviderType, config?: ResourceProps[], onSelect?: (menuItem: string) => void }) => {
    const classes = getProviderStyles(provider)();
    const intl = useIntl();
    const resources: ResourceProps[] = config ? config : [{
        name: "practice_management_patient_care_dispute",
        action: {
            id: "pep-forms",
            name: "Practice Management, Patient Care and Dispute Resolution Forms",
        },
        icon: PatientCare,
        enabled: true
    },
    {
        name: "find_a_doctor",
        action: {
            id: "find_a_doctor",
            name: "Find a Doctor",
            href: "https://www.brownandtoland.com/find-a-doctor/",
            target: "_blank"
        },
        icon: FindDoctor,
        enabled: (provider === "bnt")
    },
    {
        name: "contact_us",
        action: {
            id: "contact_us",
            name: intl.formatMessage({ id: "pep.fee.schedules.right.link.contact.us" }),
            href: (provider === "fcs") ? "https://altais.com/contact-us/" : "https://www.brownandtoland.com/contact-us/",
            target: "_blank"
        },
        icon: ContactUs,
        enabled: true
    }]

    return (<>
        <Typography id="additional_resources_header" className={classes.pep_additional_resources_header} >
            <FormattedMessage id={"pep.fee.schedules.right.additional.resources"} />
        </Typography >
        <hr style={{ backgroundColor: '#006385', height: '3px' }} />
        {(resources?.length > 0) && resources.filter((resource: ResourceProps) => resource.enabled).map((resource: ResourceProps, index: number) => {
            return (
                <Typography key={`additional_resources_link_${index}`} id="additional_resources_link" className={classes.pep_additional_resources_link}>
                    <SVGIcon svg={resource.icon} className={classes.pep_additional_resources_link_icons} />
                    <a href={resource.action.href}
                        style={{ fontStyle: 'normal', fontWeight: '400', fontSize: '16px', lineHeight: '140%', color: '#0084D5', cursor: 'pointer' }}
                        target={resource.action.target ? resource.action.target : "_self"}
                        rel="noopener noreferrer"
                        onClick={(event) => {
                            if (handleSelection && !resource.action.href) {
                                event.preventDefault();
                                event.stopPropagation();
                                handleSelection(resource.action.id);
                            }
                        }}
                    >
                        {resource.action.name}
                    </a>
                </Typography>)
        })}
    </>)
}
