import { ReactComponent as BrownTolandPhysicians } from "icon-library/SVG/Book.svg";
import { ReactComponent as DisputeResolution } from "icon-library/SVG/Caduceus.svg";
import { ReactComponent as FeeSchedules } from "icon-library/SVG/Circles_3x3.svg";
import { ReactComponent as ClaimsBillingResources } from "icon-library/SVG/File-Light_Blank-Multiple.svg";

export const getProviderConfig = (providerName: string, permission: boolean) => {
    const hasPermission = () => permission;
    const fcsConfig = {
        provider: "fcs",
        config_left: [{
            name: `Fee Schedules`,
            description: "Find fee schedules for Altais contracted providers. Fee schedules require authorization to view.",
            action: {
                id: "fee_schedules"
            },
            icon: FeeSchedules,
            enabled: hasPermission()
        },
        {
            name: `Tools and Resources`,
            description: "Tools and reference materials to help you successfully submit claims for reimbursement.",
            action: {
                id: "pep-claims-billing-resources"
            },
            icon: ClaimsBillingResources,
            enabled: true
        }],
        config_right: [{
            name: `Dispute Resolution Information`,
            description: "Learn about dispute resolution guidelines and procedures, and find related forms.",
            action: {
                id: "pep-dispute-resolution-information",
                href: "https://altais.com/physician-dispute-resolution-and-fee-schedules/",
                target: "_blank"
            },
            icon: DisputeResolution,
            enabled: true
        },
        {
            name: `Brown & Toland Physicians Policies`,
            action: {
                id: "brown_toland_physicians_policies"
            },
            icon: BrownTolandPhysicians,
            enabled: false
        }]
    }

    const bntConfig = {
        provider: "bnt",
        config_left: [{
            name: `Fee Schedules`,
            description: "Find fee schedules for BTP contracted providers. Fee schedules require authorization to view.",
            action: {
                id: "fee_schedules"
            },
            icon: FeeSchedules,
            enabled: hasPermission()
        },
        {
            name: `Tools and Resources`,
            description: "Tools and reference materials to help you successfully submit claims for reimbursement.",
            action: {
                id: "pep-claims-billing-resources"
            },
            icon: ClaimsBillingResources,
            enabled: true
        }],
        config_right: [{
            name: `Dispute Resolution Information`,
            description: "Learn about dispute resolution guidelines and procedures, and find related forms.",
            action: {
                id: "pep-dispute-resolution-information"
            },
            icon: DisputeResolution,
            enabled: true
        },
        {
            name: `Brown & Toland Physicians Policies`,
            action: {
                id: "brown_toland_physicians_policies"
            },
            icon: BrownTolandPhysicians,
            enabled: false
        }]
    }

    const allConfigs = [];
    allConfigs.push(bntConfig);
    allConfigs.push(fcsConfig);

    const config = allConfigs.filter((config) => {
        return config.provider === providerName;
    });
    return config[0];
};