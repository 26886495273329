import { Link, Typography } from "@mui/material";

export const getProviderConfig = (providerName: string, classes: any, handleFormDownload: () => Promise<void>) => {
    const fcsConfig = {
        provider: "fcs",
        content: (<>
            <Typography id="help_description" className={classes.pep_help_description}>
                {`If you need assistance with Provider Engagement Portal (PEP) or electronic tools, we’re here to help.`}
            </Typography>
            <Typography id="help_bottom" className={classes.pep_help_bottom}>
                {`Please contact the service desk at (877) 786-1552 or email `}<a href={`mailto:support@altais.com`} target="_top" style={{ color: '#0084D5' }}>support@altais.com.</a>
            </Typography>
            <Typography id="help_bottom" className={classes.pep_help_bottom}>
                {`If you need assistance with your reports, please contact your Physician Services Advisor.`}
            </Typography>
            <Typography id="help_bottom" variant="h4" style={{ fontFamily: 'BentonSansCond', fontWeight: '600' }} className={classes.pep_help_bottom}>
                {`Access Requests`}
            </Typography>
            <Typography id="help_bottom" className={classes.pep_help_bottom}>
                {`- To request PEP access for your practice staff, complete the `}
                <Link className={classes.pep_download_link} onClick={() => handleFormDownload()}>Provider Engagement Portal Access Request for Practice Staff</Link>
                {`.`}
            </Typography>
            <Typography id="help_bottom" className={classes.pep_help_bottom}>
                <a href="https://epiclink.brownandtoland.com/EpicCareLink_PRD/common/account_request_main.asp" target="_blank" style={{ color: '#0084D5' }} rel="noopener, noreferrer">
                    {`- EpicLink access request `}
                </a>
            </Typography>
        </>)
    }

    const bntConfig = {
        provider: "bnt",
        content: (<>
            <Typography id="help_description" className={classes.pep_help_description}>
                {`If you need assistance with Provider Engagement Portal (PEP) or electronic tools, we’re here to help.`}
            </Typography>
            <Typography id="help_bottom" className={classes.pep_help_bottom}>
                {`Call our IT Help Desk at (415) 972-6008 or email `}<a href={`mailto:servicedesk@btmg.com`} target="_top" style={{ color: '#0084D5' }}>servicedesk@btmg.com.</a>
            </Typography>
            <Typography id="help_bottom" className={classes.pep_help_bottom}>
                {`If you need assistance with your reports, please contact Brown & Toland Service Desk at `}<a href={`mailto:support@btmg.com`} target="_top" style={{ color: '#0084D5' }}>support@btmg.com.</a>
            </Typography>
            <Typography id="help_bottom" variant="h4" style={{ fontFamily: 'BentonSansCond', fontWeight: '600' }} className={classes.pep_help_bottom}>
                {`Access Requests`}
            </Typography>
            <Typography id="help_bottom" className={classes.pep_help_bottom}>
                {`- To request PEP access for your practice staff, complete the New User Build Request Form.`}
            </Typography>
            <Typography id="help_bottom" className={classes.pep_help_bottom}>
                <a href="https://epiclink.brownandtoland.com/EpicCareLink_PRD/common/account_request_main.asp" target="_blank" style={{ color: '#0084D5' }} rel="noopener, noreferrer">
                    {`- EpicLink access request `}
                </a>
            </Typography>
            <Typography id="help_bottom" variant="h4" style={{ fontFamily: 'BentonSansCond', fontWeight: '600' }} className={classes.pep_help_bottom}>
                {`Training Guides`}
            </Typography>
            <Typography id="help_bottom" className={classes.pep_help_bottom}>
                {`Review our helpful training guides:`}
            </Typography>
            <Typography id="help_bottom" className={classes.pep_help_bottom}>
                {`- Forgot password`}
            </Typography>
            <Typography id="help_bottom" className={classes.pep_help_bottom}>
                {`- Understanding your Clinical Performance and Quality reports`}
            </Typography>
        </>)
    }

    const allConfigs = [];
    allConfigs.push(bntConfig);
    allConfigs.push(fcsConfig);

    const config = allConfigs.filter((config) => {
        return config.provider === providerName;
    });
    return config[0];
};