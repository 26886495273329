import { makeStyles } from "@mui/styles";
import { SIZING, COLORS } from "theme";

export const useStyles = makeStyles(() => ({
    pep_membership_reports_row: {
        width: '100%', 
        minWidth: '1440px', 
        display: 'flex', 
        flexDirection: 'row', 
        justifyContent: 'center', 
        alignItems: 'center', 
        fontStyle: 'normal', 
        fontWeight: '400', 
        fontSize: '13px', 
        lineHeight: '140%', 
        color: '#000000',
        userSelect: 'none',
        marginBottom: '20px'
    },
    pep_membership_reports_container: {
        display: 'flex',
        width: '100%', 
        maxWidth: '1440px', 
        paddingLeft: '15px', 
        paddingRight: '15px'
    },
    pep_membership_reports_left: {
        marginRight: '10px',
        width: '1065px',
        paddingBottom: '30px'
    },
    pep_membership_reports_right: {
        marginLeft: '20px',
        marginRight: '20px',
        width: '100%'
    },
    pep_membership_reports_top: {
        width: '100%'
    },
    pep_membership_reports_banner: {
        marginTop: '20px', 
        marginBottom: '20px', 
        flexDirection: 'row', 
        alignItems: 'center', 
        padding: '16px 32px', 
        gap: '4px', 
        width: '1065px', 
        height: '111px', 
        background: 'linear-gradient(90deg, #F6F6F6 66.29%, rgba(246, 246, 246, 0) 100%)', 
        borderRadius: '4px'
    },
    pep_membership_reports_banner_top: {
        fontFamily: 'BentonSansCond',
        fontStyle: 'normal', 
        fontWeight: '500', 
        fontSize: '32px', 
        lineHeight: '140%', 
        color: '#083d78'
    },
    pep_membership_reports_banner_bottom: {
        fontStyle: 'normal', 
        fontWeight: '400', 
        fontSize: '16px', 
        lineHeight: '140%', 
        color: '#083d78'
    },




    bannerLine1: {
        marginTop: '30px',
        marginLeft: '30px',
        background: '#083d78',
        color: 'white',
        fontFamily: 'sans-serif',
        fontSize: '32pt',
        whiteSpace: 'nowrap'
    },
    bannerLine2: {
        marginTop: '30px',
        marginLeft: '30px',
        background: '#083d78',
        color: 'white',
        fontFamily: 'sans-serif',
        fontSize: '12pt',
        whiteSpace: 'nowrap'
    },
    outerColumn: {
        border: "none",
        height: 105
    },
    centerColumn: {
        border: "none",
        height: 105
    },
    pepHeader: {
        'padding-left': 'calc(10% - 80px)',
        'padding-right': 'calc(10% - 80px)',
        height: '110px',
        margin: "auto"
    },
    navigationLeft: {
        border: "none",
        height: 50
    },
    navigationCenter: {
        border: "none",
        height: 50
    },
    navigationRight: {
        border: "none",
        height: 50
    },
    pepFooterLinks: {
        margin: 0,
        padding: 0,
        listStyle: "none"
    },
    pageHeader: {
        float: 'left',
        width: '100%',
        padding: '1em 135px',
        background: '#083d78',
        color: 'white',
        'font-size': '54px',
        'font-family': 'BentonSansCondensedLight',
    },
    pageSubheader: {
        float: 'left',
        width: '100%',
        'font-size': '18px',
        'font-family': 'BentonSansCondensedMedium'
    },
    paragraphStyle: {
        fontWeight: '400',
        fontSize: '16px',
        lineHeight: '140%',
        color: '#404040',
        marginTop: '5px',
        marginBottom: '10px',
        textAlign: 'justify',
        fontFamily: 'OpenSans'
    },
    paragraphStyleBold: {
        fontWeight: '700',
        fontSize: '16px',
        lineHeight: '140%',
        color: '#404040',
        marginTop: '20px',
        marginBottom: '30px',
        textAlign: 'justify',
        fontFamily: 'OpenSans'
    },
    ahaButtonTitle: {
        fontFamily: 'BentonSansCond',
        fontWeight: '500',
        fontSize: '20px',
        lineHeight: '28px',
        letter: '2%',
        letterSpacing: '0.02em',
        color: '#083d78',
        paddingBottom: '10px'
    },
    ahaButtonTitleDisabled: {
        fontFamily: 'BentonSansCond',
        fontWeight: '500',
        fontSize: '20px',
        lineHeight: '28px',
        letter: '2%',
        letterSpacing: '0.02em',
        color: 'rgba(0, 0, 0, 0.26)',
        paddingBottom: '10px'
    },
    ahaButtonContent: {
        fontFamily: 'OpenSans',
        fontWeight: '500',
        fontSize: '16px',
        lineHeight: '140%',
        color: '#404040',
        paddingRight: '30px'
    },
    ahaButtonContentDisabled: {
        fontFamily: 'OpenSans',
        fontWeight: '500',
        fontSize: '16px',
        lineHeight: '140%',
        color: 'rgba(0, 0, 0, 0.26)',
        paddingRight: '30px'
    },
    ahaButtonContentLine2: {
        fontFamily: 'OpenSans',
        fontWeight: '500',
        fontSize: '16px',
        lineHeight: '140%',
        color: '#404040',
        paddingRight: '30px',
        paddingTop: '10px'
    },
    logoutIcon: {
        height: '39px',
        width: '39px',
        marginRight: '10px',
        "& path": {
            fill: '#083d78',
        },
        cursor: 'pointer'
    },
    userGroupIcon: {
        height: '50px',
        width: '50px',
        marginTop: '10px',
        "& path": {
            fill: '#0084D5',
        },
        cursor: 'pointer'
    },
    userGroupIconDisabled: {
        height: '50px',
        width: '50px',
        marginTop: '10px',
        "& path": {
            fill: 'rgba(0, 0, 0, 0.26)',
        },
        cursor: 'pointer'
    },
    warningIcon: {
        height: '39px',
        width: '39px',
        marginRight: '10px',
        "& path": {
            fill: 'red',
        }
    },
    errorButtonTitle: {
        fontFamily: 'OpenSans',
        fontWeight: '700',
        fontSize: '17px',
        lineHeight: '140%',
        color: 'red',
        paddingTop: '5px',
        paddingBottom: '20px'
    },
    tableContainer: {
        display: 'block', 
        marginLeft: 'auto', 
        marginRight: 'auto', 
        marginBottom: '20px',
        width: '570px'
    },
    tableMain: {
        borderCollapse: 'collapse'
    },
    tableHeaderRow: {
        height: '48px', 
        background: '#EDEDEF', 
        boxShadow: '0 0 0 1px #E7E7ED', 
        borderRadius: '8px 8px 0px 0px'
    },
    tableHeaderRowLeft: {
        borderRadius: '8px 0 0 0', 
        paddingLeft: '10px', 
        fontWeight: '600', 
        fontSize: '14px'
    },
    tableHeaderRowRight: {
        borderRadius: '0 8px 0 0', 
        paddingLeft: '10px', 
        fontWeight: '600', 
        fontSize: '14px'
    },
    tableBodyRow: {
        height: '64px', 
        background: '#FFFFFF', 
        border: '1px solid #E4E4E6'
    },
    tableDownloadButton: {
        cursor: 'pointer', 
        fontWeight: '600', 
        fontSize: '16px', 
        display: 'flex', 
        flexDirection: 'row', 
        justifyContent: 'center', 
        alignItems: 'center', 
        padding: '4px 16px', 
        gap: '10px', 
        width: '111px', 
        height: '30px', 
        background: '#0F7BBD', 
        boxShadow: '0px 0px 2px rgba(0, 0, 0, 0.25), 0px 2px 2px rgba(33, 33, 49, 0.24)', 
        borderRadius: '64px',
        color: '#FFFFFF'
    },
    root: {
        height: '200px',
        padding: SIZING.scale1200,
        display: 'flex',
        flexDirection: 'column',
        overflowY: 'scroll',
        // IE11 support
        flex: 1,
    },
    doctorName: {
        fontSize: SIZING.scale800,
        color: COLORS.LBLUE,
        fontWeight: 300
    },
    desc: {
        color: COLORS.DBLACK,
        fontSize: SIZING.scale500,
        fontWeight: 300,
        marginTop: SIZING.scale800,
        lineHeight: SIZING.scale800,
    }
}));