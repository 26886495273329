import FB from './assests/images/FB.png';
import LI from './assests/images/LI.png';
import { getProviderStyles } from "./styles";
import { ConfigProps, FooterLinksProps, LinkProps } from "./types";

export const PEPFooter = ({ providerConfig: config, onSelect: handleSelection }: { providerConfig: ConfigProps, onSelect?: (menuItem: string) => void }) => {
    const classes = getProviderStyles(config?.provider)();
    const footerLinks: FooterLinksProps = config.navigations.footer.links;
    const hideFB = !!(config.navigations.footer.hideFB);
    const hideLI = !!(config.navigations.footer.hideLI);

    return (<> {(footerLinks?.length > 0) &&
        <div id="footer_row" className={classes.pep_footer_row}>
            <div id="footer_container" className={classes.pep_footer_container}>
                <ul className={classes.pep_footer_column_links_ul}>
                    {footerLinks.map((footerLink: LinkProps) => (
                        <li className={classes.pep_footer_column_links_li} key={footerLink.name}>
                            <a className={classes.pep_footer_link}
                                href={footerLink.href}
                                target={footerLink.target ? footerLink.target : "_self"}
                                onClick={(event) => {
                                    if (handleSelection && footerLink.href === "") {
                                        event.preventDefault();
                                        event.stopPropagation();
                                        handleSelection(footerLink.id);
                                    }
                                }}
                            >
                                {footerLink.name}
                            </a>
                        </li>
                    ))}
                    {(!hideFB || !hideLI) ? <li style={{ listStyle: 'none', marginTop: '10px', marginBottom: '10px' }}>
                        {!hideFB && <a className={classes.pep_footer_link_fb} target="_blank" rel="noopener noreferrer" href="https://www.facebook.com/brown.toland">
                            <img width="30" height="30" src={FB} alt="Logo" />
                        </a>}
                        {!hideLI && <a className={classes.pep_footer_link_li} target="_blank" rel="noopener noreferrer" href="https://www.linkedin.com/company/brown-&amp;-toland-physicians">
                            <img width="30" height="30" src={LI} alt="Logo" />
                        </a>}
                    </li> : null}
                </ul>
            </div>
        </div>
    }</>
    );
}
