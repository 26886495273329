import { getProviderStyles } from "./styles";
import { ProviderType } from "./types";

const MenuSeparatorView = ({ providerType }: { providerType?: ProviderType }) => {
    const classes = getProviderStyles(providerType)();

    return (
        <div className={classes.optionDividerWrapper}>
            <div className={classes.optionDivider} />
        </div>
    );
};

export default MenuSeparatorView;
